import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ArrowLeft, LayoutDashboard, ListFilter } from "lucide-react";
import Navbar from "../../components/navigation/navbar";
import { GET } from "../../utils/apiCalls";
import API from "../../config/api";
import NavbarCCM from "../../components/navigation/ccmnavbar";

const dummyData = [
  {
    id: 1,
    Lead_Owner: "Wissam Messarra",
    Lead_Status: "New",
    Salutation: "Mr.",
    First_Name: "John",
    Middle_Name: "A.",
    Last_Name: "Smith",
    Suffix: "Jr.",
    Phone: "123-456-7890",
    Company: "Company A",
    Mobile: "111-222-3333",
    Title: "Manager",
    Email: "john.smith@example.com",
    ASI_Number: "ASI001",
    Rating: "High",
    Customer_Group: "Group A",
    Lead_Currency: "USD",
    PPAI_Number: "PPAI001",
    VAT_Number: "VAT001",
    EDD: "2025-03-01",
    Product_Interest: "Product A",
    Customization_Details: "Custom A",
    SAP_Lead_ID: "SAP001",
    SAP_Leads_IDs: "SAP001,SAP002",
    Mailing_Address: "123 Main St, City A",
    Mailing_Country: "Country A",
    Mailing_Street: "Main St",
    Billing_Address: "456 Elm St, City B",
    Billing_Country: "Country B",
  },
  {
    id: 2,
    Lead_Owner: "Wissam Messarra",
    Lead_Status: "New",
    Salutation: "Ms.",
    First_Name: "Alice",
    Middle_Name: "B.",
    Last_Name: "Johnson",
    Suffix: "",
    Phone: "987-654-3210",
    Company: "Company B",
    Mobile: "444-555-6666",
    Title: "Director",
    Email: "alice.johnson@example.com",
    ASI_Number: "ASI002",
    Rating: "Medium",
    Customer_Group: "Group B",
    Lead_Currency: "EUR",
    PPAI_Number: "PPAI002",
    VAT_Number: "VAT002",
    EDD: "2025-03-05",
    Product_Interest: "Product B",
    Customization_Details: "Custom B",
    SAP_Lead_ID: "SAP002",
    SAP_Leads_IDs: "SAP003,SAP004",
    Mailing_Address: "789 Pine St, City C",
    Mailing_Country: "Country C",
    Mailing_Street: "Pine St",
    Billing_Address: "101 Maple St, City D",
    Billing_Country: "Country D",
  },
  {
    id: 3,
    Lead_Owner: "Wissam Messarra",
    Lead_Status: "New",
    Salutation: "Dr.",
    First_Name: "Sam",
    Middle_Name: "C.",
    Last_Name: "Williams",
    Suffix: "Sr.",
    Phone: "456-789-1234",
    Company: "Company C",
    Mobile: "777-888-9999",
    Title: "Engineer",
    Email: "sam.williams@example.com",
    ASI_Number: "ASI003",
    Rating: "High",
    Customer_Group: "Group C",
    Lead_Currency: "GBP",
    PPAI_Number: "PPAI003",
    VAT_Number: "VAT003",
    EDD: "2025-03-10",
    Product_Interest: "Product C",
    Customization_Details: "Custom C",
    SAP_Lead_ID: "SAP005",
    SAP_Leads_IDs: "SAP006,SAP007",
    Mailing_Address: "202 Oak St, City E",
    Mailing_Country: "Country E",
    Mailing_Street: "Oak St",
    Billing_Address: "303 Birch St, City F",
    Billing_Country: "Country F",
  },
  {
    id: 4,
    Lead_Owner: "Wissam Messarra",
    Lead_Status: "New",
    Salutation: "Mrs.",
    First_Name: "Emily",
    Middle_Name: "D.",
    Last_Name: "Brown",
    Suffix: "",
    Phone: "789-123-4567",
    Company: "Company D",
    Mobile: "000-111-2222",
    Title: "Analyst",
    Email: "emily.brown@example.com",
    ASI_Number: "ASI004",
    Rating: "Low",
    Customer_Group: "Group D",
    Lead_Currency: "AUD",
    PPAI_Number: "PPAI004",
    VAT_Number: "VAT004",
    EDD: "2025-03-15",
    Product_Interest: "Product D",
    Customization_Details: "Custom D",
    SAP_Lead_ID: "SAP008",
    SAP_Leads_IDs: "SAP009,SAP010",
    Mailing_Address: "404 Cedar St, City G",
    Mailing_Country: "Country G",
    Mailing_Street: "Cedar St",
    Billing_Address: "505 Spruce St, City H",
    Billing_Country: "Country H",
  },
];

const LeadView = () => {
  const { leadId }: any = useParams();
  const navigate = useNavigate();
  const [lead, setLead] = useState<any>();
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    setLoading(true);
    const leadData = dummyData.find((lead) => lead.id === parseInt(leadId));
    setLead(leadData);
    setLoading(false);
  }, [leadId]);

  return (
    <div className="min-h-screen bg-gray-50">
      <NavbarCCM />
      <div className="flex items-center justify-between flex-wrap border-b border-gray-200 px-6 py-4 bg-white">
        <div className="flex items-center gap-3">
          <ArrowLeft
            className="h-5 w-5 cursor-pointer"
            onClick={() => navigate("/ccm-leads")}
          />
          <div className="flex items-center gap-2">
            <LayoutDashboard />
            <h1 className="text-xl font-medium">
              Lead:{" "}
              {loading
                ? "Loading..."
                : lead?.First_Name + " " + lead?.Last_Name}
            </h1>
          </div>
        </div>
        <div className="flex gap-2">
          <button className="px-4 py-1.5 text-gray-700 border border-gray-300 rounded hover:bg-gray-50">
            Edit
          </button>
          <button className="px-4 py-1.5 text-white bg-blue-600 rounded hover:bg-blue-700">
            Save
          </button>
        </div>
      </div>
      {loading ? (
        <div className="flex justify-center items-center min-h-screen bg-gray-50">
          <div className="animate-pulse">
            <div className="w-16 h-16 border-4 border-gray-600 border-t-transparent rounded-full animate-spin"></div>
          </div>
        </div>
      ) : (
        <>
          <div className="p-6 bg-gray-50">
            <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
              {/* <!-- Left Column --> */}
              <div className="col-span-12 md:col-span-6 bg-white rounded-lg shadow-md p-6">
                <div className="bg-gray-100 flex items-center justify-between mb-4 p-3 cursor-pointer">
                  <div className="flex items-center gap-2">
                    <ListFilter className="h-5 w-5 text-blue-500" />
                    <span className="font-bold text-blue-500">
                      Lead Information
                    </span>
                  </div>
                </div>
                <div className="space-y-4">
                  <div>
                    <label className="text-sm font-medium">Lead Owner</label>
                    <p className="text-gray-700">{lead?.Lead_Owner}</p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">Lead Status</label>
                    <p className="text-gray-700">{lead?.Lead_Status}</p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">Salutation</label>
                    <p className="text-gray-700">{lead?.Salutation}</p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">First Name</label>
                    <p className="text-gray-700">{lead?.First_Name}</p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">Middle Name</label>
                    <p className="text-gray-700">{lead?.Middle_Name}</p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">Last Name</label>
                    <p className="text-gray-700">{lead?.Last_Name}</p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">Suffix</label>
                    <p className="text-gray-700">{lead?.Suffix}</p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">Phone</label>
                    <p className="text-gray-700">{lead?.Phone}</p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">Company</label>
                    <p className="text-gray-700">{lead?.Company}</p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">Mobile</label>
                    <p className="text-gray-700">{lead?.Mobile}</p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">Title</label>
                    <p className="text-gray-700">{lead?.Title}</p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">Email</label>
                    <p className="text-gray-700">{lead?.Email}</p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">ASI Number</label>
                    <p className="text-gray-700">{lead?.ASI_Number}</p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">Rating</label>
                    <p className="text-gray-700">{lead?.Rating}</p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">
                      Customer Group
                    </label>
                    <p className="text-gray-700">{lead?.Customer_Group}</p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">Lead Currency</label>
                    <p className="text-gray-700">{lead?.Lead_Currency}</p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">PPAI Number</label>
                    <p className="text-gray-700">{lead?.PPAI_Number}</p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">VAT Number</label>
                    <p className="text-gray-700">{lead?.VAT_Number}</p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">EDD</label>
                    <p className="text-gray-700">{lead?.EDD}</p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">
                      Product Interest
                    </label>
                    <p className="text-gray-700">{lead?.Product_Interest}</p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">
                      Customization Details
                    </label>
                    <p className="text-gray-700">
                      {lead?.Customization_Details}
                    </p>
                  </div>
                </div>
              </div>

              {/* <!-- Right Column --> */}
              <div className="col-span-12 md:col-span-6 bg-white rounded-lg shadow-md p-6">
                {/* <!-- SAP Section --> */}
                <div className="bg-gray-100 flex items-center justify-between mb-4 p-3 cursor-pointer">
                  <div className="flex items-center gap-2">
                    <ListFilter className="h-5 w-5 text-blue-500" />
                    <span className="font-bold text-blue-500">
                      SAP Information
                    </span>
                  </div>
                </div>
                <div className="space-y-4">
                  <div>
                    <label className="text-sm font-medium">SAP Lead ID</label>
                    <p className="text-gray-700">{lead?.SAP_Lead_ID}</p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">SAP Leads IDs</label>
                    <p className="text-gray-700">{lead?.SAP_Leads_IDs}</p>
                  </div>
                </div>
                {/* <!-- Address Section --> */}
                <div className="bg-gray-100 flex items-center justify-between my-6 p-3 cursor-pointer">
                  <div className="flex items-center gap-2">
                    <ListFilter className="h-5 w-5 text-blue-500" />
                    <span className="font-bold text-blue-500">
                      Address Information
                    </span>
                  </div>
                </div>
                <div className="space-y-4">
                  {/* <!-- Mailing Address --> */}
                  <div>
                    <label className="text-sm font-medium">
                      Mailing Address
                    </label>
                    <p className="text-gray-700">{lead?.Mailing_Address}</p>
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="text-sm font-medium">
                        Mailing Country
                      </label>
                      <p className="text-gray-700">{lead?.Mailing_Country}</p>
                    </div>
                    <div>
                      <label className="text-sm font-medium">
                        Mailing Street
                      </label>
                      <p className="text-gray-700">{lead?.Mailing_Street}</p>
                    </div>
                  </div>
                  {/* <!-- Billing Address --> */}
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="text-sm font-medium">
                        Billing Address
                      </label>
                      <p className="text-gray-700">{lead?.Billing_Address}</p>
                    </div>
                    <div>
                      <label className="text-sm font-medium">
                        Billing Country
                      </label>
                      <p className="text-gray-700">{lead?.Billing_Country}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default LeadView;
