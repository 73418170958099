import { Form, Select } from "antd";
import {
  BanknoteIcon,
  CalendarIcon,
  LayoutDashboard,
  PackageIcon,
  PercentIcon,
  ShoppingCartIcon,
  TrendingUpIcon,
  TruckIcon,
  UserPlusIcon,
  UsersIcon,
  XCircleIcon,
} from "lucide-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NavbarCCM from "../../components/navigation/ccmnavbar";
import API from "../../config/api";
import { setFilters } from "../../redux/slices/filterSlice";
import { GET, POST } from "../../utils/apiCalls";

const salesData = [
  { name: "P1", sales: 200 },
  { name: "P2", sales: 300 },
  { name: "P3", sales: 600 },
  { name: "P4", sales: 800 },
  { name: "P5", sales: 700 },
  { name: "P6", sales: 700 },
  { name: "P7", sales: 700 },
  { name: "P8", sales: 700 },
  { name: "P9", sales: 700 },
  { name: "P10", sales: 700 },
  { name: "P11", sales: 700 },
  { name: "P12", sales: 700 },
];
const salesValues = salesData.map((item) => item.sales);
interface FilterValues {
  year: string[];
  periods: string[];
  weeks: string[];
  country: string[];
  channel: string[];
  salesChannel: string[];
}

export const CCMDashboard: React.FC = () => {
  const { filters } = useSelector((state: any) => state.Filter);
  const dispatch = useDispatch();
  const [salesChannel2Options, setSalesChannel2Options] = useState<any>({});
  const [isSalesChannel2Disabled, setIsSalesChannel2Disabled] = useState(true);

  const [form] = Form.useForm();

  useEffect(() => {
    getFilters();
  }, []);
  const getFilters = async () => {
    try {
      if (filters.length > 0) {
        return;
      }
      const response: any = await GET(API.FILTERS);

      if (response.length) {
        dispatch(setFilters(response));
      }

      const initialValues: FilterValues = {
        year: [],
        periods: [],
        weeks: [],
        country: [],
        channel: [],
        salesChannel: [],
      };
      form.setFieldsValue(initialValues);
    } catch (error) {
      console.log(error);
    }
  };

  const FilterBox = ({
    label,
    defaultValue,
    disabled,
    name,
    options = [
      {
        label: "All",
        value: "All",
      },
    ],
  }: any) => {
    return (
      <div className="flex-1 min-w-[150px] max-w-[200px] sm:max-w-none">
        <div className="w-full bg-white rounded shadow-sm border">
          <div className="p-0.5">
            <div className="min-w-0 flex-1">
              <div className="text-xs text-gray-500 truncate pl-1 pt-1">
                {label}
              </div>
              <Form.Item
                name={name}
                className="flex-1 min-w-[150px] max-w-[200px] sm:max-w-none mb-0"
              >
                <Select
                  showSearch
                  allowClear
                  mode="multiple"
                  style={{ width: "100%" }}
                  disabled={disabled}
                  bordered={false}
                  filterOption={(input: any, option: any) =>
                    (option?.label ?? "")
                      ?.toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={options}
                />
              </Form.Item>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const leftMetrics = [
    {
      icon: <PackageIcon className="w-5 h-5 text-amber-500" />,
      value: "$30,000",
      label: "Total Orders",
    },
    {
      icon: <BanknoteIcon className="w-5 h-5 text-blue-600" />,
      value: "$5,000",
      label: "Total NSV Invoiced",
    },
    {
      icon: <TruckIcon className="w-5 h-5 text-green-600" />,
      value: "15",
      label: "Shipped Orders",
    },
    {
      icon: <XCircleIcon className="w-5 h-5 text-red-500" />,
      value: "5",
      label: "Cancelled Orders",
    },
    {
      icon: <CalendarIcon className="w-5 h-5 text-purple-500" />,
      value: "5",
      label: "Period Days Remaining",
    },
    {
      icon: <UserPlusIcon className="w-5 h-5 text-blue-500" />,
      value: "5",
      label: "New Customers during this Period",
    },
  ];

  const rightMetrics = [
    {
      icon: <UsersIcon className="w-5 h-5 text-green-600" />,
      value: "75%",
      label: "Returning Customers during Period",
    },
    {
      icon: <BanknoteIcon className="w-5 h-5 text-blue-600" />,
      value: "$12,000",
      label: "Total NSV Demand",
    },
    {
      icon: <TrendingUpIcon className="w-5 h-5 text-amber-500" />,
      value: "18%",
      label: "Orders invoiced vs Demand",
    },
    {
      icon: <PercentIcon className="w-5 h-5 text-green-600" />,
      value: "90%",
      label: "% of Orders Invoiced",
    },
    {
      icon: <ShoppingCartIcon className="w-5 h-5 text-blue-600" />,
      value: "4",
      label: "Total Products Sold",
    },
  ];
  const handleFormChange = async (changedValues: any, allValues: any) => {
    try {
      if (changedValues.salesChannel !== undefined) {
        if (
          changedValues.salesChannel &&
          changedValues.salesChannel.length > 0
        ) {
          const response = await POST(API.FILTERSBYCHANNEL, {
            channel: changedValues.salesChannel,
          });
          setSalesChannel2Options(response);
          setIsSalesChannel2Disabled(false);
        } else {
          form.setFieldsValue({
            salesChannel: [],
            salesChannel2: [],
          });
          setSalesChannel2Options({});
          setIsSalesChannel2Disabled(true);
        }
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 font-sans">
      <NavbarCCM />

      <div className="flex items-center gap-2 bg-white border-b border-gray-200 px-6 py-4">
        <LayoutDashboard className="text-gray-600" />
        <h1 className="text-xl font-medium">
          Dashboard:{" "}
          <span className="font-bold">
            As of {moment(new Date("2024-12-11")).format("DD MMM YYYY")}
          </span>
        </h1>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-4 md:gap-6 p-5">
        <div className="sm:col-span-12 md:col-span-3 lg:col-span-3 space-y-4">
          <div className="bg-white p-4 rounded-lg border border-gray-200 shadow-md hover:scale-105 transition-transform">
            <div className="flex items-center gap-3">
              <div className="text-amber-500">
                <svg
                  width="24"
                  height="24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="w-5 h-5"
                >
                  <circle cx="8" cy="21" r="1"></circle>
                  <circle cx="19" cy="21" r="1"></circle>
                  <path d="M2.05 2.05h2l2.66 12.42a2 2 0 0 0 2 1.58h9.78a2 2 0 0 0 1.95-1.57l1.65-7.43H5.12"></path>
                </svg>
              </div>
              <div>
                <div className="text-2xl font-bold text-blue-500">5</div>
                <div className="text-sm text-gray-600">Total Accounts</div>
              </div>
            </div>
          </div>
          <div className="bg-white p-4 rounded-lg border border-gray-200 shadow-md hover:scale-105 transition-transform">
            <div className="flex items-center gap-3">
              <div className="text-blue-600">
                <svg
                  width="24"
                  height="24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="w-5 h-5"
                >
                  <rect width="20" height="12" x="2" y="6" rx="2"></rect>
                  <circle cx="12" cy="12" r="2"></circle>
                  <path d="M6 12h.01M18 12h.01"></path>
                </svg>
              </div>
              <div>
                <div className="text-2xl font-bold text-blue-500">5</div>
                <div className="text-sm text-gray-600">Total Leads</div>
              </div>
            </div>
          </div>
        </div>
        <div className="sm:col-span-12 md:col-span-9 lg:col-span-9 p-4">
          <h2 className="text-2xl font-semibold mb-6">CCM Dummy Dashboard</h2>
          <div className="border border-gray-200 rounded-lg shadow-sm p-4">
            <div className="flex justify-between items-center border-b border-gray-200 pb-2">
              <div className="text-pink-600 font-bold">Stats</div>
              <div className="bg-pink-200 p-1 rounded-full text-white">
                <svg
                  width="20"
                  height="20"
                  fill="none"
                  stroke="#954e9a"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="lucide lucide-percent"
                >
                  <line x1="19" x2="5" y1="5" y2="19"></line>
                  <circle cx="6.5" cy="6.5" r="2.5"></circle>
                  <circle cx="17.5" cy="17.5" r="2.5"></circle>
                </svg>
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 pt-5">
              <div>
                <div className="flex justify-between mb-1">
                  <div className="text-sm text-gray-600">Accounts</div>
                  <div className="text-sm text-gray-600">vs LY</div>
                </div>
                <div className="flex justify-between mb-1">
                  <div className="text-sm font-semibold">5</div>
                  <div className="text-sm text-green-500">0.60%</div>
                </div>
              </div>
              <div>
                <div className="flex justify-between mb-1">
                  <div className="text-sm text-gray-600">Leads</div>
                  <div className="text-sm text-gray-600">vs LY</div>
                </div>
                <div className="flex justify-between mb-1">
                  <div className="text-sm font-semibold">5</div>
                  <div className="text-sm text-red-500">-1.24%</div>
                </div>
              </div>
              <div>
                <div className="flex justify-between mb-1">
                  <div className="text-sm text-gray-600">Opportunities</div>
                  <div className="text-sm text-gray-600">vs LY</div>
                </div>
                <div className="flex justify-between mb-1">
                  <div className="text-sm font-semibold">€3,401K</div>
                  <div className="text-sm text-green-500">6.59%</div>
                </div>
              </div>
              <div>
                <div className="flex justify-between mb-1">
                  <div className="text-sm text-gray-600">Cases</div>
                  <div className="text-sm text-gray-600">vs LY</div>
                </div>
                <div className="flex justify-between mb-1">
                  <div className="text-sm font-semibold">5</div>
                  <div className="text-sm text-green-500">1.33%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CCMDashboard;
