import { ArrowUpDown, CircleX } from "lucide-react";
import { useEffect, useState } from "react";
import { POST } from "../../../utils/apiCalls";
import Pagination from "../../sales/components/pagination";
import LoadingBox from "../../../components/LoadingBox";
import API from "../../../config/api";

const Ga4 = (props: any) => {
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [ga4Data, setGa4Data] = useState([]);
  const [ga4DataMeta, setGa4DataMeta] = useState<any>();
  const [ga4DataTotal, setGa4DataTotal] = useState<any>();
  const [omsData, setOmsData] = useState([]);
  const [omsDataMeta, setOmsDataMeta] = useState<any>();
  const [omsDataTotal, setOmsDataTotal] = useState<any>();
  const [ga4DataPrevious, setGa4DataPrevious] = useState([]);
  const [ga4DataPreviousMeta, setGa4DataPreviousMeta] = useState<any>();
  const [ga4DataPreviousTotal, setGa4DataPreviousTotal] = useState<any>();
  const [omsDataPrevious, setOmsDataPrevious] = useState([]);
  const [omsDataPreviousMeta, setOmsDataPreviousMeta] = useState<any>();
  const [omsDataPreviousTotal, setOmsDataPreviousTotal] = useState<any>();
  const [ga4DataPage, setGa4DataPage] = useState<number>(1);
  const [ga4DataPreviousPage, setGa4DataPreviousPage] = useState<number>(1);
  const [omsDataPage, setOmsDataPage] = useState<number>(1);
  const [omsDataPreviousPage, setOmsDataPreviousPage] = useState<number>(1);

  useEffect(() => {
    getga4Data(props?.filters);
  }, [props, ga4DataPage, ga4DataPreviousPage]);
  useEffect(() => {
    getOmsData(props?.filters);
  }, [props, omsDataPage, omsDataPreviousPage]);

  const getga4Data = async (filter: any) => {
    try {
      setIsLoading1(true);
      console.log("this is the filter", filter);
      const filters = {
        marsYear: filter?.year,
        marsPeriod: filter?.periods,
        marsWeek: filter?.weeks,
        countries: filter?.country,
        channel: filter?.channel,
        salesChannel: filter?.salesChannel,
        salesChannel2: filter?.salesChannel2,
        currentPage: ga4DataPage,
        previousPage: ga4DataPreviousPage,
        currentLimit: 10,
        previousLimit: 10,
        sortBy: "DEMAND",
        sortOrder: "DESC",
      };
      const response: any = await POST(API.GET_PERFORMANCE_GA4TRAFFIC, filters);
      setGa4Data(response?.current?.data);
      setGa4DataMeta(response?.current?.meta);
      setGa4DataPrevious(response?.previous?.data);
      setGa4DataPreviousMeta(response?.previous?.meta);
      setGa4DataTotal(response?.current?.totals);
      setGa4DataPreviousTotal(response?.previous?.totals);
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setIsLoading1(false);
    }
  };

  const getOmsData = async (filter: any) => {
    try {
      setIsLoading2(true);
      const filters = {
        marsYear: filter?.year,
        marsPeriod: filter?.periods,
        marsWeek: filter?.weeks,
        countries: filter?.country,
        channel: filter?.channel,
        salesChannel: filter?.salesChannel,
        salesChannel2: filter?.salesChannel2,
        currentPage: omsDataPage,
        previousPage: omsDataPreviousPage,
        currentLimit: 10,
        previousLimit: 10,
        sortBy: "DEMAND",
        sortOrder: "DESC",
      };
      const response: any = await POST(API.GET_PERFORMANCE_ANALYTICS, filters);
      setOmsData(response?.current?.data);
      setOmsDataMeta(response?.current?.meta);
      setOmsDataPrevious(response?.vsLastYear?.data);
      setOmsDataPreviousMeta(response?.previous?.meta);
      setOmsDataTotal(response.current.totals);
      setOmsDataPreviousTotal(response.previous.totals);
      console.log("this is the response", response);
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setIsLoading2(false);
    }
  };

  const columns = [
    { key: "period", label: "Mars Year/Period" },
    { key: "week", label: "Week" },
    { key: "total_users", label: "Total Users" },
    { key: "sessions", label: "Sessions" },
    { key: "transactions", label: "Transactions" },
    { key: "demand", label: "Demand" },
    { key: "aov", label: "AOV" },
    { key: "conversion_rate", label: "Conversion Rate" },
  ];

  const formatCurrency = (value: any) => {
    if (isNaN(value)) return "-";
    return new Intl.NumberFormat("en-EU", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };
  const formatPercentage = (value: any) => {
    if (isNaN(value)) return "-";
    return `${value?.toFixed(1)}%`;
  };
  const getPercentageColor = (value: any) => {
    const numValue = parseFloat(value);
    if (numValue > 0) return "bg-green-100 text-green-800";
    if (numValue < 0) return "bg-red-100 text-red-800";
    return "text-gray-600";
  };

  return (
    <div>
      <div className="bg-blue-50 mb-5 p-4 font-bold text-sm text-[#a1343c] text-center italic">
        <p>
          GA4 taken from MMS ALL Production Property where the dimension
          "Country Platform" is not "Not Set" or "US"
        </p>
      </div>

      <div className="bg-white rounded-lg shadow">
        <div className="p-4 border-b border-gray-200 flex justify-between">
          <h2 className="text-lg font-semibold text-[#6b2228]">
            EU | WEBSITE Demand & Traffic KPI
          </h2>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4 md:gap-6">
            <div className="sm:col-span-12 md:col-span-12 lg:col-span-7 space-y-4">
              <h2 className="text-lg font-semibold text-[#a1343c] italic">
                GA4 Dataset
              </h2>
              <div className="overflow-x-auto">
                {isLoading1 ? (
                  <LoadingBox />
                ) : (
                  <table className="w-full">
                    <thead className="bg-gray-50">
                      <tr>
                        {columns?.map(({ key, label }) => (
                          <th
                            key={key}
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                          >
                            <div className="flex items-center space-x-1">
                              <span>{label}</span>
                              <ArrowUpDown className="h-4 w-4" />
                            </div>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {ga4Data?.map((row: any, index) => (
                        <tr key={index} className="hover:bg-gray-50">
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                            {row.YEAR} {row.PERIOD}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                            {row.WEEK}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span
                              className={`px-3 py-1 rounded-full text-xs font-medium `}
                            >
                              {row.TOTAL_USERS.toLocaleString()}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span
                              className={`px-3 py-1 rounded-full text-xs font-medium `}
                            >
                              {row?.SESSIONS?.toLocaleString()}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span
                              className={`px-3 py-1 rounded-full text-xs font-medium`}
                            >
                              {row?.TRANSACTIONS?.toLocaleString()}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span
                              className={`px-3 py-1 rounded-full text-xs font-medium `}
                            >
                              {row?.DEMAND}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span
                              className={`px-3 py-1 rounded-full text-xs font-medium `}
                            >
                              {row?.AOV === "null"
                                ? "-"
                                : formatCurrency(Number(row.AOV))}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span
                              className={`px-3 py-1 rounded-full text-xs font-medium`}
                            >
                              {Number(row.CONVERSION_RATE) * 100}%
                            </span>
                          </td>
                        </tr>
                      ))}
                      <tr className="bg-blue-600">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white">
                          GA4 Data
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white"></td>

                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                          {ga4DataTotal?.TOTAL_USERS}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                          {ga4DataTotal?.SESSIONS}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                          {ga4DataTotal?.TRANSACTIONS}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                          {ga4DataTotal?.DEMAND}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                          {ga4DataTotal?.AOV}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </div>
              <Pagination
                currentPage={Number(ga4DataMeta?.page) || 0}
                totalPages={Number(ga4DataMeta?.totalPages) || 0}
                totalRows={Number(ga4DataMeta?.total) || 0}
                setCurrentPage={(val: any) => {
                  setGa4DataPage(val);
                }}
                itemsPerPage={Number(10) || 0}
              />
            </div>
            <div className="sm:col-span-12 md:col-span-12 lg:col-span-5 space-y-4">
              <div className="flex justify-between items-center">
                <h2 className="text-lg font-semibold text-[#a1343c] italic">
                  OMS
                </h2>
                <span
                  className="text-[#62be84] text-sm px-2"
                  style={{ width: 300, textAlign: "center" }}
                >
                  Orders that don't go through the website are not displayed
                  here.
                </span>
              </div>
              <div className="overflow-x-auto">
                {isLoading2 ? (
                  <LoadingBox />
                ) : (
                  <table className="w-full">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Mars Year/Period
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Week
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Orders
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Demand
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          AOV
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {omsData?.map((row: any, index) => (
                        <tr key={index} className="hover:bg-gray-50">
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                            {row?.YEAR} {row?.PERIOD}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                            {row?.WEEK}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                            {row?.ORDERS || "_"}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                            {row?.DEMAND}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                            {row?.AOV}
                          </td>
                        </tr>
                      ))}
                      <tr className="bg-blue-600">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white">
                          OMS Data
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                          {omsDataTotal?.ORDERS}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                          {omsDataTotal?.DEMAND}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                          {omsDataTotal?.AOV}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </div>
              <Pagination
                currentPage={Number(omsDataMeta?.page) || 0}
                totalPages={Number(omsDataMeta?.totalPages) || 0}
                totalRows={Number(omsDataMeta?.total) || 0}
                setCurrentPage={(val: any) => {
                  setOmsDataPage(val);
                }}
                itemsPerPage={Number(10) || 0}
              />
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="bg-white rounded-lg shadow">
        <div className="p-4 border-b border-gray-200 flex justify-between">
          <h2 className="text-lg font-semibold text-[#6b2228]">
            EU | WEBSITE Demand & Traffic KPI || vs Last Year
          </h2>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4 md:gap-6">
            <div className="sm:col-span-12 md:col-span-12 lg:col-span-7 space-y-4">
              <h2 className="text-lg font-semibold text-[#a1343c] italic">
                GA4 Dataset
              </h2>
              <div className="overflow-x-auto">
                {isLoading1 ? (
                  <LoadingBox />
                ) : (
                  <table className="w-full">
                    <thead className="bg-gray-50">
                      <tr>
                        {columns?.map(({ key, label }) => (
                          <th
                            key={key}
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                          >
                            <div className="flex items-center space-x-1">
                              <span>{label}</span>
                              <ArrowUpDown className="h-4 w-4" />
                            </div>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {ga4Data?.map((row: any, index) => (
                        <tr key={index} className="hover:bg-gray-50">
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                            {row.YEAR} {row.PERIOD}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                            {row.WEEK}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span
                              className={`px-3 py-1 rounded-full text-xs font-medium ${getPercentageColor(
                                row?.TOTAL_USERS_VS_LY
                              )}`}
                            >
                              {row?.TOTAL_USERS_VS_LY}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span
                              className={`px-3 py-1 rounded-full text-xs font-medium ${getPercentageColor(
                                row?.SESSIONS_VS_LY
                              )}`}
                            >
                              {row?.SESSIONS_VS_LY}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span
                              className={`px-3 py-1 rounded-full text-xs font-medium ${getPercentageColor(
                                row?.TRANSACTIONS_VS_LY
                              )}`}
                            >
                              {row?.TRANSACTIONS_VS_LY}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span
                              className={`px-3 py-1 rounded-full text-xs font-medium ${getPercentageColor(
                                row?.DEMAND_VS_LY
                              )}`}
                            >
                              {row?.DEMAND_VS_LY}
                            </span>
                          </td>
                          <td
                            className={`px-6 py-4 whitespace-nowrap text-sm text-gray-600`}
                          >
                            <span
                              className={`px-3 py-1 rounded-full text-xs font-medium ${getPercentageColor(
                                row.AOVLY
                              )}`}
                            >
                              {formatPercentage(Number(row.AOVLY))}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span
                              className={`px-3 py-1 rounded-full text-xs font-medium ${getPercentageColor(
                                row.CONVERTION_RATE_VS_LY
                              )}`}
                            >
                              {formatPercentage(
                                Number(row.CONVERTION_RATE_VS_LY)
                              )}
                            </span>
                          </td>
                        </tr>
                      ))}
                      <tr className="bg-blue-600">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white">
                          GA4 Data
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                          {ga4DataPreviousTotal?.TOTAL_USERS_VS_LY}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                          {ga4DataPreviousTotal?.SESSIONS_VS_LY}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                          {ga4DataPreviousTotal?.TRANSACTIONS_VS_LY}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                          {ga4DataPreviousTotal?.DEMAND_VS_LY}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                          {/* {formatPercentage(ga4Total.aov_vs_ly)} */}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                          {/* {formatPercentage(ga4Total.conversion_rate_vs_ly)} */}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </div>
              {/* <Pagination
                currentPage={Number(ga4DataPreviousMeta?.page) || 0}
                totalPages={Number(ga4DataPreviousMeta?.totalPages) || 0}
                totalRows={Number(ga4DataPreviousMeta?.total) || 0}
                setCurrentPage={(val: any) => {
                  setGa4DataPreviousPage(val);
                }}
                itemsPerPage={Number(10) || 0}
              /> */}
            </div>
            <div className="sm:col-span-12 md:col-span-12 lg:col-span-5 space-y-4">
              <div className="flex justify-between items-center">
                <h2 className="text-lg font-semibold text-[#a1343c] italic">
                  OMS
                </h2>
              </div>
              <div className="overflow-x-auto">
                {isLoading2 ? (
                  <LoadingBox />
                ) : (
                  <table className="w-full">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Mars Year/Period
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Week
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Orders
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Demand
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          AOV
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {omsDataPrevious?.map((row: any, index) => (
                        <tr key={index} className="hover:bg-gray-50">
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                            {row?.YEAR} {row?.PERIOD}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                            {row?.WEEK}
                          </td>

                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                            <span
                              className={`px-3 py-1 rounded-full text-xs font-medium ${getPercentageColor(
                                row?.ORDERS_VS_LY
                              )}`}
                            >
                              {row?.ORDERS_VS_LY}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                            <span
                              className={`px-3 py-1 rounded-full text-xs font-medium ${getPercentageColor(
                                row?.DEMAND_VS_LY
                              )}`}
                            >
                              {row?.DEMAND_VS_LY}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                            <span
                              className={`px-3 py-1 rounded-full text-xs font-medium ${getPercentageColor(
                                row?.AOV_VS_LY
                              )}`}
                            >
                              {row?.AOV_VS_LY}
                            </span>
                          </td>
                        </tr>
                      ))}
                      <tr className="bg-blue-600">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white">
                          OMS Data
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                          {omsDataPreviousTotal?.ORDERS}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                          {omsDataPreviousTotal?.DEMAND}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                          {omsDataPreviousTotal?.AOV}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </div>
              {/* <Pagination
                currentPage={Number(omsDataPreviousMeta?.page) || 0}
                totalPages={Number(omsDataPreviousMeta?.totalPages) || 0}
                totalRows={Number(omsDataPreviousMeta?.total) || 0}
                setCurrentPage={(val: any) => {
                  setOmsDataPreviousPage(val);
                }}
                itemsPerPage={Number(10) || 0}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ga4;
