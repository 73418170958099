import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ArrowLeft, LayoutDashboard, ListFilter } from "lucide-react";
import Navbar from "../../components/navigation/navbar";
import { GET } from "../../utils/apiCalls";
import API from "../../config/api";
import NavbarCCM from "../../components/navigation/ccmnavbar";

const AccountView = () => {
  const { accountId } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState<any>();
  const [loading, setLoading] = React.useState(false);

  // React.useEffect(() => {
  //   const fetchProduct = async () => {
  //     setLoading(true);
  //     try {
  //       const response = await GET(API.EU_ALL_PRODUCTS + accountId);
  //       setProduct(response);
  //     } catch (error) {
  //       console.error("Error fetching product:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchProduct();
  // }, [accountId]);

  return (
    <div className="min-h-screen bg-gray-50">
      <NavbarCCM />
      <div className="flex items-center justify-between flex-wrap border-b border-gray-200 px-6 py-4 bg-white">
        <div className="flex items-center gap-3">
          <ArrowLeft
            className="h-5 w-5 cursor-pointer"
            onClick={() => navigate("/ccm-accounts")}
          />
          <div className="flex items-center gap-2">
            <LayoutDashboard />
            <h1 className="text-xl font-medium">
              Account: Dummy Data {accountId}
              <span className="font-bold">
                {loading ? "" : product?.NAME_EN}
              </span>
            </h1>
          </div>
        </div>
        <div className="flex gap-2">
          <button className="px-4 py-1.5 text-gray-700 border border-gray-300 rounded hover:bg-gray-50">
            Edit
          </button>
          <button className="px-4 py-1.5 text-white bg-blue-600 rounded hover:bg-blue-700">
            Save
          </button>
        </div>
      </div>
      {loading ? (
        <div className="flex justify-center items-center min-h-screen bg-gray-50">
          <div className="animate-pulse">
            <div className="w-16 h-16 border-4 border-gray-600 border-t-transparent rounded-full animate-spin"></div>
          </div>
        </div>
      ) : (
        <div className="p-6 bg-gray-50">
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            {/* Left Column */}
            <div className="col-span-12 md:col-span-6 bg-white rounded-lg shadow-md p-6">
              <div className="bg-gray-100 flex items-center justify-between mb-4 p-3 cursor-pointer">
                <div className="flex items-center gap-2">
                  <ListFilter className="h-5 w-5 text-blue-500" />
                  <span className="font-bold text-blue-500">
                    Account Information
                  </span>
                </div>
              </div>
              <div className="space-y-4">
                {/* Account Information Fields */}
                <div>
                  <label className="text-sm font-medium">Account Name</label>
                  <div className=" w-full   rounded">
                    {" "}
                    Dummy Data {accountId}
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="text-sm font-medium">Salutation</label>
                    <div className=" rounded">Mr.</div>
                  </div>
                  <div>
                    <label className="text-sm font-medium">First Name</label>
                    <div className=" rounded">John</div>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="text-sm font-medium">Middle Name</label>
                    <div className=" rounded">A.</div>
                  </div>
                  <div>
                    <label className="text-sm font-medium">Last Name</label>
                    <div className=" rounded">Doe</div>
                  </div>
                </div>
                <div>
                  <label className="text-sm font-medium">Suffix</label>
                  <div className=" rounded">Jr.</div>
                </div>
                <div>
                  <label className="text-sm font-medium">
                    Account Record Type
                  </label>
                  <div className=" rounded">Consumer DTC</div>
                </div>
                <div>
                  <label className="text-sm font-medium">Account Owner</label>
                  <div className=" rounded">Wissam Messarra</div>
                </div>
                <div>
                  <label className="text-sm font-medium">Email</label>
                  <div className=" rounded">john.doe@example.com</div>
                </div>
                <div className="flex items-center gap-4">
                  <label className="text-sm font-medium">Do Not Call</label>
                  <input
                    type="checkbox"
                    className="w-6 h-6 border border-gray-300 rounded"
                    checked={false}
                    readOnly
                  />
                </div>
                <div>
                  <label className="text-sm font-medium">Phone</label>
                  <div className=" rounded">+1234567890</div>
                </div>
                <div>
                  <label className="text-sm font-medium">Type</label>
                  <div className=" rounded">DTC</div>
                </div>
                <div>
                  <label className="text-sm font-medium">Mobile</label>
                  <div className=" rounded">+0987654321</div>
                </div>
                <div>
                  <label className="text-sm font-medium">ASI #</label>
                  <div className=" rounded">12345</div>
                </div>
                <div>
                  <label className="text-sm font-medium">Business Unit</label>
                  <div className=" rounded">--None--</div>
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div className="col-span-12 md:col-span-6 bg-white rounded-lg shadow-md p-6">
              {/* SAP Section */}
              <div className="bg-gray-100 flex items-center justify-between mb-4 p-3 cursor-pointer">
                <div className="flex items-center gap-2">
                  <ListFilter className="h-5 w-5 text-blue-500" />
                  <span className="font-bold text-blue-500">
                    SAP Information
                  </span>
                </div>
              </div>
              <div className="space-y-4">
                <div>
                  <label className="text-sm font-medium">SAP Account ID</label>
                  <div className=" rounded">67890</div>
                </div>
                <div>
                  <label className="text-sm font-medium">
                    SAP Accounts IDs
                  </label>
                  <div className=" rounded">67890, 12345</div>
                </div>
              </div>
              {/* Address Section */}
              <div className="bg-gray-100 flex items-center justify-between my-6 p-3 cursor-pointer">
                <div className="flex items-center gap-2">
                  <ListFilter className="h-5 w-5 text-blue-500" />
                  <span className="font-bold text-blue-500">
                    Address Information
                  </span>
                </div>
              </div>
              <div className="space-y-4">
                {/* Mailing Address */}
                <div>
                  <label className="text-sm font-medium">Mailing Address</label>
                  <div className=" rounded">123 Main St, Anytown, USA</div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="text-sm font-medium">
                      Mailing Country
                    </label>
                    <div className=" rounded">USA</div>
                  </div>
                  <div>
                    <label className="text-sm font-medium">
                      Mailing Street
                    </label>
                    <div className=" rounded">123 Main St</div>
                  </div>
                </div>
                {/* Billing Address */}
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="text-sm font-medium">
                      Billing Address
                    </label>
                    <div className=" rounded">456 Elm St, Othertown, USA</div>
                  </div>
                  <div>
                    <label className="text-sm font-medium">
                      Billing Country
                    </label>
                    <div className=" rounded">USA</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountView;
