import React, { useEffect, useState } from "react";
import DemandNavigation from "./components/tabBar";
import Navbar from "../../components/navigation/navbar";
import Nsv from "./components/nsv";
import Ga4 from "./components/ga4";
import B2b from "./components/b2b";
import { Form,message  } from "antd";
import { POST } from "../../utils/apiCalls";
import API from "../../config/api";
import Filters from "../../components/filters";
import { useSelector } from "react-redux";

const Performance = () => {
  const [selectedTabItem, setSelectedTabItem] = useState<any>({
    id: 1,
    title: "GA4 Demand/TRAFFIC",
  });
  const { filters, lastFetched } = useSelector((state: any) => state.Filter);

  const [form] = Form.useForm();
  const [salesChannel2Options, setSalesChannel2Options] = useState<any>({});

  const [isSalesChannel2Disabled, setIsSalesChannel2Disabled] = useState(true);
  const [formData, setFormData] = useState<any>({
    year: ["2024"],
  });

  useEffect(() => {
      form.setFieldsValue({ year: formData.year });
    }, []);
    const handleFormChange = async (changedValues: any, allValues: any) => {
      try {
        // Reset form if year is cleared
        if (changedValues.year !== undefined && (!changedValues.year || changedValues.year.length === 0)) {
          form.resetFields(['period', 'week', 'countries', 'channel', 'salesChannel', 'salesChannel2']);
          setFormData({ year: [] });
          return;
        }
  
        if (changedValues.salesChannel !== undefined) {
          if (changedValues.salesChannel && changedValues.salesChannel.length > 0) {
            const response = await POST(API.FILTERSBYCHANNEL, {
              channel: changedValues.salesChannel,
            });
            setSalesChannel2Options(response);
            setIsSalesChannel2Disabled(false);
          } else {
            form.setFieldsValue({
              salesChannel: [],
              salesChannel2: [],
            });
            setSalesChannel2Options({});
            setIsSalesChannel2Disabled(true);
          }
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    };
  
    const filterObject = (obj: Record<string, any>) => {
      return Object.fromEntries(
        Object.entries(obj).filter(
          ([key, value]) => Array.isArray(value) && value.length > 0
        )
      );
    };
  
    const handleFormSubmit = async (value: any) => {
      try {
        // Check if year is selected
        if (!value.year || value.year.length === 0) {
          message.error('Please select a year before applying filters');
          return;
        }
  
        let values = filterObject(value);
        console.log('Form values:', values);
        setFormData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    };
  return (
    <div className="min-h-screen bg-gray-50 font-sans">
      <Navbar />
      <div className="px-6 pt-4">
        <div className="flex items-center bg-blue-50 px-4 pt-4">
          <Filters
            filters={
              selectedTabItem?.id === 2
                ? filters.map((item: any) => {
                    if (
                      item.name === "channel" ||
                      item.name === "salesChannel"
                    ) {
                      return {
                        ...item,
                        disabled: true,
                      };
                    } else {
                      return item;
                    }
                  })
                : filters
            }
            form={form}
            handleFormChange={handleFormChange}
            handleFormFinish={handleFormSubmit}
            isSalesChannel2Disabled={
              false
              // selectedTabItem?.id === 2 ? true : isSalesChannel2Disabled
            }
            salesChannel2Options={salesChannel2Options}
          />
        </div>
      </div>
      <div className="flex items-center gap-2 bg-white border-b border-gray-200 px-6 py-4">
        <DemandNavigation
          selectedItem={(item: any) => setSelectedTabItem(item)}
        />
      </div>
      <div className="p-4 md:p-6">
        {selectedTabItem?.id === 1 ? (
          <Ga4 filters={formData}/>
        ) : selectedTabItem?.id === 2 ? (
          <Nsv />
        ) : selectedTabItem?.id === 3 ? (
          <B2b />
        ) : (
          <div className="text-xl font-bold text-center mt-[200px] text-[#a1343c]">
            Coming Soon
          </div>
        )}
      </div>
    </div>
  );
};

export default Performance;
