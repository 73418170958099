import React, { useState, useEffect } from "react";
import { ArrowUpDown, Eye, Search, X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

interface Case {
  id: number;
  Case_Reason_Name: string;
  Owner: string;
  Intent: string;
  Intent_Code: string;
  Area: string;
  Area_Code: string;
  Specifics: string;
  Specifics_Code: string;
  Issue: string;
  Issue_Code: string;
  Case_Reason: string;
  Case_Reason_Code: string;
  Case_Reason_Long_Description: string;
  Do_Not_Send_Survey: string;
  Vital_Reason_Code: string;
  Product_Segment: string;
  Vital_Case_Reason: string;
  Responsible_Area: string;
  Responsible_Area_Code: string;
  Severity: string;
  Severity_Code: string;
  Business_Segment: string;
  Business_Segment_Code: string;
  Reason_Search_String_Czech: string;
  Reason_Search_String_Italian: string;
  Reason_Search_String_English: string;
  Reason_Search_String_Polish: string;
  Reason_Search_String_French: string;
  Reason_Search_String_Portuguese: string;
  Reason_Search_String_German: string;
  Reason_Search_String_Spanish: string;
}

interface CasesResponse {
  data: Case[];
  totalRecords: number;
  filteredRecords: number;
  totalPages: number;
  currentPage: number;
}

interface CasesTableProps {
  totalRecords: (val: number) => void;
  filteredRecords: (val: number) => void;
}

const dummyData: Case[] = [
  {
    id: 1,
    Case_Reason_Name: "Reason E",
    Owner: "Wissam Messarra",
    Intent: "Intent E",
    Intent_Code: "Code E",
    Area: "Area E",
    Area_Code: "A005",
    Specifics: "Specific E",
    Specifics_Code: "S005",
    Issue: "Issue E",
    Issue_Code: "I005",
    Case_Reason: "CR E",
    Case_Reason_Code: "CRC005",
    Case_Reason_Long_Description: "Description for Reason E",
    Do_Not_Send_Survey: "1",
    Vital_Reason_Code: "VR005",
    Product_Segment: "Segment E",
    Vital_Case_Reason: "Vital Reason E",
    Responsible_Area: "Resp E",
    Responsible_Area_Code: "RA005",
    Severity: "Low",
    Severity_Code: "SC005",
    Business_Segment: "Business E",
    Business_Segment_Code: "BS005",
    Reason_Search_String_Czech: "Czech E",
    Reason_Search_String_Italian: "Italian E",
    Reason_Search_String_English: "English E",
    Reason_Search_String_Polish: "Polish E",
    Reason_Search_String_French: "French E",
    Reason_Search_String_Portuguese: "Portuguese E",
    Reason_Search_String_German: "German E",
    Reason_Search_String_Spanish: "Spanish E",
  },
  {
    id: 2,
    Case_Reason_Name: "Reason D",
    Owner: "Wissam Messarra",
    Intent: "Intent D",
    Intent_Code: "Code D",
    Area: "Area D",
    Area_Code: "A004",
    Specifics: "Specific D",
    Specifics_Code: "S004",
    Issue: "Issue D",
    Issue_Code: "I004",
    Case_Reason: "CR D",
    Case_Reason_Code: "CRC004",
    Case_Reason_Long_Description: "Description for Reason D",
    Do_Not_Send_Survey: "0",
    Vital_Reason_Code: "VR004",
    Product_Segment: "Segment D",
    Vital_Case_Reason: "Vital Reason D",
    Responsible_Area: "Resp D",
    Responsible_Area_Code: "RA004",
    Severity: "Critical",
    Severity_Code: "SC004",
    Business_Segment: "Business D",
    Business_Segment_Code: "BS004",
    Reason_Search_String_Czech: "Czech D",
    Reason_Search_String_Italian: "Italian D",
    Reason_Search_String_English: "English D",
    Reason_Search_String_Polish: "Polish D",
    Reason_Search_String_French: "French D",
    Reason_Search_String_Portuguese: "Portuguese D",
    Reason_Search_String_German: "German D",
    Reason_Search_String_Spanish: "Spanish D",
  },
  {
    id: 3,
    Case_Reason_Name: "Reason C",
    Owner: "Wissam Messarra",
    Intent: "Intent C",
    Intent_Code: "Code C",
    Area: "Area C",
    Area_Code: "A003",
    Specifics: "Specific C",
    Specifics_Code: "S003",
    Issue: "Issue C",
    Issue_Code: "I003",
    Case_Reason: "CR C",
    Case_Reason_Code: "CRC003",
    Case_Reason_Long_Description: "Description for Reason C",
    Do_Not_Send_Survey: "1",
    Vital_Reason_Code: "VR003",
    Product_Segment: "Segment C",
    Vital_Case_Reason: "Vital Reason C",
    Responsible_Area: "Resp C",
    Responsible_Area_Code: "RA003",
    Severity: "High",
    Severity_Code: "SC003",
    Business_Segment: "Business C",
    Business_Segment_Code: "BS003",
    Reason_Search_String_Czech: "Czech C",
    Reason_Search_String_Italian: "Italian C",
    Reason_Search_String_English: "English C",
    Reason_Search_String_Polish: "Polish C",
    Reason_Search_String_French: "French C",
    Reason_Search_String_Portuguese: "Portuguese C",
    Reason_Search_String_German: "German C",
    Reason_Search_String_Spanish: "Spanish C",
  },
  {
    id: 4,
    Case_Reason_Name: "Reason B",
    Owner: "Wissam Messarra",
    Intent: "Intent B",
    Intent_Code: "Code B",
    Area: "Area B",
    Area_Code: "A002",
    Specifics: "Specific B",
    Specifics_Code: "S002",
    Issue: "Issue B",
    Issue_Code: "I002",
    Case_Reason: "CR B",
    Case_Reason_Code: "CRC002",
    Case_Reason_Long_Description: "Description for Reason B",
    Do_Not_Send_Survey: "0",
    Vital_Reason_Code: "VR002",
    Product_Segment: "Segment B",
    Vital_Case_Reason: "Vital Reason B",
    Responsible_Area: "Resp B",
    Responsible_Area_Code: "RA002",
    Severity: "Medium",
    Severity_Code: "SC002",
    Business_Segment: "Business B",
    Business_Segment_Code: "BS002",
    Reason_Search_String_Czech: "Czech B",
    Reason_Search_String_Italian: "Italian B",
    Reason_Search_String_English: "English B",
    Reason_Search_String_Polish: "Polish B",
    Reason_Search_String_French: "French B",
    Reason_Search_String_Portuguese: "Portuguese B",
    Reason_Search_String_German: "German B",
    Reason_Search_String_Spanish: "Spanish B",
  },
  {
    id: 5,
    Case_Reason_Name: "Reason A",
    Owner: "Wissam Messarra",
    Intent: "Intent A",
    Intent_Code: "Code A",
    Area: "Area A",
    Area_Code: "A001",
    Specifics: "Specific A",
    Specifics_Code: "S001",
    Issue: "Issue A",
    Issue_Code: "I001",
    Case_Reason: "CR A",
    Case_Reason_Code: "CRC001",
    Case_Reason_Long_Description: "Description for Reason A",
    Do_Not_Send_Survey: "1",
    Vital_Reason_Code: "VR001",
    Product_Segment: "Segment A",
    Vital_Case_Reason: "Vital Reason A",
    Responsible_Area: "Resp A",
    Responsible_Area_Code: "RA001",
    Severity: "Low",
    Severity_Code: "SC001",
    Business_Segment: "Business A",
    Business_Segment_Code: "BS001",
    Reason_Search_String_Czech: "Czech A",
    Reason_Search_String_Italian: "Italian A",
    Reason_Search_String_English: "English A",
    Reason_Search_String_Polish: "Polish A",
    Reason_Search_String_French: "French A",
    Reason_Search_String_Portuguese: "Portuguese A",
    Reason_Search_String_German: "German A",
    Reason_Search_String_Spanish: "Spanish A",
  },
];

const CasesTable: React.FC<CasesTableProps> = ({
  totalRecords,
  filteredRecords,
}) => {
  const [cases, setCases] = useState<Case[]>(dummyData);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sortField, setSortField] = useState("created_at");
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC">("DESC");
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecordsCount, setTotalRecordsCount] = useState(dummyData.length);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCases = async () => {
      setIsLoading(true);
      try {
        // Simulate API call with dummy data
        let filteredData = dummyData;

        // Apply search filter
        if (searchTerm) {
          filteredData = filteredData.filter((caseItem) =>
            Object.values(caseItem).some((value) =>
              value.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
          );
        }

        const data: CasesResponse = {
          data: filteredData,
          totalRecords: dummyData.length,
          filteredRecords: filteredData.length,
          totalPages: Math.ceil(filteredData.length / itemsPerPage),
          currentPage: currentPage,
        };

        setCases(data.data);
        setTotalPages(data.totalPages);
        setTotalRecordsCount(data.totalRecords);
        totalRecords(data.totalRecords);
        filteredRecords(data.filteredRecords);
      } catch (error) {
        console.error("Error fetching cases:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCases();
  }, [searchTerm, currentPage, itemsPerPage, sortField, sortOrder]);

  const handleSort = (column: string) => {
    if (sortField === column) {
      setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC");
    } else {
      setSortField(column);
      setSortOrder("ASC");
    }
  };

  const handleView = (caseId: string) => {
    navigate(`/ccm-case/${caseId}`);
  };

  const clearSearch = () => {
    setSearchTerm("");
    setCurrentPage(1);
  };

  const handleEntriesChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const renderPageNumbers = () => {
    const pages = [];
    const showAround = 1;

    pages.push(1);

    if (currentPage > 2 + showAround) {
      pages.push("...");
    }

    for (
      let i = Math.max(2, currentPage - showAround);
      i <= Math.min(totalPages - 1, currentPage + showAround);
      i++
    ) {
      pages.push(i);
    }

    if (currentPage < totalPages - (1 + showAround)) {
      pages.push("...");
    }

    if (totalPages > 1) {
      pages.push(totalPages);
    }

    return (
      <div className="flex items-center space-x-1">
        {pages.map((page, index) => (
          <React.Fragment key={index}>
            {page === "..." ? (
              <span className="px-2 py-1 text-gray-500">...</span>
            ) : (
              <button
                onClick={() => setCurrentPage(page as number)}
                className={`min-w-[32px] px-2 py-1 rounded text-sm ${
                  currentPage === page
                    ? "bg-gray-600 text-white"
                    : "text-gray-600 hover:bg-gray-100"
                }`}
              >
                {page}
              </button>
            )}
          </React.Fragment>
        ))}
      </div>
    );
  };

  return (
    <div className="p-4 md:p-6 lg:col-span-10">
      <div className="bg-white rounded-lg border border-gray-200 shadow-sm">
        {/* Table Header */}
        <div className="p-4 border-b border-gray-200">
          <h2 className="text-xl font-semibold">Cases</h2>
          <div className="mt-4 flex flex-col sm:flex-row justify-between items-center gap-4">
            <div className="flex items-center gap-2">
              <span className="text-sm text-gray-600">Show</span>
              <select
                value={itemsPerPage}
                onChange={handleEntriesChange}
                className="px-2 py-1 border border-gray-300 rounded-md text-sm"
              >
                {[10, 25, 50, 100].map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <span className="text-sm text-gray-600">entries</span>
            </div>
            <div className="relative w-full sm:w-auto">
              <Search className="h-5 w-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                placeholder="Search Cases...."
                className="w-full sm:w-64 pl-10 pr-10 py-2 border border-gray-300 rounded-lg"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  setCurrentPage(1);
                }}
              />
              {searchTerm && (
                <button
                  onClick={clearSearch}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
                >
                  <X className="h-5 w-5" />
                </button>
              )}
            </div>
          </div>
        </div>

        {/* Table Content */}
        <div className="overflow-x-auto">
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            <table className="w-full">
              <thead className="bg-gray-50">
                <tr>
                  {[
                    { key: "Case_Reason_Name", label: "Case Reason Name" },
                    { key: "Owner", label: "Owner" },
                    { key: "Intent", label: "Intent" },
                    { key: "Intent_Code", label: "Intent Code" },
                    { key: "Area", label: "Area" },
                    { key: "Area_Code", label: "Area Code" },
                    { key: "Specifics", label: "Specifics" },
                    { key: "Specifics_Code", label: "Specifics Code" },
                    { key: "Issue", label: "Issue" },
                    { key: "Issue_Code", label: "Issue Code" },
                    { key: "Case_Reason", label: "Case Reason" },
                    { key: "Case_Reason_Code", label: "Case Reason Code" },
                    {
                      key: "Case_Reason_Long_Description",
                      label: "Case Reason Long Description",
                    },
                    { key: "Do_Not_Send_Survey", label: "Do Not Send Survey" },
                    { key: "Vital_Reason_Code", label: "Vital Reason Code" },
                    { key: "Product_Segment", label: "Product Segment" },
                    { key: "Vital_Case_Reason", label: "Vital Case Reason" },
                    { key: "Responsible_Area", label: "Responsible Area" },
                    {
                      key: "Responsible_Area_Code",
                      label: "Responsible Area Code",
                    },
                    { key: "Severity", label: "Severity" },
                    { key: "Severity_Code", label: "Severity Code" },
                    { key: "Business_Segment", label: "Business Segment" },
                    {
                      key: "Business_Segment_Code",
                      label: "Business Segment Code",
                    },
                    {
                      key: "Reason_Search_String_Czech",
                      label: "Reason Search String Czech",
                    },
                    {
                      key: "Reason_Search_String_Italian",
                      label: "Reason Search String Italian",
                    },
                    {
                      key: "Reason_Search_String_English",
                      label: "Reason Search String English",
                    },
                    {
                      key: "Reason_Search_String_Polish",
                      label: "Reason Search String Polish",
                    },
                    {
                      key: "Reason_Search_String_French",
                      label: "Reason Search String French",
                    },
                    {
                      key: "Reason_Search_String_Portuguese",
                      label: "Reason Search String Portuguese",
                    },
                    {
                      key: "Reason_Search_String_German",
                      label: "Reason Search String German",
                    },
                    {
                      key: "Reason_Search_String_Spanish",
                      label: "Reason Search String Spanish",
                    },
                  ].map(({ key, label }) => (
                    <th
                      key={key}
                      onClick={() => handleSort(key)}
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                    >
                      <div className="flex items-center space-x-1">
                        <span>{label}</span>
                        <ArrowUpDown className="h-4 w-4" />
                      </div>
                    </th>
                  ))}
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {cases?.map((caseItem) => (
                  <tr key={caseItem.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-blue-600 font-medium">
                      {caseItem.Case_Reason_Name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {caseItem.Owner}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {caseItem.Intent}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {caseItem.Intent_Code}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {caseItem.Area}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {caseItem.Area_Code}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {caseItem.Specifics}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {caseItem.Specifics_Code}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {caseItem.Issue}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {caseItem.Issue_Code}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {caseItem.Case_Reason}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {caseItem.Case_Reason_Code}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {caseItem.Case_Reason_Long_Description}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {caseItem.Do_Not_Send_Survey}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {caseItem.Vital_Reason_Code}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {caseItem.Product_Segment}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {caseItem.Vital_Case_Reason}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {caseItem.Responsible_Area}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {caseItem.Responsible_Area_Code}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {caseItem.Severity}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {caseItem.Severity_Code}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {caseItem.Business_Segment}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {caseItem.Business_Segment_Code}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {caseItem.Reason_Search_String_Czech}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {caseItem.Reason_Search_String_Italian}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {caseItem.Reason_Search_String_English}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {caseItem.Reason_Search_String_Polish}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {caseItem.Reason_Search_String_French}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {caseItem.Reason_Search_String_Portuguese}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {caseItem.Reason_Search_String_German}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {caseItem.Reason_Search_String_Spanish}
                    </td>
                    <td>
                      <div className="flex items-center gap-3">
                        <button
                          onClick={() => handleView(caseItem.id.toString())}
                          className="text-blue-600 hover:text-blue-800"
                        >
                          <Eye className="h-5 w-5" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        {/* Pagination */}
        <div className="px-6 py-4 border-t border-gray-200">
          <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
            <div className="text-sm text-gray-500">
              Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
              {Math.min(currentPage * itemsPerPage, totalRecordsCount)} of{" "}
              {totalRecordsCount} entries
            </div>
            <div className="flex items-center space-x-2">
              <button
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
                className="px-3 py-1 text-sm disabled:opacity-50"
              >
                Previous
              </button>
              {renderPageNumbers()}
              <button
                onClick={() =>
                  setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                }
                disabled={currentPage === totalPages}
                className="px-3 py-1 text-sm disabled:opacity-50"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CasesTable;
