import React, { useState, useEffect } from "react";
import { ArrowUpDown, Eye, Search, X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

interface Opportunity {
  id: number;
  Opportunity_Name: string;
  Opportunity_Owner: string;
  Account_Name: string;
  Close_Date: string;
  Type: string;
  Stage: string;
  Primary_Campaign_Source: string;
  Probability: string;
  Budget_Confirmed: string;
  Opportunity_Amount: string;
  Discovery_Completed: string;
  NSV: string;
  ROI_Analysis_Completed: string;
  Receive_Marketing_Emails: string;
  Loss_Reason: string;
  Customer_Channel: string;
  Reason_for_Close_Lost: string;
  Customer_PO: string;
  Opportunity_Currency: string;
  Customer_Ref_Number: string;
  Product: string;
  Sale_Source_Origin: string;
  Billing_Address: string;
  Shipping_Address: string;
  Order_Number: string;
  Package_Choice: string;
}

interface OpportunitiesResponse {
  data: Opportunity[];
  totalRecords: number;
  filteredRecords: number;
  totalPages: number;
  currentPage: number;
}

interface OpportunitiesTableProps {
  totalRecords: (val: number) => void;
  filteredRecords: (val: number) => void;
}

const dummyData: Opportunity[] = [
  {
    id: 1,
    Opportunity_Name: "Opportunity A",
    Opportunity_Owner: "Wissam Messarra",
    Account_Name: "Account A",
    Close_Date: "2025-01-01",
    Type: "New Business",
    Stage: "Proposal",
    Primary_Campaign_Source: "Campaign A",
    Probability: "50",
    Budget_Confirmed: "1",
    Opportunity_Amount: "$100,000",
    Discovery_Completed: "1",
    NSV: "$50,000",
    ROI_Analysis_Completed: "0",
    Receive_Marketing_Emails: "1",
    Loss_Reason: "Price",
    Customer_Channel: "Channel A",
    Reason_for_Close_Lost: "Budget",
    Customer_PO: "PO123",
    Opportunity_Currency: "USD",
    Customer_Ref_Number: "Ref001",
    Product: "10lb Bag",
    Sale_Source_Origin: "Web",
    Billing_Address: "Address 1",
    Shipping_Address: "Ship Address 1",
    Order_Number: "ORD123",
    Package_Choice: "Standard",
  },
  {
    id: 2,
    Opportunity_Name: "Opportunity B",
    Opportunity_Owner: "Wissam Messarra",
    Account_Name: "Account B",
    Close_Date: "2025-02-01",
    Type: "Renewal",
    Stage: "Negotiation",
    Primary_Campaign_Source: "Campaign B",
    Probability: "75",
    Budget_Confirmed: "1",
    Opportunity_Amount: "$200,000",
    Discovery_Completed: "0",
    NSV: "$75,000",
    ROI_Analysis_Completed: "1",
    Receive_Marketing_Emails: "0",
    Loss_Reason: "Timing",
    Customer_Channel: "Channel B",
    Reason_for_Close_Lost: "Scope",
    Customer_PO: "PO124",
    Opportunity_Currency: "USD",
    Customer_Ref_Number: "Ref002",
    Product: "5lb Bag",
    Sale_Source_Origin: "Referral",
    Billing_Address: "Address 2",
    Shipping_Address: "Ship Address 2",
    Order_Number: "ORD124",
    Package_Choice: "Premium",
  },
  {
    id: 3,
    Opportunity_Name: "Opportunity C",
    Opportunity_Owner: "Wissam Messarra",
    Account_Name: "Account C",
    Close_Date: "2025-03-01",
    Type: "Upsell",
    Stage: "Closed Won",
    Primary_Campaign_Source: "Campaign C",
    Probability: "90",
    Budget_Confirmed: "0",
    Opportunity_Amount: "$150,000",
    Discovery_Completed: "1",
    NSV: "$60,000",
    ROI_Analysis_Completed: "0",
    Receive_Marketing_Emails: "1",
    Loss_Reason: "Competition",
    Customer_Channel: "Channel C",
    Reason_for_Close_Lost: "Price",
    Customer_PO: "PO125",
    Opportunity_Currency: "EUR",
    Customer_Ref_Number: "Ref003",
    Product: "7oz Bag",
    Sale_Source_Origin: "Event",
    Billing_Address: "Address 3",
    Shipping_Address: "Ship Address 3",
    Order_Number: "ORD125",
    Package_Choice: "Basic",
  },
  {
    id: 4,
    Opportunity_Name: "Opportunity D",
    Opportunity_Owner: "Wissam Messarra",
    Account_Name: "Account D",
    Close_Date: "2025-04-01",
    Type: "Cross-Sell",
    Stage: "Closed Lost",
    Primary_Campaign_Source: "Campaign D",
    Probability: "30",
    Budget_Confirmed: "1",
    Opportunity_Amount: "$250,000",
    Discovery_Completed: "0",
    NSV: "$85,000",
    ROI_Analysis_Completed: "1",
    Receive_Marketing_Emails: "0",
    Loss_Reason: "Budget",
    Customer_Channel: "Channel D",
    Reason_for_Close_Lost: "Timing",
    Customer_PO: "PO126",
    Opportunity_Currency: "GBP",
    Customer_Ref_Number: "Ref004",
    Product: "Acrylic 16oz",
    Sale_Source_Origin: "Email Campaign",
    Billing_Address: "Address 4",
    Shipping_Address: "Ship Address 4",
    Order_Number: "ORD126",
    Package_Choice: "Enterprise",
  },
  {
    id: 5,
    Opportunity_Name: "Opportunity E",
    Opportunity_Owner: "Wissam Messarra",
    Account_Name: "Account E",
    Close_Date: "2025-05-01",
    Type: "Retention",
    Stage: "Discovery",
    Primary_Campaign_Source: "Campaign E",
    Probability: "80",
    Budget_Confirmed: "0",
    Opportunity_Amount: "$300,000",
    Discovery_Completed: "1",
    NSV: "$95,000",
    ROI_Analysis_Completed: "1",
    Receive_Marketing_Emails: "1",
    Loss_Reason: "Scope",
    Customer_Channel: "Channel E",
    Reason_for_Close_Lost: "Competition",
    Customer_PO: "PO127",
    Opportunity_Currency: "AUD",
    Customer_Ref_Number: "Ref005",
    Product: "Business Card Kit",
    Sale_Source_Origin: "Social Media",
    Billing_Address: "Address 5",
    Shipping_Address: "Ship Address 5",
    Order_Number: "ORD127",
    Package_Choice: "Custom",
  },
];

const OpportunitiesTable: React.FC<OpportunitiesTableProps> = ({
  totalRecords,
  filteredRecords,
}) => {
  const [opportunities, setOpportunities] = useState<Opportunity[]>(dummyData);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sortField, setSortField] = useState("created_at");
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC">("DESC");
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecordsCount, setTotalRecordsCount] = useState(dummyData.length);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchOpportunities = async () => {
      setIsLoading(true);
      try {
        // Simulate API call with dummy data
        let filteredData = dummyData;

        // Apply search filter
        if (searchTerm) {
          filteredData = filteredData.filter((opportunityItem) =>
            Object.values(opportunityItem).some((value) =>
              value.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
          );
        }

        const data: OpportunitiesResponse = {
          data: filteredData,
          totalRecords: dummyData.length,
          filteredRecords: filteredData.length,
          totalPages: Math.ceil(filteredData.length / itemsPerPage),
          currentPage: currentPage,
        };

        setOpportunities(data.data);
        setTotalPages(data.totalPages);
        setTotalRecordsCount(data.totalRecords);
        totalRecords(data.totalRecords);
        filteredRecords(data.filteredRecords);
      } catch (error) {
        console.error("Error fetching opportunities:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOpportunities();
  }, [searchTerm, currentPage, itemsPerPage, sortField, sortOrder]);

  const handleSort = (column: string) => {
    if (sortField === column) {
      setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC");
    } else {
      setSortField(column);
      setSortOrder("ASC");
    }
  };

  const handleView = (opportunityId: string) => {
    navigate(`/ccm-opportunity/${opportunityId}`);
  };

  const clearSearch = () => {
    setSearchTerm("");
    setCurrentPage(1);
  };

  const handleEntriesChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const renderPageNumbers = () => {
    const pages = [];
    const showAround = 1;

    pages.push(1);

    if (currentPage > 2 + showAround) {
      pages.push("...");
    }

    for (
      let i = Math.max(2, currentPage - showAround);
      i <= Math.min(totalPages - 1, currentPage + showAround);
      i++
    ) {
      pages.push(i);
    }

    if (currentPage < totalPages - (1 + showAround)) {
      pages.push("...");
    }

    if (totalPages > 1) {
      pages.push(totalPages);
    }

    return (
      <div className="flex items-center space-x-1">
        {pages.map((page, index) => (
          <React.Fragment key={index}>
            {page === "..." ? (
              <span className="px-2 py-1 text-gray-500">...</span>
            ) : (
              <button
                onClick={() => setCurrentPage(page as number)}
                className={`min-w-[32px] px-2 py-1 rounded text-sm ${
                  currentPage === page
                    ? "bg-gray-600 text-white"
                    : "text-gray-600 hover:bg-gray-100"
                }`}
              >
                {page}
              </button>
            )}
          </React.Fragment>
        ))}
      </div>
    );
  };

  return (
    <div className="p-4 md:p-6 lg:col-span-10">
      <div className="bg-white rounded-lg border border-gray-200 shadow-sm">
        {/* Table Header */}
        <div className="p-4 border-b border-gray-200">
          <h2 className="text-xl font-semibold">Opportunities</h2>
          <div className="mt-4 flex flex-col sm:flex-row justify-between items-center gap-4">
            <div className="flex items-center gap-2">
              <span className="text-sm text-gray-600">Show</span>
              <select
                value={itemsPerPage}
                onChange={handleEntriesChange}
                className="px-2 py-1 border border-gray-300 rounded-md text-sm"
              >
                {[10, 25, 50, 100].map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <span className="text-sm text-gray-600">entries</span>
            </div>
            <div className="relative w-full sm:w-auto">
              <Search className="h-5 w-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                placeholder="Search Opportunities...."
                className="w-full sm:w-64 pl-10 pr-10 py-2 border border-gray-300 rounded-lg"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  setCurrentPage(1);
                }}
              />
              {searchTerm && (
                <button
                  onClick={clearSearch}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
                >
                  <X className="h-5 w-5" />
                </button>
              )}
            </div>
          </div>
        </div>

        {/* Table Content */}
        <div className="overflow-x-auto">
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            <table className="w-full">
              <thead className="bg-gray-50">
                <tr>
                  {[
                    { key: "Opportunity_Name", label: "Opportunity Name" },
                    { key: "Opportunity_Owner", label: "Opportunity Owner" },
                    { key: "Account_Name", label: "Account Name" },
                    { key: "Close_Date", label: "Close Date" },
                    { key: "Type", label: "Type" },
                    { key: "Stage", label: "Stage" },
                    {
                      key: "Primary_Campaign_Source",
                      label: "Primary Campaign Source",
                    },
                    { key: "Probability", label: "Probability" },
                    { key: "Budget_Confirmed", label: "Budget Confirmed" },
                    { key: "Opportunity_Amount", label: "Opportunity Amount" },
                    {
                      key: "Discovery_Completed",
                      label: "Discovery Completed",
                    },
                    { key: "NSV", label: "NSV" },
                    {
                      key: "ROI_Analysis_Completed",
                      label: "ROI Analysis Completed",
                    },
                    {
                      key: "Receive_Marketing_Emails",
                      label: "Receive Marketing Emails",
                    },
                    { key: "Loss_Reason", label: "Loss Reason" },
                    { key: "Customer_Channel", label: "Customer Channel" },
                    {
                      key: "Reason_for_Close_Lost",
                      label: "Reason for Close Lost",
                    },
                    { key: "Customer_PO", label: "Customer PO" },
                    {
                      key: "Opportunity_Currency",
                      label: "Opportunity Currency",
                    },
                    {
                      key: "Customer_Ref_Number",
                      label: "Customer Ref Number",
                    },
                    { key: "Product", label: "Product" },
                    { key: "Sale_Source_Origin", label: "Sale Source Origin" },
                    { key: "Billing_Address", label: "Billing Address" },
                    { key: "Shipping_Address", label: "Shipping Address" },
                    { key: "Order_Number", label: "Order Number" },
                    { key: "Package_Choice", label: "Package Choice" },
                  ].map(({ key, label }) => (
                    <th
                      key={key}
                      onClick={() => handleSort(key)}
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                    >
                      <div className="flex items-center space-x-1">
                        <span>{label}</span>
                        <ArrowUpDown className="h-4 w-4" />
                      </div>
                    </th>
                  ))}
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {opportunities?.map((opportunityItem) => (
                  <tr key={opportunityItem.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-blue-600 font-medium">
                      {opportunityItem.Opportunity_Name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {opportunityItem.Opportunity_Owner}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {opportunityItem.Account_Name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {opportunityItem.Close_Date}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {opportunityItem.Type}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {opportunityItem.Stage}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {opportunityItem.Primary_Campaign_Source}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {opportunityItem.Probability}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {opportunityItem.Budget_Confirmed}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {opportunityItem.Opportunity_Amount}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {opportunityItem.Discovery_Completed}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {opportunityItem.NSV}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {opportunityItem.ROI_Analysis_Completed}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {opportunityItem.Receive_Marketing_Emails}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {opportunityItem.Loss_Reason}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {opportunityItem.Customer_Channel}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {opportunityItem.Reason_for_Close_Lost}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {opportunityItem.Customer_PO}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {opportunityItem.Opportunity_Currency}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {opportunityItem.Customer_Ref_Number}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {opportunityItem.Product}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {opportunityItem.Sale_Source_Origin}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {opportunityItem.Billing_Address}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {opportunityItem.Shipping_Address}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {opportunityItem.Order_Number}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {opportunityItem.Package_Choice}
                    </td>
                    <td>
                      <div className="flex items-center gap-3">
                        <button
                          onClick={() =>
                            handleView(opportunityItem.id.toString())
                          }
                          className="text-blue-600 hover:text-blue-800"
                        >
                          <Eye className="h-5 w-5" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        {/* Pagination */}
        <div className="px-6 py-4 border-t border-gray-200">
          <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
            <div className="text-sm text-gray-500">
              Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
              {Math.min(currentPage * itemsPerPage, totalRecordsCount)} of{" "}
              {totalRecordsCount} entries
            </div>
            <div className="flex items-center space-x-2">
              <button
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
                className="px-3 py-1 text-sm disabled:opacity-50"
              >
                Previous
              </button>
              {renderPageNumbers()}
              <button
                onClick={() =>
                  setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                }
                disabled={currentPage === totalPages}
                className="px-3 py-1 text-sm disabled:opacity-50"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpportunitiesTable;
