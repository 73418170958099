import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import LoadingBox from "../../../components/LoadingBox";
import { useEffect, useState } from "react";
import { POST } from "../../../utils/apiCalls";
import API from "../../../config/api";
import { ArrowUpDown } from "lucide-react";
import Pagination from "./pagination";

const FactoryConsumption = (props: any) => {
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);

  const [data, setData] = useState<any>();
  const [page, setPage] = useState<any>(1);

  const [dataSortField, setDataSortField] = useState<any>("NAME");
  const [dataSortOrder, setDataSortOrder] = useState<"ASC" | "DESC">("DESC");
  const [graphSortField, setGraphSortField] = useState<any>("period");
  const [graphSortOrder, setGraphSortOrder] = useState<"ASC" | "DESC">("DESC");
  const [graphData, setGraphData] = useState<any>();

  useEffect(() => {
    loadGraph({
      ...props.filters,
      sortBy: "period",
      sortOrder: "ASC",
      page: page,
      limit: 10,
    });
  }, [props.filters]);
  useEffect(() => {
    loadDetails({
      ...props.filters,
      sortBy: columns.some((item: any) => item.key === dataSortField)
        ? dataSortField
        : null,
      sortOrder: columns.some((item: any) => item.key === dataSortField)
        ? dataSortOrder
        : null,
      page: page,
      limit: 10,
    });
  }, [page, props.filters, dataSortField, dataSortOrder]);

  const columns = [
    { key: "NAME", label: "Name" },
    { key: "P1", label: "p1" },
    { key: "P2", label: "p2" },
    { key: "P3", label: "p3" },
    { key: "P4", label: "p4" },
    { key: "P5", label: "p5" },
    { key: "P6", label: "p6" },
    { key: "P7", label: "p7" },
    { key: "P8", label: "p8" },
    { key: "P9", label: "p9" },
    { key: "P10", label: "p10" },
    { key: "P11", label: "p11" },
    { key: "P12", label: "p12" },
    { key: "P13", label: "p13" },
  ];

  const lineData = graphData
    ? graphData.map((item: any) => ({
        period: item.period,
        value: item.quantity,
      }))
    : [];

  const loadDetails = async (filterValues: any = {}) => {
    setIsLoading1(true);
    try {
      const response: any = await POST(API.STOCK_DETAILS, filterValues);
      if (response) {
        setData(response);
        setPage(response?.currentPage);
        setIsLoading1(false);
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setIsLoading1(false);
    }
  };
  const loadGraph = async (filterValues: any = {}) => {
    setIsLoading2(true);
    try {
      const responseGraph: any = await POST(
        API.GET_FACTORY_CONSEMPTION_GRAPH,
        filterValues
      );
      setGraphData(responseGraph);
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setIsLoading2(false);
    }
  };
  const handleSort = (column: string) => {
    if (dataSortField === column) {
      setDataSortOrder(dataSortOrder === "ASC" ? "DESC" : "ASC");
    } else {
      setDataSortField(column);
      setDataSortOrder("ASC");
    }
  };

  const getTotal = (data: any[], field: string): number => {
    return data?.reduce((total: number, item: any) => {
      const value = parseFloat(item[field]);
      return total + (isNaN(value) ? 0 : value);
    }, 0);
  };
  return (
    <div>
      <div className="bg-white rounded-lg shadow">
        <div className="p-4 border-b border-gray-200">
          <h2 className="text-lg font-semibold text-[#6b2228]">
            Produced (BOM Stock Movement/Consumption){" "}
          </h2>
        </div>
        <div className="overflow-x-auto">
          {isLoading1 ? (
            <div>
              <LoadingBox />
            </div>
          ) : (
            <table className="w-full">
              <thead className="bg-gray-50">
                <tr>
                  {columns.map(({ key, label }) => (
                    <th
                      key={key}
                      onClick={() => handleSort(key)}
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                    >
                      <div className="flex items-center space-x-1">
                        <span>{label}</span>
                        <ArrowUpDown className="h-4 w-4" />
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {data?.rows?.map((row: any, index: any) => (
                  <tr key={index} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {row.NAME}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {row.P1}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {row.P2}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {row.P3}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {row.P4}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {row.P5}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {row.P6}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {row.P7}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {row.P8}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {row.P9}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {row.P10}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {row.P11}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {row.P12}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {row.P13}
                    </td>
                  </tr>
                ))}
                <tr className="bg-blue-600">
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white">
                    Total
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                    {getTotal(data?.rows, "P1")}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                    {getTotal(data?.rows, "P2")}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                    {getTotal(data?.rows, "P3")}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                    {getTotal(data?.rows, "P4")}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                    {getTotal(data?.rows, "P5")}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                    {getTotal(data?.rows, "P6")}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                    {getTotal(data?.rows, "P7")}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                    {getTotal(data?.rows, "P8")}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                    {getTotal(data?.rows, "P9")}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                    {getTotal(data?.rows, "P10")}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                    {getTotal(data?.rows, "P11")}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                    {getTotal(data?.rows, "P12")}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                    {getTotal(data?.rows, "P13")}
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
        <Pagination
          currentPage={Number(data?.currentPage) || 0}
          totalPages={Number(data?.totalPages) || 0}
          totalRows={Number(data?.count) || 0}
          setCurrentPage={(val: any) => {
            setPage(val);
          }}
          itemsPerPage={Number(10) || 0}
        />
      </div>
      <div className="bg-white rounded-lg shadow mt-5">
        <div className="p-4 border-b border-gray-200">
          <h2 className="text-lg font-semibold text-[#6b2228]">
            Produced (BOM Stock Movement/Consumption)
          </h2>
        </div>
        {isLoading2 ? (
          <LoadingBox />
        ) : (
          <div className="w-full h-[320px] mt-12">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={lineData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="period"
                  angle={-90}
                  textAnchor="end"
                  height={100}
                  axisLine={false}
                  label={{
                    value: "Mars Period",
                    //   angle: -90,
                    position: "center",
                    dx: 0,
                    dy: 20,
                    style: { textAnchor: "middle", fontSize: "12px" },
                  }}
                />
                <YAxis
                  dataKey={"value"}
                  axisLine={false}
                  tickLine={false}
                  tickFormatter={(value) => `${(value / 1000000).toFixed(1)}M`}
                  label={{
                    value: "Qty",
                    angle: -90,
                    position: "insideLeft",
                    dx: 0,
                    dy: 20,
                    style: { textAnchor: "middle", fontSize: "12px" },
                  }}
                />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="value"
                  stroke="#2196F3"
                  strokeWidth={2}
                  dot={false}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        )}
      </div>
    </div>
  );
};

export default FactoryConsumption;
