import { ArrowLeft, LayoutDashboard } from "lucide-react";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../../components/navigation/navbar";

const OrderShipment = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <div className="flex items-center justify-between flex-wrap border-b border-gray-200 px-6 py-4 bg-white">
        <div className="flex items-center gap-3">
          <ArrowLeft
            className="h-5 w-5 cursor-pointer"
            onClick={() => navigate("/oms-dashboard")}
          />
          <div className="flex items-center gap-2">
            <LayoutDashboard />
            <h1 className="text-xl font-medium">
              Order & Shipment Collaboration: (Post-POC Phase)
              <span className="font-bold">
                {/* {loading ? "" : product?.NAME_EN} */}
              </span>
            </h1>
          </div>
        </div>
        <div className="flex gap-2">
          <button className="px-4 py-1.5 text-gray-700 border border-gray-300 rounded hover:bg-gray-50">
            Edit
          </button>
          <button className="px-4 py-1.5 text-white bg-blue-600 rounded hover:bg-blue-700">
            Save
          </button>
        </div>
      </div>
      {loading ? (
        <div className="flex justify-center items-center min-h-screen bg-gray-50">
          <div className="animate-pulse">
            <div className="w-16 h-16 border-4 border-gray-600 border-t-transparent rounded-full animate-spin"></div>
          </div>
        </div>
      ) : (
        <div className="p-6">
          <div className="grid grid-cols-1 md:grid-cols-12 gap-6 items-stretch">
            <div className="col-span-12 md:col-span-4 flex flex-col">
              <div className="bg-white rounded-lg shadow-md p-4 h-full">
                <div className="bg-gray-100 flex items-center justify-between mb-1 p-3 cursor-pointer">
                  <div className="flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="lucide lucide-package-check h-5 w-5 text-blue-500"
                    >
                      <path d="M16 16 12 12 8 16"></path>

                      <path d="M12 12V4"></path>

                      <path d="M12 4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v18a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2"></path>
                    </svg>

                    <span className="font-bold text-blue-500">
                      Order Collaboration Overview
                    </span>
                  </div>
                </div>

                <div className="text-sm text-gray-600 mt-1">
                  <div className="divide-y divide-gray-100">
                    <div className="flex items-start gap-3 p-2 bg-blue-50 rounded-lg">
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">Order ID</div>

                        <div className="mt-1 font-medium text-blue-600 break-words">
                          ORD-202402-120
                        </div>
                      </div>
                    </div>

                    <div className="flex items-start gap-3 p-2">
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">Supplier</div>

                        <div className="mt-1 font-medium text-gray-900 break-words">
                          XYZ Manufacturing Ltd.
                        </div>
                      </div>
                    </div>

                    <div className="flex items-start gap-3 p-2">
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">
                          Order Status
                        </div>

                        <div className="mt-1 font-medium text-yellow-600 break-words">
                          Processing
                        </div>
                      </div>
                    </div>

                    <div className="flex items-start gap-3 p-2">
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">
                          Estimated Delivery
                        </div>

                        <div className="mt-1 font-medium text-gray-900 break-words">
                          March 5, 2025
                        </div>
                      </div>
                    </div>

                    <div className="flex items-start gap-3 p-2">
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">
                          Shipment Status
                        </div>

                        <div className="mt-1 font-medium text-green-600 break-words">
                          In Transit
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-span-12 md:col-span-8">
              <div className="bg-white rounded-lg shadow-md p-4 mb-6">
                <div className="bg-gray-100 flex items-center justify-between mb-1 p-3 cursor-pointer">
                  <div className="flex items-center gap-2">
                    <span className="font-bold text-blue-500">
                      Shipment Tracking
                    </span>
                  </div>
                </div>

                <div className="text-sm text-gray-600 mt-1">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="bg-white p-3 rounded-lg shadow-sm">
                      <dt className="text-xs text-gray-500 uppercase">
                        Shipment ID
                      </dt>

                      <dd className="text-sm font-medium text-gray-800">
                        SHIP-45689
                      </dd>
                    </div>

                    <div className="bg-white p-3 rounded-lg shadow-sm">
                      <dt className="text-xs text-gray-500 uppercase">
                        Carrier
                      </dt>

                      <dd className="text-sm font-medium text-gray-800">
                        DHL Logistics
                      </dd>
                    </div>

                    <div className="bg-white p-3 rounded-lg shadow-sm">
                      <dt className="text-xs text-gray-500 uppercase">
                        Tracking Number
                      </dt>

                      <dd className="text-sm font-medium text-gray-800">
                        DHL-123456789
                      </dd>
                    </div>

                    <div className="bg-white p-3 rounded-lg shadow-sm">
                      <dt className="text-xs text-gray-500 uppercase">
                        Current Location
                      </dt>

                      <dd className="text-sm font-medium text-gray-800">
                        Paris, France
                      </dd>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-lg shadow-md p-4 mb-6">
                <div className="bg-gray-100 flex items-center justify-between mb-1 p-3 cursor-pointer">
                  <div className="flex items-center gap-2">
                    <span className="font-bold text-blue-500">
                      Supplier Communication
                    </span>
                  </div>
                </div>

                <div className="text-sm text-gray-600 mt-1">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="bg-white p-3 rounded-lg shadow-sm">
                      <dt className="text-xs text-gray-500 uppercase">
                        Last Message
                      </dt>

                      <dd className="text-sm font-medium text-gray-800">
                        Supplier confirmed shipment on Feb 28
                      </dd>
                    </div>

                    <div className="bg-white p-3 rounded-lg shadow-sm">
                      <dt className="text-xs text-gray-500 uppercase">
                        Pending Responses
                      </dt>

                      <dd className="text-sm font-medium text-red-800">
                        1 Unanswered Query
                      </dd>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-lg shadow-md p-4">
                <div className="bg-gray-100 flex items-center justify-between mb-1 p-3 cursor-pointer">
                  <div className="flex items-center gap-2">
                    <span className="font-bold text-blue-500">
                      Performance Metrics
                    </span>
                  </div>
                </div>

                <div className="text-sm text-gray-600 mt-1">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="bg-white p-3 rounded-lg shadow-sm">
                      <dt className="text-xs text-gray-500 uppercase">
                        On-Time Delivery Rate
                      </dt>

                      <dd className="text-sm font-medium text-green-800">
                        95%
                      </dd>
                    </div>

                    <div className="bg-white p-3 rounded-lg shadow-sm">
                      <dt className="text-xs text-gray-500 uppercase">
                        Average Lead Time
                      </dt>

                      <dd className="text-sm font-medium text-gray-800">
                        7 Days
                      </dd>
                    </div>

                    <div className="bg-white p-3 rounded-lg shadow-sm">
                      <dt className="text-xs text-gray-500 uppercase">
                        Issue Resolution Speed
                      </dt>

                      <dd className="text-sm font-medium text-gray-800">
                        24 Hours
                      </dd>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderShipment;
