import React, { useEffect, useState } from "react";
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import LoadingBox from "../../../components/LoadingBox";
import { ArrowUpDown } from "lucide-react";
import Pagination from "./pagination";
import { POST } from "../../../utils/apiCalls";
import API from "../../../config/api";

const ProductSplit = (props: any) => {
  const [pageperiodicNsv, setPageperiodicNsv] = useState<any>(1);
  const [pagetopProducts, setPagetopProducts] = useState<any>(1);
  const [pagesalesKPIS, setPagesalesKPIS] = useState<any>(1);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const [isLoading4, setIsLoading4] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [distributionData, setDistributionData] = useState<any>();
  const [topProductsData, setTopProductsData] = useState<any>();
  const [topProductsMeta, setTopProductsMeta] = useState<any>();
  const [periodicNsvData, setPeriodicNsvData] = useState<any>();
  const [periodicNsvMeta, setPeriodicNsvMeta] = useState<any>();
  const [salesKPISData, setSalesKPISData] = useState<any>();
  const [salesKPISMeta, setSalesKPISMeta] = useState<any>();

  const [topProductSortField, setTopProductSortField] =
    useState<any>("NAME_EN");
  const [topProductSortOrder, setTopProductSortOrder] = useState<
    "ASC" | "DESC"
  >("DESC");

  const [periodSortField, setPeriodSortField] = useState<any>("CATEGORY");
  const [periodSortOrder, setPeriodSortOrder] = useState<"ASC" | "DESC">(
    "DESC"
  );

  const [SalesSortField, setSalesSortField] = useState<any>("CATEGORY");
  const [SalesSortOrder, setSalesSortOrder] = useState<"ASC" | "DESC">("DESC");
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [props?.item]);

  const metaObj = (data: any) => {
    return {
      count: data?.count,
      totalPages: data?.totalPages,
      currentPage: data?.currentPage,
    };
  };
  const reqObj = (page: number, limit: number, filtersArray: any) => {
    return {
      ...filtersArray,
      page: page,
      limit: limit,
    };
  };

  useEffect(() => {
    TopProducts({
      ...props.filters,
      sortBy: columns1.some((item: any) => item.key === topProductSortField)
        ? topProductSortField
        : null,
      sortOrder: columns1.some((item: any) => item.key === topProductSortField)
        ? topProductSortOrder
        : null,
      page: pagetopProducts,
      limit: 10,
    });
  }, [
    pagetopProducts,
    props.filters,
    topProductSortField,
    topProductSortOrder,
  ]);
  useEffect(() => {
    PERIODIC_NSV({
      ...props.filters,
      sortBy: columns2.some((item: any) => item.key === periodSortField)
        ? periodSortField
        : null,
      sortOrder: columns2.some((item: any) => item.key === periodSortField)
        ? periodSortOrder
        : null,
      page: pageperiodicNsv,
      limit: 10,
    });
  }, [pageperiodicNsv, props.filters, periodSortField, periodSortOrder]);
  useEffect(() => {
    salesKPIS({
      ...props.filters,
      sortBy: columns3.some((item: any) => item.key === SalesSortField)
        ? SalesSortField
        : null,
      sortOrder: columns3.some((item: any) => item.key === SalesSortField)
        ? SalesSortOrder
        : null,
      page: pagesalesKPIS,
      limit: 10,
    });
  }, [pagesalesKPIS, props.filters, SalesSortField, SalesSortOrder]);
  useEffect(() => {
    categoryDistribution({
      ...props.filters,
      sortBy: "CATEGORY",
      sortOrder: "ASC",
    });
  }, [props.filters]);

  const TopProducts = async (filterValues: any = {}) => {
    try {
      setIsLoading1(true);
      const response: any = await POST(
        API.TOP_PRODUCTS,
        reqObj(pagetopProducts, 10, filterValues)
      );
      if (response) {
        setTopProductsData(response?.rows);
        setTopProductsMeta(metaObj(response));
        setPagetopProducts(response?.currentPage);
      }
    } catch (error) {
      console.error("Error fetching metrics:", error);
    } finally {
      setIsLoading1(false);
    }
  };
  const PERIODIC_NSV = async (filterValues: any = {}) => {
    setIsLoading2(true);
    try {
      const PERIODIC_NSV: any = await POST(API.PERIODIC_NSV, filterValues);
      setPeriodicNsvData(PERIODIC_NSV);
      setPeriodicNsvMeta(metaObj(PERIODIC_NSV));
      setPageperiodicNsv(PERIODIC_NSV?.currentPage);
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setIsLoading2(false);
    }
  };
  const categoryDistribution = async (filterValues: any = {}) => {
    setIsLoading3(true);
    try {
      const DistributionResponse: any = await POST(
        API.CATEGORY_DISTRIBUTION,
        filterValues
      );
      setDistributionData(DistributionResponse);
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setIsLoading3(false);
    }
  };
  const salesKPIS = async (filterValues: any = {}) => {
    setIsLoading4(true);
    try {
      const salesKPIS: any = await POST(API.SALES_KPIS, filterValues);
      setSalesKPISData(salesKPIS?.rows);
      setSalesKPISMeta(metaObj(salesKPIS));
      setPagesalesKPIS(salesKPIS?.currentPage);
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setIsLoading4(false);
    }
  };
  const handleTopProductSort = (column: string) => {
    if (topProductSortField === column) {
      setTopProductSortOrder(topProductSortOrder === "ASC" ? "DESC" : "ASC");
    } else {
      setTopProductSortField(column);
      setTopProductSortOrder("ASC");
    }
  };
  const handlePeriodSort = (column: string) => {
    if (periodSortField === column) {
      setPeriodSortOrder(periodSortOrder === "ASC" ? "DESC" : "ASC");
    } else {
      setPeriodSortField(column);
      setPeriodSortOrder("ASC");
    }
  };
  const handleSalesSort = (column: string) => {
    if (SalesSortField === column) {
      setSalesSortOrder(SalesSortOrder === "ASC" ? "DESC" : "ASC");
    } else {
      setSalesSortField(column);
      setSalesSortOrder("ASC");
    }
  };
  const columns1 = [
    { key: "NAME_EN", label: "Name EN" },
    { key: "INVOICED_QUANTITY", label: "Invoiced Quantity" },
    { key: "INVOICED_NSV", label: "Invoiced NSV" },
    { key: "INVOICED_QUANTITY_LY", label: "Invoiced Quantity LY" },
    { key: "INVOICED_NSV_LY", label: "Invoiced NSV LY" },
  ];
  const columns2 = [
    { key: "CATEGORY", label: "Category" },
    { key: "P01", label: "P01" },
    { key: "P02", label: "P02" },
    { key: "P03", label: "P03" },
    { key: "P04", label: "P04" },
    { key: "P05", label: "P05" },
    { key: "P06", label: "P06" },
    { key: "P07", label: "P07" },
    { key: "P08", label: "P08" },
    { key: "P09", label: "P09" },
  ];
  const columns3 = [
    { key: "CATEGORY", label: "category" },
    { key: "RANGE", label: "Range" },
    { key: "NAME_EN", label: "Name" },
    { key: "INVOICED_NSV", label: "Invoiced NSV" },
    { key: "INVOICED_NSV_LY", label: "Invoiced NSV LY" },
    { key: "NSV_VS_LY_%", label: "Invoiced NSV vs LY %" },
    { key: "INVOICED_QUANTITY", label: "Invoiced Quantity" },
    { key: "INVOICED_QUANTITY_LY", label: "Invoiced Quantity LY" },
    { key: "QUANTITY_VS_LY_%", label: "Invoiced Quantity vs LY %" },
    {
      key: "ORDER_COUNT",
      label: "Count of Orders having the product category",
    },
  ];
  const formatCurrency = (value: any) => {
    if (isNaN(value)) return "-";
    return new Intl.NumberFormat("en-EU", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const pieData = distributionData?.map((item: any) => {
    return {
      name: item.CATEGORY,
      value: item.ORDER_COUNT,
      percentage: parseFloat(item.PERCENTAGE),
    };
  });
  const COLORS = ["#1E88E5", "#FF5722", "#673AB7", "#2E7D32"];

  const getTotal = (data: any[], field: string): number => {
    return data?.reduce((total: number, item: any) => {
      const value = parseFloat(item[field]);
      return total + (isNaN(value) ? 0 : value);
    }, 0);
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-12 gap-6">
      <div className="sm:col-span-12 md:col-span-5">
        <div className="bg-white rounded-lg shadow mt-5">
          <div className="p-4 border-b border-gray-200">
            <h2 className="text-lg font-semibold text-[#6b2228]">
              Top Products{" "}
            </h2>
          </div>
          <div className="overflow-x-auto">
            {isLoading1 ? (
              <LoadingBox />
            ) : (
              <table className="w-full">
                <thead className="bg-gray-50">
                  <tr>
                    {columns1.map(({ key, label }) => (
                      <th
                        key={key}
                        onClick={() => handleTopProductSort(key)}
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                      >
                        <div className="flex items-center space-x-1">
                          <span>{label}</span>
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {topProductsData?.map((row: any, index: any) => {
                    return (
                      <tr key={index} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.NAME_EN}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.INVOICED_QUANTITY}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.INVOICED_NSV)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.INVOICED_QUANTITY_LY)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.INVOICED_NSV_LY)}
                        </td>
                      </tr>
                    );
                  })}
                  <tr className="bg-blue-600">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white">
                      Total
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {getTotal(topProductsData, "INVOICED_QUANTITY")}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {formatCurrency(
                        getTotal(topProductsData, "INVOICED_NSV")
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {getTotal(topProductsData, "INVOICED_QUANTITY_LY")}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {formatCurrency(
                        getTotal(topProductsData, "INVOICED_NSV_LY")
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
          <Pagination
            currentPage={Number(topProductsMeta?.currentPage) || 0}
            totalPages={Number(topProductsMeta?.totalPages) || 0}
            totalRows={Number(topProductsMeta?.count) || 0}
            setCurrentPage={(val: any) => {
              setPagetopProducts(val);
            }}
            itemsPerPage={Number(10) || 0}
          />
        </div>
      </div>
      <div className="sm:col-span-12 md:col-span-7">
        <div className="bg-white rounded-lg shadow mt-5">
          <div className="p-4 border-b border-gray-200">
            <h2 className="text-lg font-semibold text-[#6b2228]">
              Periodic NSV{" "}
            </h2>
          </div>
          <div className="overflow-x-auto">
            {isLoading2 ? (
              <div>
                <LoadingBox />
              </div>
            ) : (
              <table className="w-full">
                <thead className="bg-gray-50">
                  <tr>
                    {columns2.map(({ key, label }) => (
                      <th
                        key={key}
                        onClick={() => handlePeriodSort(key)}
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                      >
                        <div className="flex items-center space-x-1">
                          <span>{label}</span>
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {periodicNsvData?.map((row: any, index: any) => (
                    <tr key={index} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {row.CATEGORY}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.P01)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.P02)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.P03)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.P04)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.P05)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.P06)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.P07)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.P08)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.P09)}
                      </td>
                    </tr>
                  ))}
                  <tr className="bg-blue-600">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white">
                      Total
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {formatCurrency(getTotal(periodicNsvData, "P01"))}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {formatCurrency(getTotal(periodicNsvData, "P02"))}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {formatCurrency(getTotal(periodicNsvData, "P03"))}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {formatCurrency(getTotal(periodicNsvData, "P04"))}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {formatCurrency(getTotal(periodicNsvData, "P05"))}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {formatCurrency(getTotal(periodicNsvData, "P06"))}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {formatCurrency(getTotal(periodicNsvData, "P07"))}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {formatCurrency(getTotal(periodicNsvData, "P08"))}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {formatCurrency(getTotal(periodicNsvData, "P09"))}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
          {/* <Pagination
            currentPage={Number(periodicNsvMeta?.currentPage) || 0}
            totalPages={Number(periodicNsvMeta?.totalPages) || 0}
            totalRows={Number(periodicNsvMeta?.count) || 0}
            setCurrentPage={setPageperiodicNsv}
            itemsPerPage={Number(10) || 0}
          /> */}
        </div>
      </div>
      <div className="sm:col-span-12 md:col-span-5">
        <div className="bg-white rounded-lg shadow mt-5">
          <div className="p-4 border-b border-gray-200">
            <h2 className="text-lg font-semibold text-[#6b2228]">
              Category Distribution
            </h2>
          </div>
          {isLoading3 ? (
            <LoadingBox />
          ) : (
            <>
              <div className="w-full h-[500px]">
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={pieData}
                      cx="50%"
                      cy="50%"
                      outerRadius={isMobile ? 50 : 100}
                      dataKey="value"
                      label={({ name, percentage }) =>
                        `${name} (${percentage?.toFixed(2)}%)`
                      }
                    >
                      {pieData?.map((entry: any, index: any) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="sm:col-span-12 md:col-span-7">
        <div className="bg-white rounded-lg shadow mt-5">
          <div className="p-4 border-b border-gray-200">
            <h2 className="text-lg font-semibold text-[#6b2228]">
              Sales KPIs{" "}
            </h2>
          </div>
          {isLoading4 ? (
            <LoadingBox />
          ) : (
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead className="bg-gray-50">
                  <tr>
                    {columns3.map(({ key, label }) => (
                      <th
                        key={key}
                        onClick={() => handleSalesSort(key)}
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                      >
                        <div className="flex items-center space-x-1">
                          <span>{label}</span>
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {salesKPISData?.map((row: any, index: any) => (
                    <tr key={index} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {row.CATEGORY}
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {row.RANGE}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {row.NAME_EN}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.INVOICED_NSV)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.INVOICED_NSV_LY)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {row.NSV_VS_LY_}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.INVOICED_QUANTITY)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.INVOICED_QUANTITY_LY)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {row.QUANTITY_VS_LY_}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                        {formatCurrency(row.ORDER_COUNT)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          <Pagination
            currentPage={Number(salesKPISMeta?.currentPage) || 0}
            totalPages={Number(salesKPISMeta?.totalPages) || 0}
            totalRows={Number(salesKPISMeta?.count) || 0}
            setCurrentPage={setPagesalesKPIS}
            itemsPerPage={Number(10) || 0}
          />
        </div>
        <div className="bg-blue-50 p-4 mt-3 text-sm text-gray-600 italic text-center mt-5">
          <p>
            Sales KPI : the report shows the products that were ordered and
            their related order numbers count.One order can have products from
            different categories.In those cases,the count(numbers of
            order)increases for each category
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProductSplit;
