import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  ArrowLeft,
  LayoutDashboard,
  Package,
  Calendar,
  ShoppingBag,
  Building,
  Globe,
  Beaker,
  FileText,
  Weight,
  Filter,
  ListFilter,
} from "lucide-react";
import Navbar from "../../components/navigation/navbar";
import { GET } from "../../utils/apiCalls";
import API from "../../config/api";

const Shipments = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <div className="flex items-center justify-between flex-wrap border-b border-gray-200 px-6 py-4 bg-white">
        <div className="flex items-center gap-3">
          <ArrowLeft
            className="h-5 w-5 cursor-pointer"
            onClick={() => navigate("/oms-dashboard")}
          />
          <div className="flex items-center gap-2">
            <LayoutDashboard />
            <h1 className="text-xl font-medium">
              Shipments & Tracking: (Post-POC Phase)
              <span className="font-bold">
                {/* {loading ? "" : product?.NAME_EN} */}
              </span>
            </h1>
          </div>
        </div>
        <div className="flex gap-2">
          <button className="px-4 py-1.5 text-gray-700 border border-gray-300 rounded hover:bg-gray-50">
            Edit
          </button>
          <button className="px-4 py-1.5 text-white bg-blue-600 rounded hover:bg-blue-700">
            Save
          </button>
        </div>
      </div>
      {loading ? (
        <div className="flex justify-center items-center min-h-screen bg-gray-50">
          <div className="animate-pulse">
            <div className="w-16 h-16 border-4 border-gray-600 border-t-transparent rounded-full animate-spin"></div>
          </div>
        </div>
      ) : (
        <div className="p-6">
          <div className="grid grid-cols-1 md:grid-cols-12 gap-6 items-stretch">
            <div className="col-span-12 md:col-span-4 flex flex-col">
              <div className="bg-white rounded-lg shadow-md p-4 h-full">
                <div className="bg-gray-100 flex items-center justify-between mb-1 p-3 cursor-pointer">
                  <div className="flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="lucide lucide-list-filter h-5 w-5 text-blue-500"
                    >
                      <path d="M3 6h18"></path>

                      <path d="M7 12h10"></path>

                      <path d="M10 18h4"></path>
                    </svg>

                    <span className="font-bold text-blue-500">
                      Shipment Summary
                    </span>
                  </div>
                </div>

                <div className="text-sm text-gray-600 mt-1">
                  <div className="divide-y divide-gray-100">
                    <div className="flex items-start gap-3 p-2 bg-blue-50 rounded-lg">
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">Shipment ID</div>

                        <div className="mt-1 font-medium text-blue-600 break-words">
                          SH-202402-001
                        </div>
                      </div>
                    </div>

                    <div className="flex items-start gap-3 p-2">
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">Carrier</div>

                        <div className="mt-1 font-medium text-gray-900 break-words">
                          DHL
                        </div>
                      </div>
                    </div>

                    <div className="flex items-start gap-3 p-2">
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">
                          Tracking Number
                        </div>

                        <div className="mt-1 font-medium text-gray-900 break-words">
                          <a href="#" className="text-blue-600 underline">
                            123456789
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="flex items-start gap-3 p-2">
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">
                          Shipment Date
                        </div>

                        <div className="mt-1 font-medium text-gray-900 break-words">
                          Feb 12, 2025
                        </div>
                      </div>
                    </div>

                    <div className="flex items-start gap-3 p-2">
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">
                          Estimated Delivery
                        </div>

                        <div className="mt-1 font-medium text-gray-900 break-words">
                          Feb 15, 2025
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-span-12 md:col-span-8">
              <div className="bg-white rounded-lg shadow-md p-4 mb-6">
                <div className="bg-gray-100 flex items-center justify-between mb-1 p-3 cursor-pointer">
                  <div className="flex items-center gap-2">
                    <span className="font-bold text-blue-500">
                      Tracking Timeline
                    </span>
                  </div>
                </div>

                <div className="text-sm text-gray-600 mt-1">
                  <div className="divide-y divide-gray-100">
                    <div className="flex items-start gap-3 p-2 bg-blue-50 rounded-lg">
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">
                          Shipment Created
                        </div>

                        <div className="mt-1 font-medium text-blue-600 break-words">
                          Feb 12, 2025 - 08:00 AM
                        </div>
                      </div>
                    </div>

                    <div className="flex items-start gap-3 p-2">
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">
                          Picked Up by Carrier
                        </div>

                        <div className="mt-1 font-medium text-gray-900 break-words">
                          Feb 12, 2025 - 12:00 PM
                        </div>
                      </div>
                    </div>

                    <div className="flex items-start gap-3 p-2">
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">In Transit</div>

                        <div className="mt-1 font-medium text-gray-900 break-words">
                          Feb 13, 2025 - 06:00 AM
                        </div>
                      </div>
                    </div>

                    <div className="flex items-start gap-3 p-2">
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">
                          Out for Delivery
                        </div>

                        <div className="mt-1 font-medium text-gray-900 break-words">
                          Feb 15, 2025 - 09:00 AM
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-lg shadow-md p-4 mb-6">
                <div className="bg-gray-100 flex items-center justify-between mb-1 p-3 cursor-pointer">
                  <div className="flex items-center gap-2">
                    <span className="font-bold text-blue-500">
                      Shipment Details
                    </span>
                  </div>
                </div>

                <div className="text-sm text-gray-600 mt-1">
                  <div className="divide-y divide-gray-100">
                    <div className="flex items-start gap-3 p-2 bg-blue-50 rounded-lg">
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">
                          Total Weight
                        </div>

                        <div className="mt-1 font-medium text-blue-600 break-words">
                          12kg
                        </div>
                      </div>
                    </div>

                    <div className="flex items-start gap-3 p-2">
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">
                          Package Type
                        </div>

                        <div className="mt-1 font-medium text-gray-900 break-words">
                          Box
                        </div>
                      </div>
                    </div>

                    <div className="flex items-start gap-3 p-2">
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">
                          Temperature Requirement
                        </div>

                        <div className="mt-1 font-medium text-gray-900 break-words">
                          Ambient
                        </div>
                      </div>
                    </div>

                    <div className="flex items-start gap-3 p-2">
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">Incoterms</div>

                        <div className="mt-1 font-medium text-gray-900 break-words">
                          FOB
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-lg shadow-md p-4">
                <div className="bg-gray-100 flex items-center justify-between mb-1 p-3 cursor-pointer">
                  <div className="flex items-center gap-2">
                    <span className="font-bold text-blue-500">
                      Issues & Exceptions
                    </span>
                  </div>
                </div>

                <div className="text-sm text-gray-600 mt-1">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="bg-white p-3 rounded-lg shadow-sm flex items-center justify-between">
                      <dt className="text-xs text-gray-500 uppercase">
                        Delivery Delay
                      </dt>

                      <dd>
                        <span className="px-2 py-1 rounded-full text-xs font-medium bg-red-100 text-red-800">
                          Yes
                        </span>
                      </dd>
                    </div>

                    <div className="bg-white p-3 rounded-lg shadow-sm flex items-center justify-between">
                      <dt className="text-xs text-gray-500 uppercase">
                        Damaged Goods
                      </dt>

                      <dd>
                        <span className="px-2 py-1 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                          No
                        </span>
                      </dd>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Shipments;
