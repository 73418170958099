import React, { useState, useEffect } from "react";
import { ArrowUpDown, Eye, Search, X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

interface Lead {
  id: number;
  Lead_Owner: string;
  Lead_Status: string;
  Salutation: string;
  First_Name: string;
  Middle_Name: string;
  Last_Name: string;
  Suffix: string;
  Phone: string;
  Company: string;
  Mobile: string;
  Title: string;
  Email: string;
  ASI_Number: string;
  Rating: string;
  Customer_Group: string;
  Lead_Currency: string;
  PPAI_Number: string;
  VAT_Number: string;
  EDD: string;
  Product_Interest: string;
  Customization_Details: string;
}

interface LeadsResponse {
  data: Lead[];
  totalRecords: number;
  filteredRecords: number;
  totalPages: number;
  currentPage: number;
}

interface LeadsTableProps {
  totalRecords: (val: number) => void;
  filteredRecords: (val: number) => void;
}

const dummyData: Lead[] = [
  {
    id: 1,
    Lead_Owner: "Wissam Messarra",
    Lead_Status: "New",
    Salutation: "Mr.",
    First_Name: "John",
    Middle_Name: "A.",
    Last_Name: "Smith",
    Suffix: "Jr.",
    Phone: "123-456-7890",
    Company: "Company A",
    Mobile: "111-222-3333",
    Title: "Manager",
    Email: "john.smith@example.com",
    ASI_Number: "ASI001",
    Rating: "High",
    Customer_Group: "Group A",
    Lead_Currency: "USD",
    PPAI_Number: "PPAI001",
    VAT_Number: "VAT001",
    EDD: "2025-03-01",
    Product_Interest: "Product A",
    Customization_Details: "Custom A",
  },
  {
    id: 2,
    Lead_Owner: "Wissam Messarra",
    Lead_Status: "New",
    Salutation: "Ms.",
    First_Name: "Alice",
    Middle_Name: "B.",
    Last_Name: "Johnson",
    Suffix: "",
    Phone: "987-654-3210",
    Company: "Company B",
    Mobile: "444-555-6666",
    Title: "Director",
    Email: "alice.johnson@example.com",
    ASI_Number: "ASI002",
    Rating: "Medium",
    Customer_Group: "Group B",
    Lead_Currency: "EUR",
    PPAI_Number: "PPAI002",
    VAT_Number: "VAT002",
    EDD: "2025-03-05",
    Product_Interest: "Product B",
    Customization_Details: "Custom B",
  },
  {
    id: 3,
    Lead_Owner: "Wissam Messarra",
    Lead_Status: "New",
    Salutation: "Dr.",
    First_Name: "Sam",
    Middle_Name: "C.",
    Last_Name: "Williams",
    Suffix: "Sr.",
    Phone: "456-789-1234",
    Company: "Company C",
    Mobile: "777-888-9999",
    Title: "Engineer",
    Email: "sam.williams@example.com",
    ASI_Number: "ASI003",
    Rating: "High",
    Customer_Group: "Group C",
    Lead_Currency: "GBP",
    PPAI_Number: "PPAI003",
    VAT_Number: "VAT003",
    EDD: "2025-03-10",
    Product_Interest: "Product C",
    Customization_Details: "Custom C",
  },
  {
    id: 4,
    Lead_Owner: "Wissam Messarra",
    Lead_Status: "New",
    Salutation: "Mrs.",
    First_Name: "Emily",
    Middle_Name: "D.",
    Last_Name: "Brown",
    Suffix: "",
    Phone: "789-123-4567",
    Company: "Company D",
    Mobile: "000-111-2222",
    Title: "Analyst",
    Email: "emily.brown@example.com",
    ASI_Number: "ASI004",
    Rating: "Low",
    Customer_Group: "Group D",
    Lead_Currency: "AUD",
    PPAI_Number: "PPAI004",
    VAT_Number: "VAT004",
    EDD: "2025-03-15",
    Product_Interest: "Product D",
    Customization_Details: "Custom D",
  },
  {
    id: 5,
    Lead_Owner: "Wissam Messarra",
    Lead_Status: "New",
    Salutation: "Prof.",
    First_Name: "David",
    Middle_Name: "E.",
    Last_Name: "Taylor",
    Suffix: "",
    Phone: "321-654-9870",
    Company: "Company E",
    Mobile: "333-444-5555",
    Title: "Consultant",
    Email: "david.taylor@example.com",
    ASI_Number: "ASI005",
    Rating: "Medium",
    Customer_Group: "Group E",
    Lead_Currency: "CAD",
    PPAI_Number: "PPAI005",
    VAT_Number: "VAT005",
    EDD: "2025-03-20",
    Product_Interest: "Product E",
    Customization_Details: "Custom E",
  },
];

const LeadsTable: React.FC<LeadsTableProps> = ({
  totalRecords,
  filteredRecords,
}) => {
  const [leads, setLeads] = useState<Lead[]>(dummyData);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sortField, setSortField] = useState("created_at");
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC">("DESC");
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecordsCount, setTotalRecordsCount] = useState(dummyData.length);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchLeads = async () => {
      setIsLoading(true);
      try {
        // Simulate API call with dummy data
        let filteredData = dummyData;

        // Apply search filter
        if (searchTerm) {
          filteredData = filteredData.filter((lead) =>
            Object.values(lead).some((value) =>
              value.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
          );
        }

        const data: LeadsResponse = {
          data: filteredData,
          totalRecords: dummyData.length,
          filteredRecords: filteredData.length,
          totalPages: Math.ceil(filteredData.length / itemsPerPage),
          currentPage: currentPage,
        };

        setLeads(data.data);
        setTotalPages(data.totalPages);
        setTotalRecordsCount(data.totalRecords);
        totalRecords(data.totalRecords);
        filteredRecords(data.filteredRecords);
      } catch (error) {
        console.error("Error fetching leads:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLeads();
  }, [searchTerm, currentPage, itemsPerPage, sortField, sortOrder]);

  const handleSort = (column: string) => {
    if (sortField === column) {
      setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC");
    } else {
      setSortField(column);
      setSortOrder("ASC");
    }
  };

  const handleView = (LeadId: string) => {
    navigate(`/ccm-lead/${LeadId}`);
  };

  const clearSearch = () => {
    setSearchTerm("");
    setCurrentPage(1);
  };

  const handleEntriesChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const renderPageNumbers = () => {
    const pages = [];
    const showAround = 1;

    pages.push(1);

    if (currentPage > 2 + showAround) {
      pages.push("...");
    }

    for (
      let i = Math.max(2, currentPage - showAround);
      i <= Math.min(totalPages - 1, currentPage + showAround);
      i++
    ) {
      pages.push(i);
    }

    if (currentPage < totalPages - (1 + showAround)) {
      pages.push("...");
    }

    if (totalPages > 1) {
      pages.push(totalPages);
    }

    return (
      <div className="flex items-center space-x-1">
        {pages.map((page, index) => (
          <React.Fragment key={index}>
            {page === "..." ? (
              <span className="px-2 py-1 text-gray-500">...</span>
            ) : (
              <button
                onClick={() => setCurrentPage(page as number)}
                className={`min-w-[32px] px-2 py-1 rounded text-sm ${
                  currentPage === page
                    ? "bg-gray-600 text-white"
                    : "text-gray-600 hover:bg-gray-100"
                }`}
              >
                {page}
              </button>
            )}
          </React.Fragment>
        ))}
      </div>
    );
  };

  return (
    <div className="p-4 md:p-6 lg:col-span-10">
      <div className="bg-white rounded-lg border border-gray-200 shadow-sm">
        {/* Table Header */}
        <div className="p-4 border-b border-gray-200">
          <h2 className="text-xl font-semibold">Leads</h2>
          <div className="mt-4 flex flex-col sm:flex-row justify-between items-center gap-4">
            <div className="flex items-center gap-2">
              <span className="text-sm text-gray-600">Show</span>
              <select
                value={itemsPerPage}
                onChange={handleEntriesChange}
                className="px-2 py-1 border border-gray-300 rounded-md text-sm"
              >
                {[10, 25, 50, 100].map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <span className="text-sm text-gray-600">entries</span>
            </div>
            <div className="relative w-full sm:w-auto">
              <Search className="h-5 w-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                placeholder="Search Leads...."
                className="w-full sm:w-64 pl-10 pr-10 py-2 border border-gray-300 rounded-lg"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  setCurrentPage(1);
                }}
              />
              {searchTerm && (
                <button
                  onClick={clearSearch}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
                >
                  <X className="h-5 w-5" />
                </button>
              )}
            </div>
          </div>
        </div>

        {/* Table Content */}
        <div className="overflow-x-auto">
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            <table className="w-full">
              <thead className="bg-gray-50">
                <tr>
                  {[
                    { key: "Lead_Owner", label: "Lead Owner" },
                    { key: "Lead_Status", label: "Lead Status" },
                    { key: "Salutation", label: "Salutation" },
                    { key: "First_Name", label: "First Name" },
                    { key: "Middle_Name", label: "Middle Name" },
                    { key: "Last_Name", label: "Last Name" },
                    { key: "Suffix", label: "Suffix" },
                    { key: "Phone", label: "Phone" },
                    { key: "Company", label: "Company" },
                    { key: "Mobile", label: "Mobile" },
                    { key: "Title", label: "Title" },
                    { key: "Email", label: "Email" },
                    { key: "ASI_Number", label: "ASI Number" },
                    { key: "Rating", label: "Rating" },
                    { key: "Customer_Group", label: "Customer Group" },
                    { key: "Lead_Currency", label: "Lead Currency" },
                    { key: "PPAI_Number", label: "PPAI Number" },
                    { key: "VAT_Number", label: "VAT Number" },
                    { key: "EDD", label: "EDD" },
                    { key: "Product_Interest", label: "Product Interest" },
                    {
                      key: "Customization_Details",
                      label: "Customization Details",
                    },
                  ].map(({ key, label }) => (
                    <th
                      key={key}
                      onClick={() => handleSort(key)}
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                    >
                      <div className="flex items-center space-x-1">
                        <span>{label}</span>
                        <ArrowUpDown className="h-4 w-4" />
                      </div>
                    </th>
                  ))}
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {leads?.map((lead) => (
                  <tr key={lead.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-blue-600 font-medium">
                      {lead.Lead_Owner}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {lead.Lead_Status}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {lead.Salutation}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {lead.First_Name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {lead.Middle_Name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {lead.Last_Name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {lead.Suffix}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {lead.Phone}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {lead.Company}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {lead.Mobile}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {lead.Title}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {lead.Email}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {lead.ASI_Number}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {lead.Rating}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {lead.Customer_Group}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {lead.Lead_Currency}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {lead.PPAI_Number}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {lead.VAT_Number}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {moment(lead.EDD).format("YYYY-MM-DD")}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {lead.Product_Interest}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {lead.Customization_Details}
                    </td>
                    <td>
                      <div className="flex items-center gap-3">
                        <button
                          onClick={() => handleView(lead.id.toString())}
                          className="text-blue-600 hover:text-blue-800"
                        >
                          <Eye className="h-5 w-5" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        {/* Pagination */}
        <div className="px-6 py-4 border-t border-gray-200">
          <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
            <div className="text-sm text-gray-500">
              Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
              {Math.min(currentPage * itemsPerPage, totalRecordsCount)} of{" "}
              {totalRecordsCount} entries
            </div>
            <div className="flex items-center space-x-2">
              <button
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
                className="px-3 py-1 text-sm disabled:opacity-50"
              >
                Previous
              </button>
              {renderPageNumbers()}
              <button
                onClick={() =>
                  setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                }
                disabled={currentPage === totalPages}
                className="px-3 py-1 text-sm disabled:opacity-50"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadsTable;
