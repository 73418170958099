import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  ArrowLeft,
  LayoutDashboard,
  Package,
  Calendar,
  ShoppingBag,
  Building,
  Globe,
  Beaker,
  FileText,
  Weight,
  Filter,
  ListFilter,
} from "lucide-react";
import Navbar from "../../components/navigation/navbar";
import { GET } from "../../utils/apiCalls";
import API from "../../config/api";

const InvoicePayment = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <div className="flex items-center justify-between flex-wrap border-b border-gray-200 px-6 py-4 bg-white">
        <div className="flex items-center gap-3">
          <ArrowLeft
            className="h-5 w-5 cursor-pointer"
            onClick={() => navigate("/oms-dashboard")}
          />
          <div className="flex items-center gap-2">
            <LayoutDashboard />
            <h1 className="text-xl font-medium">
              Invoice & Payment Processing: (Post-POC Phase)
              <span className="font-bold">
                {/* {loading ? "" : product?.NAME_EN} */}
              </span>
            </h1>
          </div>
        </div>
        <div className="flex gap-2">
          <button className="px-4 py-1.5 text-gray-700 border border-gray-300 rounded hover:bg-gray-50">
            Edit
          </button>
          <button className="px-4 py-1.5 text-white bg-blue-600 rounded hover:bg-blue-700">
            Save
          </button>
        </div>
      </div>
      {loading ? (
        <div className="flex justify-center items-center min-h-screen bg-gray-50">
          <div className="animate-pulse">
            <div className="w-16 h-16 border-4 border-gray-600 border-t-transparent rounded-full animate-spin"></div>
          </div>
        </div>
      ) : (
        <div className="p-6">
          <div className="grid grid-cols-1 md:grid-cols-12 gap-6 items-stretch">
            <div className="col-span-12 md:col-span-4 flex flex-col">
              <div className="bg-white rounded-lg shadow-md p-4 h-full">
                <div className="bg-gray-100 flex items-center justify-between mb-1 p-3 cursor-pointer">
                  <div className="flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="lucide lucide-file-text h-5 w-5 text-blue-500"
                    >
                      <path d="M15 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7Z"></path>

                      <path d="M14 2v4a2 2 0 0 0 2 2h4"></path>

                      <path d="M10 9H8"></path>

                      <path d="M16 13H8"></path>

                      <path d="M16 17H8"></path>
                    </svg>

                    <span className="font-bold text-blue-500">
                      Invoice Summary
                    </span>
                  </div>
                </div>

                <div className="text-sm text-gray-600 mt-1">
                  <div className="divide-y divide-gray-100">
                    <div className="flex items-start gap-3 p-2 bg-blue-50 rounded-lg">
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">Invoice ID</div>

                        <div className="mt-1 font-medium text-blue-600 break-words">
                          INV-202402-210
                        </div>
                      </div>
                    </div>

                    <div className="flex items-start gap-3 p-2">
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">Vendor</div>

                        <div className="mt-1 font-medium text-gray-900 break-words">
                          XYZ Supplies Ltd.
                        </div>
                      </div>
                    </div>

                    <div className="flex items-start gap-3 p-2">
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">
                          Invoice Date
                        </div>

                        <div className="mt-1 font-medium text-gray-900 break-words">
                          Feb 15, 2025
                        </div>
                      </div>
                    </div>

                    <div className="flex items-start gap-3 p-2">
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">Due Date</div>

                        <div className="mt-1 font-medium text-gray-900 break-words">
                          Mar 15, 2025
                        </div>
                      </div>
                    </div>

                    <div className="flex items-start gap-3 p-2">
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">Status</div>

                        <div className="mt-1 font-medium text-yellow-600 break-words">
                          Pending Approval
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-span-12 md:col-span-8">
              <div className="bg-white rounded-lg shadow-md p-4 mb-6">
                <div className="bg-gray-100 flex items-center justify-between mb-1 p-3 cursor-pointer">
                  <div className="flex items-center gap-2">
                    <span className="font-bold text-blue-500">
                      Payment Processing
                    </span>
                  </div>
                </div>

                <div className="text-sm text-gray-600 mt-1">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="bg-white p-3 rounded-lg shadow-sm">
                      <dt className="text-xs text-gray-500 uppercase">
                        Payment Method
                      </dt>

                      <dd className="text-sm font-medium text-gray-800">
                        Bank Transfer
                      </dd>
                    </div>

                    <div className="bg-white p-3 rounded-lg shadow-sm">
                      <dt className="text-xs text-gray-500 uppercase">
                        Scheduled Date
                      </dt>

                      <dd className="text-sm font-medium text-gray-800">
                        Mar 12, 2025
                      </dd>
                    </div>

                    <div className="bg-white p-3 rounded-lg shadow-sm">
                      <dt className="text-xs text-gray-500 uppercase">
                        Processing Status
                      </dt>

                      <dd>
                        <span className="px-2 py-1 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                          Processing
                        </span>
                      </dd>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-lg shadow-md p-4 mb-6">
                <div className="bg-gray-100 flex items-center justify-between mb-1 p-3 cursor-pointer">
                  <div className="flex items-center gap-2">
                    <span className="font-bold text-blue-500">
                      Approval Workflow
                    </span>
                  </div>
                </div>

                <div className="text-sm text-gray-600 mt-1">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="bg-white p-3 rounded-lg shadow-sm">
                      <dt className="text-xs text-gray-500 uppercase">
                        Manager Approval
                      </dt>

                      <dd>
                        <span className="px-2 py-1 rounded-full text-xs font-medium bg-green-100 text-green-800">
                          Approved
                        </span>
                      </dd>
                    </div>

                    <div className="bg-white p-3 rounded-lg shadow-sm">
                      <dt className="text-xs text-gray-500 uppercase">
                        Finance Review
                      </dt>

                      <dd>
                        <span className="px-2 py-1 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                          Pending
                        </span>
                      </dd>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-lg shadow-md p-4">
                <div className="bg-gray-100 flex items-center justify-between mb-1 p-3 cursor-pointer">
                  <div className="flex items-center gap-2">
                    <span className="font-bold text-blue-500">
                      Financial Breakdown
                    </span>
                  </div>
                </div>

                <div className="text-sm text-gray-600 mt-1">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="bg-white p-3 rounded-lg shadow-sm">
                      <dt className="text-xs text-gray-500 uppercase">
                        Invoice Amount
                      </dt>

                      <dd className="text-sm font-medium text-gray-800">
                        $15,400
                      </dd>
                    </div>

                    <div className="bg-white p-3 rounded-lg shadow-sm">
                      <dt className="text-xs text-gray-500 uppercase">Tax</dt>

                      <dd className="text-sm font-medium text-gray-800">
                        $1,200
                      </dd>
                    </div>

                    <div className="bg-white p-3 rounded-lg shadow-sm">
                      <dt className="text-xs text-gray-500 uppercase">
                        Discount
                      </dt>

                      <dd className="text-sm font-medium text-gray-800">2%</dd>
                    </div>

                    <div className="bg-white p-3 rounded-lg shadow-sm">
                      <dt className="text-xs text-gray-500 uppercase">
                        Total Payable
                      </dt>

                      <dd className="text-sm font-medium text-blue-800">
                        $15,092
                      </dd>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InvoicePayment;
