import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ArrowLeft, LayoutDashboard, ListFilter } from "lucide-react";
import Navbar from "../../components/navigation/navbar";
import { GET } from "../../utils/apiCalls";
import API from "../../config/api";
import NavbarCCM from "../../components/navigation/ccmnavbar";

const dummyData = [
  {
    id: 1,
    Case_Reason_Name: "Reason E",
    Owner: "Wissam Messarra",
    Intent: "Intent E",
    Intent_Code: "Code E",
    Area: "Area E",
    Area_Code: "A005",
    Specifics: "Specific E",
    Specifics_Code: "S005",
    Issue: "Issue E",
    Issue_Code: "I005",
    Case_Reason: "CR E",
    Case_Reason_Code: "CRC005",
    Case_Reason_Long_Description: "Description for Reason E",
    Do_Not_Send_Survey: "1",
    Vital_Reason_Code: "VR005",
    Product_Segment: "Segment E",
    Vital_Case_Reason: "Vital Reason E",
    Responsible_Area: "Resp E",
    Responsible_Area_Code: "RA005",
    Severity: "Low",
    Severity_Code: "SC005",
    Business_Segment: "Business E",
    Business_Segment_Code: "BS005",
    Reason_Search_String_Czech: "Czech E",
    Reason_Search_String_Italian: "Italian E",
    Reason_Search_String_English: "English E",
    Reason_Search_String_Polish: "Polish E",
    Reason_Search_String_French: "French E",
    Reason_Search_String_Portuguese: "Portuguese E",
    Reason_Search_String_German: "German E",
    Reason_Search_String_Spanish: "Spanish E",
  },
  {
    id: 2,
    Case_Reason_Name: "Reason D",
    Owner: "Wissam Messarra",
    Intent: "Intent D",
    Intent_Code: "Code D",
    Area: "Area D",
    Area_Code: "A004",
    Specifics: "Specific D",
    Specifics_Code: "S004",
    Issue: "Issue D",
    Issue_Code: "I004",
    Case_Reason: "CR D",
    Case_Reason_Code: "CRC004",
    Case_Reason_Long_Description: "Description for Reason D",
    Do_Not_Send_Survey: "0",
    Vital_Reason_Code: "VR004",
    Product_Segment: "Segment D",
    Vital_Case_Reason: "Vital Reason D",
    Responsible_Area: "Resp D",
    Responsible_Area_Code: "RA004",
    Severity: "Critical",
    Severity_Code: "SC004",
    Business_Segment: "Business D",
    Business_Segment_Code: "BS004",
    Reason_Search_String_Czech: "Czech D",
    Reason_Search_String_Italian: "Italian D",
    Reason_Search_String_English: "English D",
    Reason_Search_String_Polish: "Polish D",
    Reason_Search_String_French: "French D",
    Reason_Search_String_Portuguese: "Portuguese D",
    Reason_Search_String_German: "German D",
    Reason_Search_String_Spanish: "Spanish D",
  },
  {
    id: 3,
    Case_Reason_Name: "Reason C",
    Owner: "Wissam Messarra",
    Intent: "Intent C",
    Intent_Code: "Code C",
    Area: "Area C",
    Area_Code: "A003",
    Specifics: "Specific C",
    Specifics_Code: "S003",
    Issue: "Issue C",
    Issue_Code: "I003",
    Case_Reason: "CR C",
    Case_Reason_Code: "CRC003",
    Case_Reason_Long_Description: "Description for Reason C",
    Do_Not_Send_Survey: "1",
    Vital_Reason_Code: "VR003",
    Product_Segment: "Segment C",
    Vital_Case_Reason: "Vital Reason C",
    Responsible_Area: "Resp C",
    Responsible_Area_Code: "RA003",
    Severity: "High",
    Severity_Code: "SC003",
    Business_Segment: "Business C",
    Business_Segment_Code: "BS003",
    Reason_Search_String_Czech: "Czech C",
    Reason_Search_String_Italian: "Italian C",
    Reason_Search_String_English: "English C",
    Reason_Search_String_Polish: "Polish C",
    Reason_Search_String_French: "French C",
    Reason_Search_String_Portuguese: "Portuguese C",
    Reason_Search_String_German: "German C",
    Reason_Search_String_Spanish: "Spanish C",
  },
  {
    id: 4,
    Case_Reason_Name: "Reason B",
    Owner: "Wissam Messarra",
    Intent: "Intent B",
    Intent_Code: "Code B",
    Area: "Area B",
    Area_Code: "A002",
    Specifics: "Specific B",
    Specifics_Code: "S002",
    Issue: "Issue B",
    Issue_Code: "I002",
    Case_Reason: "CR B",
    Case_Reason_Code: "CRC002",
    Case_Reason_Long_Description: "Description for Reason B",
    Do_Not_Send_Survey: "0",
    Vital_Reason_Code: "VR002",
    Product_Segment: "Segment B",
    Vital_Case_Reason: "Vital Reason B",
    Responsible_Area: "Resp B",
    Responsible_Area_Code: "RA002",
    Severity: "Medium",
    Severity_Code: "SC002",
    Business_Segment: "Business B",
    Business_Segment_Code: "BS002",
    Reason_Search_String_Czech: "Czech B",
    Reason_Search_String_Italian: "Italian B",
    Reason_Search_String_English: "English B",
    Reason_Search_String_Polish: "Polish B",
    Reason_Search_String_French: "French B",
    Reason_Search_String_Portuguese: "Portuguese B",
    Reason_Search_String_German: "German B",
    Reason_Search_String_Spanish: "Spanish B",
  },
  {
    id: 5,
    Case_Reason_Name: "Reason A",
    Owner: "Wissam Messarra",
    Intent: "Intent A",
    Intent_Code: "Code A",
    Area: "Area A",
    Area_Code: "A001",
    Specifics: "Specific A",
    Specifics_Code: "S001",
    Issue: "Issue A",
    Issue_Code: "I001",
    Case_Reason: "CR A",
    Case_Reason_Code: "CRC001",
    Case_Reason_Long_Description: "Description for Reason A",
    Do_Not_Send_Survey: "1",
    Vital_Reason_Code: "VR001",
    Product_Segment: "Segment A",
    Vital_Case_Reason: "Vital Reason A",
    Responsible_Area: "Resp A",
    Responsible_Area_Code: "RA001",
    Severity: "Low",
    Severity_Code: "SC001",
    Business_Segment: "Business A",
    Business_Segment_Code: "BS001",
    Reason_Search_String_Czech: "Czech A",
    Reason_Search_String_Italian: "Italian A",
    Reason_Search_String_English: "English A",
    Reason_Search_String_Polish: "Polish A",
    Reason_Search_String_French: "French A",
    Reason_Search_String_Portuguese: "Portuguese A",
    Reason_Search_String_German: "German A",
    Reason_Search_String_Spanish: "Spanish A",
  },
];

const CaseView = () => {
  const { caseId }: any = useParams();
  const navigate = useNavigate();
  const [caseData, setCaseData] = useState<any>();
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    setLoading(true);
    const caseInfo = dummyData.find(
      (caseItem) => caseItem.id === parseInt(caseId)
    );
    setCaseData(caseInfo);
    setLoading(false);
  }, [caseId]);

  return (
    <div className="min-h-screen bg-gray-50">
      <NavbarCCM />
      <div className="flex items-center justify-between flex-wrap border-b border-gray-200 px-6 py-4 bg-white">
        <div className="flex items-center gap-3">
          <ArrowLeft
            className="h-5 w-5 cursor-pointer"
            onClick={() => navigate("/ccm-cases")}
          />
          <div className="flex items-center gap-2">
            <LayoutDashboard />
            <h1 className="text-xl font-medium">
              Case: {loading ? "Loading..." : caseData?.Case_Reason_Name}
            </h1>
          </div>
        </div>
        <div className="flex gap-2">
          <button className="px-4 py-1.5 text-gray-700 border border-gray-300 rounded hover:bg-gray-50">
            Edit
          </button>
          <button className="px-4 py-1.5 text-white bg-blue-600 rounded hover:bg-blue-700">
            Save
          </button>
        </div>
      </div>
      {loading ? (
        <div className="flex justify-center items-center min-h-screen bg-gray-50">
          <div className="animate-pulse">
            <div className="w-16 h-16 border-4 border-gray-600 border-t-transparent rounded-full animate-spin"></div>
          </div>
        </div>
      ) : (
        <>
          <div className="p-6 bg-gray-50">
            <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
              {/* <!-- Left Column --> */}
              <div className="col-span-12 md:col-span-6 bg-white rounded-lg shadow-md p-6">
                <div className="bg-gray-100 flex items-center justify-between mb-4 p-3 cursor-pointer">
                  <div className="flex items-center gap-2">
                    <ListFilter className="h-5 w-5 text-blue-500" />
                    <span className="font-bold text-blue-500">
                      Case Information
                    </span>
                  </div>
                </div>
                <div className="space-y-4">
                  <div>
                    <label className="text-sm font-medium">
                      Case Reason Name
                    </label>
                    <p className="text-gray-700">
                      {caseData?.Case_Reason_Name}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">Owner</label>
                    <p className="text-gray-700">{caseData?.Owner}</p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">Intent</label>
                    <p className="text-gray-700">{caseData?.Intent}</p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">Intent Code</label>
                    <p className="text-gray-700">{caseData?.Intent_Code}</p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">Area</label>
                    <p className="text-gray-700">{caseData?.Area}</p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">Area Code</label>
                    <p className="text-gray-700">{caseData?.Area_Code}</p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">Specifics</label>
                    <p className="text-gray-700">{caseData?.Specifics}</p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">
                      Specifics Code
                    </label>
                    <p className="text-gray-700">{caseData?.Specifics_Code}</p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">Issue</label>
                    <p className="text-gray-700">{caseData?.Issue}</p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">Issue Code</label>
                    <p className="text-gray-700">{caseData?.Issue_Code}</p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">Case Reason</label>
                    <p className="text-gray-700">{caseData?.Case_Reason}</p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">
                      Case Reason Code
                    </label>
                    <p className="text-gray-700">
                      {caseData?.Case_Reason_Code}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">
                      Case Reason Long Description
                    </label>
                    <p className="text-gray-700">
                      {caseData?.Case_Reason_Long_Description}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">
                      Do Not Send Survey
                    </label>
                    <p className="text-gray-700">
                      {caseData?.Do_Not_Send_Survey}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">
                      Vital Reason Code
                    </label>
                    <p className="text-gray-700">
                      {caseData?.Vital_Reason_Code}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">
                      Product Segment
                    </label>
                    <p className="text-gray-700">{caseData?.Product_Segment}</p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">
                      Vital Case Reason
                    </label>
                    <p className="text-gray-700">
                      {caseData?.Vital_Case_Reason}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">
                      Responsible Area
                    </label>
                    <p className="text-gray-700">
                      {caseData?.Responsible_Area}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">
                      Responsible Area Code
                    </label>
                    <p className="text-gray-700">
                      {caseData?.Responsible_Area_Code}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">Severity</label>
                    <p className="text-gray-700">{caseData?.Severity}</p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">Severity Code</label>
                    <p className="text-gray-700">{caseData?.Severity_Code}</p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">
                      Business Segment
                    </label>
                    <p className="text-gray-700">
                      {caseData?.Business_Segment}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">
                      Business Segment Code
                    </label>
                    <p className="text-gray-700">
                      {caseData?.Business_Segment_Code}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">
                      Reason Search String Czech
                    </label>
                    <p className="text-gray-700">
                      {caseData?.Reason_Search_String_Czech}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">
                      Reason Search String Italian
                    </label>
                    <p className="text-gray-700">
                      {caseData?.Reason_Search_String_Italian}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">
                      Reason Search String English
                    </label>
                    <p className="text-gray-700">
                      {caseData?.Reason_Search_String_English}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">
                      Reason Search String Polish
                    </label>
                    <p className="text-gray-700">
                      {caseData?.Reason_Search_String_Polish}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">
                      Reason Search String French
                    </label>
                    <p className="text-gray-700">
                      {caseData?.Reason_Search_String_French}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">
                      Reason Search String Portuguese
                    </label>
                    <p className="text-gray-700">
                      {caseData?.Reason_Search_String_Portuguese}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">
                      Reason Search String German
                    </label>
                    <p className="text-gray-700">
                      {caseData?.Reason_Search_String_German}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">
                      Reason Search String Spanish
                    </label>
                    <p className="text-gray-700">
                      {caseData?.Reason_Search_String_Spanish}
                    </p>
                  </div>
                </div>
              </div>

              {/* <!-- Right Column --> */}
              <div className="col-span-12 md:col-span-6 bg-white rounded-lg shadow-md p-6">
                {/* <!-- SAP Section --> */}
                <div className="bg-gray-100 flex items-center justify-between mb-4 p-3 cursor-pointer">
                  <div className="flex items-center gap-2">
                    <ListFilter className="h-5 w-5 text-blue-500" />
                    <span className="font-bold text-blue-500">
                      SAP Information
                    </span>
                  </div>
                </div>
                <div className="space-y-4">
                  <div>
                    <label className="text-sm font-medium">SAP Case ID</label>
                    <p className="text-gray-700">{caseData?.SAP_Case_ID}</p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">SAP Cases IDs</label>
                    <p className="text-gray-700">{caseData?.SAP_Cases_IDs}</p>
                  </div>
                </div>
                {/* <!-- Address Section --> */}
                <div className="bg-gray-100 flex items-center justify-between my-6 p-3 cursor-pointer">
                  <div className="flex items-center gap-2">
                    <ListFilter className="h-5 w-5 text-blue-500" />
                    <span className="font-bold text-blue-500">
                      Address Information
                    </span>
                  </div>
                </div>
                <div className="space-y-4">
                  {/* <!-- Mailing Address --> */}
                  <div>
                    <label className="text-sm font-medium">
                      Mailing Address
                    </label>
                    <p className="text-gray-700">{caseData?.Mailing_Address}</p>
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="text-sm font-medium">
                        Mailing Country
                      </label>
                      <p className="text-gray-700">
                        {caseData?.Mailing_Country}
                      </p>
                    </div>
                    <div>
                      <label className="text-sm font-medium">
                        Mailing Street
                      </label>
                      <p className="text-gray-700">
                        {caseData?.Mailing_Street}
                      </p>
                    </div>
                  </div>
                  {/* <!-- Billing Address --> */}
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="text-sm font-medium">
                        Billing Address
                      </label>
                      <p className="text-gray-700">
                        {caseData?.Billing_Address}
                      </p>
                    </div>
                    <div>
                      <label className="text-sm font-medium">
                        Billing Country
                      </label>
                      <p className="text-gray-700">
                        {caseData?.Billing_Country}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CaseView;
