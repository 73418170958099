import { ArrowLeft, LayoutDashboard } from "lucide-react";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../../components/navigation/navbar";

const Delivery = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <div className="flex items-center justify-between flex-wrap border-b border-gray-200 px-6 py-4 bg-white">
        <div className="flex items-center gap-3">
          <ArrowLeft
            className="h-5 w-5 cursor-pointer"
            onClick={() => navigate("/oms-dashboard")}
          />
          <div className="flex items-center gap-2">
            <LayoutDashboard />
            <h1 className="text-xl font-medium">
              Delivery & Route Planning: (Post-POC Phase)
              <span className="font-bold">
                {/* {loading ? "" : product?.NAME_EN} */}
              </span>
            </h1>
          </div>
        </div>
        <div className="flex gap-2">
          <button className="px-4 py-1.5 text-gray-700 border border-gray-300 rounded hover:bg-gray-50">
            Edit
          </button>
          <button className="px-4 py-1.5 text-white bg-blue-600 rounded hover:bg-blue-700">
            Save
          </button>
        </div>
      </div>
      {loading ? (
        <div className="flex justify-center items-center min-h-screen bg-gray-50">
          <div className="animate-pulse">
            <div className="w-16 h-16 border-4 border-gray-600 border-t-transparent rounded-full animate-spin"></div>
          </div>
        </div>
      ) : (
        <div className="p-6">
          <div className="grid grid-cols-1 md:grid-cols-12 gap-6 items-stretch">
            <div className="col-span-12 md:col-span-4 flex flex-col">
              <div className="bg-white rounded-lg shadow-md p-4 h-full">
                <div className="bg-gray-100 flex items-center justify-between mb-1 p-3 cursor-pointer">
                  <div className="flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="lucide lucide-map h-5 w-5 text-blue-500"
                    >
                      <path d="M3 6l6 3 6-3 6 3v12l-6-3-6 3-6-3V6z"></path>

                      <path d="M9 3v16"></path>

                      <path d="M15 6v16"></path>
                    </svg>

                    <span className="font-bold text-blue-500">
                      Delivery Summary
                    </span>
                  </div>
                </div>

                <div className="text-sm text-gray-600 mt-1">
                  <div className="divide-y divide-gray-100">
                    <div className="flex items-start gap-3 p-2 bg-blue-50 rounded-lg">
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">Delivery ID</div>

                        <div className="mt-1 font-medium text-blue-600 break-words">
                          DLV-202402-007
                        </div>
                      </div>
                    </div>

                    <div className="flex items-start gap-3 p-2">
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">
                          Order Number
                        </div>

                        <div className="mt-1 font-medium text-gray-900 break-words">
                          ORD-87654
                        </div>
                      </div>
                    </div>

                    <div className="flex items-start gap-3 p-2">
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">Customer</div>

                        <div className="mt-1 font-medium text-gray-900 break-words">
                          ABC Retail Ltd.
                        </div>
                      </div>
                    </div>

                    <div className="flex items-start gap-3 p-2">
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">
                          Scheduled Delivery
                        </div>

                        <div className="mt-1 font-medium text-gray-900 break-words">
                          Feb 20, 2025
                        </div>
                      </div>
                    </div>

                    <div className="flex items-start gap-3 p-2">
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">Status</div>

                        <div className="mt-1 font-medium text-green-600 break-words">
                          In Progress
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-span-12 md:col-span-8">
              <div className="bg-white rounded-lg shadow-md p-4 mb-6">
                <div className="bg-gray-100 flex items-center justify-between mb-1 p-3 cursor-pointer">
                  <div className="flex items-center gap-2">
                    <span className="font-bold text-blue-500">
                      Route Optimization
                    </span>
                  </div>
                </div>

                <div className="text-sm text-gray-600 mt-1">
                  <div className="divide-y divide-gray-100">
                    <div className="flex items-start gap-3 p-2 bg-blue-50 rounded-lg">
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">
                          Optimal Route
                        </div>

                        <div className="mt-1 font-medium text-blue-600 break-words">
                          Route A - 120 km
                        </div>
                      </div>
                    </div>

                    <div className="flex items-start gap-3 p-2">
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">
                          Estimated Travel Time
                        </div>

                        <div className="mt-1 font-medium text-gray-900 break-words">
                          2 hrs 15 mins
                        </div>
                      </div>
                    </div>

                    <div className="flex items-start gap-3 p-2">
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">
                          Live Traffic Impact
                        </div>

                        <div className="mt-1 font-medium text-yellow-600 break-words">
                          Minor Delays
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-lg shadow-md p-4 mb-6">
                <div className="bg-gray-100 flex items-center justify-between mb-1 p-3 cursor-pointer">
                  <div className="flex items-center gap-2">
                    <span className="font-bold text-blue-500">
                      Fleet Utilization
                    </span>
                  </div>
                </div>

                <div className="text-sm text-gray-600 mt-1">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="bg-white p-3 rounded-lg shadow-sm">
                      <dt className="text-xs text-gray-500 uppercase">
                        Vehicle Assigned
                      </dt>

                      <dd className="text-sm font-medium text-gray-800">
                        Truck #25
                      </dd>
                    </div>

                    <div className="bg-white p-3 rounded-lg shadow-sm">
                      <dt className="text-xs text-gray-500 uppercase">
                        Driver Name
                      </dt>

                      <dd className="text-sm font-medium text-gray-800">
                        John Doe
                      </dd>
                    </div>

                    <div className="bg-white p-3 rounded-lg shadow-sm">
                      <dt className="text-xs text-gray-500 uppercase">
                        Current Load
                      </dt>

                      <dd className="text-sm font-medium text-gray-800">80%</dd>
                    </div>

                    <div className="bg-white p-3 rounded-lg shadow-sm">
                      <dt className="text-xs text-gray-500 uppercase">
                        Max Capacity
                      </dt>

                      <dd className="text-sm font-medium text-gray-800">
                        10 Tons
                      </dd>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-lg shadow-md p-4">
                <div className="bg-gray-100 flex items-center justify-between mb-1 p-3 cursor-pointer">
                  <div className="flex items-center gap-2">
                    <span className="font-bold text-blue-500">
                      Delivery Performance
                    </span>
                  </div>
                </div>

                <div className="text-sm text-gray-600 mt-1">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="bg-white p-3 rounded-lg shadow-sm">
                      <dt className="text-xs text-gray-500 uppercase">
                        On-Time Rate
                      </dt>

                      <dd className="text-sm font-medium text-green-800">
                        95%
                      </dd>
                    </div>

                    <div className="bg-white p-3 rounded-lg shadow-sm">
                      <dt className="text-xs text-gray-500 uppercase">
                        Distance Covered
                      </dt>

                      <dd className="text-sm font-medium text-gray-800">
                        450 km
                      </dd>
                    </div>

                    <div className="bg-white p-3 rounded-lg shadow-sm">
                      <dt className="text-xs text-gray-500 uppercase">
                        Fuel Efficiency
                      </dt>

                      <dd className="text-sm font-medium text-gray-800">
                        8.5 km/l
                      </dd>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Delivery;
