import {
  BarChart2,
  Bell,
  Box,
  Boxes,
  Calendar,
  ChevronDown,
  Database,
  DollarSign,
  Factory,
  FileCheck,
  HardDrive,
  Home,
  LineChart,
  LogOut,
  Menu,
  Network,
  Package,
  Settings,
  ShoppingBag,
  TrendingUp,
  Truck,
  User,
  Users,
  Wallet,
  X,
  ClipboardList,
  ShoppingCart,
  Handshake,
  Warehouse,
} from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../redux/slices/authSlice";
import AppLauncher from "../appLauncherButton";

const Navbar: React.FC = () => {
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [openMobileDropdowns, setOpenMobileDropdowns] = useState<string[]>([]);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setActiveDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  interface NavItem {
    title: string;
    link: string;
    icon: React.ReactNode;
    dropdownItems?: { title: string; link: string; icon?: React.ReactNode }[];
    onClick?: any;
  }
  const handleLogout = () => {
    console.log("logount");
    localStorage.clear(); // Or be more specific: localStorage.removeItem('specific_key')

    dispatch(logout());

    navigate("/login");
  };
  const navItems: NavItem[] = [
    {
      title: "Dashboard",
      link: "/oms-dashboard",
      icon: <Home className="w-3 h-4 text-[#000]" />,
    },
    {
      title: "Orders",
      link: "/eu-orders",
      icon: <ClipboardList className="w-3 h-4 text-[#000]" />,
    },
    {
      title: "Manufacturing",
      link: "#",
      icon: <Factory className="w-3 h-4 text-[#000]" />,
      dropdownItems: [
        {
          title: "Planning Phase (UI Prototype)",
          link: "/planning-phase/schedule-person",
          icon: <Calendar className="w-3 h-4 text-[#000]" />,
        },
        {
          title: "Production Phase (UI Prototype)",
          link: "/production-phase",
          icon: <HardDrive className="w-3 h-4 text-[#000]" />,
        },
        {
          title: "Shipment Phase (UI Prototype)",
          link: "/shipment-phase/shipping",
          icon: <Truck className="w-3 h-4 text-[#000]" />,
        },
      ],
    },
    {
      title: "Logistics",
      link: "#",
      icon: <Truck className="w-3 h-4 text-[#000]" />,
      dropdownItems: [
        {
          title: "Shipments & Tracking (Post-POC Phase)",
          link: "/shipments-tracking",
          icon: <Truck className="w-3 h-4 text-[#000]" />,
        },
        {
          title: "Freight & Carrier Management (Post-POC Phase)",
          link: "/freight-carrier",
          icon: <Truck className="w-3 h-4 text-[#000]" />,
        },
        {
          title: "Delivery & Route Planning (Post-POC Phase)",
          link: "/delivery-route",
          icon: <Truck className="w-3 h-4 text-[#000]" />,
        },
      ],
    },
    {
      title: "Financial",
      link: "#",
      icon: <DollarSign className="w-3 h-4 text-[#000]" />,
      dropdownItems: [
        {
          title: "Salesview (Post-POC Phase)",
          link: "/salesview",
          icon: <DollarSign className="w-3 h-4 text-[#000]" />,
        },
        {
          title: "Bank Reconciliation (Post-POC Phase)",
          link: "/bankReconciliation",
          icon: <DollarSign className="w-3 h-4 text-[#000]" />,
        },
        {
          title: "Order Release (Post-POC Phase)",
          link: "/order-release",
          icon: <DollarSign className="w-3 h-4 text-[#000]" />,
        },
      ],
    },
    {
      title: "Procurement",
      link: "#",
      icon: <ShoppingCart className="w-3 h-4 text-[#000]" />,
      dropdownItems: [
        {
          title: "Purchase Orders & Requisitions (Post-POC Phase)",
          link: "/purchase-order",
          icon: <ShoppingCart className="w-3 h-4 text-[#000]" />,
        },
        {
          title: "Supplier Contracts & Sourcing (Post-POC Phase)",
          link: "/supplier-contracts",
          icon: <ShoppingCart className="w-3 h-4 text-[#000]" />,
        },
        {
          title: "Invoice & Payment Processing (Post-POC Phase)",
          link: "/invoice-payment",
          icon: <ShoppingCart className="w-3 h-4 text-[#000]" />,
        },
      ],
    },
    {
      title: "Inventory",
      link: "#",
      icon: <Boxes className="w-3 h-4 text-[#000]" />,
      dropdownItems: [
        {
          title: "EU Products",
          link: "/eu-products",
          icon: <Package className="w-3 h-4 text-[#000]" />,
        },
        // {
        //   title: "EU Customers",
        //   link: "#",
        //   icon: <Users className="w-3 h-4 text-[#000]" />,
        // },
      ],
    },
    {
      title: "Warehouse",
      link: "#",
      icon: <Warehouse className="w-3 h-4 text-[#000]" />,
      dropdownItems: [
        {
          title: "Inbound & Receiving (Post-POC Phase)",
          link: "/inbound-receiving",
          icon: <Warehouse className="w-3 h-4 text-[#000]" />,
        },
        {
          title: "Inventory & Order Fulfillment (Post-POC Phase)",
          link: "/inventory-fulfillment",
          icon: <Warehouse className="w-3 h-4 text-[#000]" />,
        },
        {
          title: "Stock Audits & Compliance (Post-POC Phase)",
          link: "/stock-compliance",
          icon: <Warehouse className="w-3 h-4 text-[#000]" />,
        },
      ],
    },
    {
      title: "Supplier Portal",
      link: "#",
      icon: <Handshake className="w-3 h-4 text-[#000]" />,
      dropdownItems: [
        {
          title: "Supplier Onboarding & Management (Post-POC Phase)",
          link: "/supplier-onboarding",
          icon: <Handshake className="w-3 h-4 text-[#000]" />,
        },
        {
          title: "Order & Shipment Collaboration (Post-POC Phase)",
          link: "/order-shipment",
          icon: <Handshake className="w-3 h-4 text-[#000]" />,
        },
        {
          title: "Invoices & Payments (Post-POC Phase)",
          link: "/invoices-payments",
          icon: <Handshake className="w-3 h-4 text-[#000]" />,
        },
      ],
    },
    {
      title: "Analytics",
      link: "#",
      icon: <BarChart2 className="w-3 h-4 text-[#000]" />,
      dropdownItems: [
        {
          title: "Demand",
          link: "/demand",
          icon: <TrendingUp className="w-3 h-4 text-[#000]" />,
        },
        {
          title: "Sales",
          link: "/sales",
          icon: <DollarSign className="w-3 h-4 text-[#000]" />,
        },
        {
          title: "Supply",
          link: "/supply",
          icon: <Box className="w-3 h-4 text-[#000]" />,
        },
        {
          title: "Performance",
          link: "/performance",
          icon: <LineChart className="w-3 h-4 text-[#000]" />,
        },
        // {
        //   title: "Leadership",
        //   link: "#",
        //   icon: <Network className="w-3 h-4 text-[#000]" />,
        // },
      ],
    },
  ];

  const toggleMobileDropdown = (title: string) => {
    setOpenMobileDropdowns((prev) =>
      prev.includes(title)
        ? prev.filter((item) => item !== title)
        : [...prev, title]
    );
  };

  const handleNavItemClick = (item: NavItem) => {
    if (item?.dropdownItems) {
      setActiveDropdown((prev) => (prev === item?.title ? null : item?.title));
    } else {
      navigate(item.link);
      setActiveDropdown(null);
    }
    if (item.title === "Logout") {
      item.onClick();
    }
  };

  const MobileNavItem = ({ item }: { item: NavItem }) => {
    const isOpen = openMobileDropdowns.includes(item.title);

    return (
      <div className="border-b border-gray-200">
        {item.dropdownItems ? (
          <div>
            <button
              onClick={(e) => {
                e.stopPropagation();
                toggleMobileDropdown(item.title);
              }}
              className="w-full flex items-center justify-between px-4 py-3 text-xs text-gray-700 hover:bg-blue-50"
            >
              <div className="flex items-center space-x-2">
                {item.icon}
                <span>{item.title}</span>
              </div>
              <ChevronDown
                className={`w-2 h-4 transition-transform duration-200 ${
                  isOpen ? "rotate-180" : ""
                }`}
              />
            </button>
            <div
              className={`
                overflow-hidden transition-all duration-200 ease-in-out bg-gray-50
                ${isOpen ? "max-h-[500px]" : "max-h-0"}
              `}
            >
              {item.dropdownItems.map((subItem) => (
                <Link
                  key={subItem.title}
                  to={subItem.link}
                  className="flex items-center space-x-2 px-6 py-3 text-xs text-gray-600 hover:bg-blue-50"
                  onClick={() => setIsDrawerOpen(false)}
                >
                  {subItem.icon}
                  <span>{subItem.title}</span>
                </Link>
              ))}
            </div>
          </div>
        ) : (
          <Link
            to={item.link}
            className="flex items-center space-x-2 px-4 py-3 text-xs text-gray-700 hover:bg-blue-50"
            onClick={() => setIsDrawerOpen(false)}
          >
            {item.icon}
            <span>{item.title}</span>
          </Link>
        )}
      </div>
    );
  };

  return (
    <>
      <nav
        className="bg-white border-b fixed w-full top-0 z-50"
        style={{ borderBottomWidth: "3px", borderBottomColor: "#1b96ff" }}
      >
        <div className="px-4">
          <div className="flex items-center justify-between h-lg-10 h-md-16">
            <div className="flex items-center space-x-4">
              <AppLauncher />
              <span
                className="text-md font-bold"
                style={{ display: "inline-block", whiteSpace: "nowrap" }}
              >
                Nexus Supply Chain Management
              </span>
              {/* Desktop Navigation */}
              <div className="hidden md:flex space-x-1 ml-6" ref={dropdownRef}>
                {navItems.map((item, index) => (
                  <div key={item.title} className="relative ">
                    <div
                      onClick={() => handleNavItemClick(item)}
                      className={`
                        cursor-pointer flex items-center space-x-1 px-1 h-full text-sm font-medium text-[#000] hover:bg-blue-50
                        ${index === 0 ? "border-r border-gray-200" : ""} 
                        ${
                          item.title === "Logout"
                            ? "border-l border-gray-200"
                            : ""
                        }
                      `}
                    >
                      {item.icon}
                      <span style={{ fontSize: 12 }}>{item.title}</span>
                      {item.dropdownItems && (
                        <ChevronDown
                          className={`ml-1 h-4 w-2 text-[#000] transition-transform duration-200 ${
                            activeDropdown === item.title ? "rotate-180" : ""
                          }`}
                        />
                      )}
                    </div>

                    {item.dropdownItems && activeDropdown === item.title && (
                      <div
                        className="absolute left-0 mt-1  bg-white border border-gray-200 rounded-md shadow-lg z-50"
                        style={
                          [
                            "Financial",
                            "Warehouse",
                            "Logistics",
                            "Procurement",
                            "Manufacturing",

                            "Supplier Portal",
                          ].includes(item.title)
                            ? { width: 320 }
                            : {}
                        }
                      >
                        {item.dropdownItems.map((dropdownItem) => (
                          <Link
                            key={dropdownItem.title}
                            to={dropdownItem.link}
                            className="flex items-center space-x-2 px-4 py-2 text-xs text-gray-700 hover:bg-blue-50"
                            onClick={() => setActiveDropdown(null)}
                          >
                            {dropdownItem.icon}
                            <span>{dropdownItem.title}</span>
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>

            {/* Desktop Action Buttons */}
            <div className="hidden md:flex items-center space-x-1">
              <div className="relative">
                <button
                  className="p-2 rounded-md hover:bg-blue-50"
                  onClick={() =>
                    setActiveDropdown(activeDropdown === "user" ? null : "user")
                  }
                >
                  <User className="h-5 w-5 text-[#000]" />
                </button>
                {activeDropdown === "user" && (
                  <div
                    className="absolute right-0 mt-2 bg-white border border-gray-200 rounded-md shadow-lg z-50"
                    style={{ width: "320px" }}
                  >
                    <button
                      className="flex items-center space-x-4 py-3 px-3 w-full text-left hover:bg-red-50 text-red-600"
                      onClick={handleLogout}
                    >
                      <LogOut className="w-4 h-4" />
                      <span className="text-sm">Log Out</span>
                    </button>
                    <div className="flex items-center space-x-4 py-3 px-3 hover:bg-gray-50">
                      <User className="w-4 h-4 text-[#000]" />
                      <span className="text-sm">
                        My Profile (Post-POC Phase)
                      </span>
                    </div>
                  </div>
                )}
              </div>

              <button
                className="p-2 rounded-md hover:bg-blue-50"
                onClick={() => setActiveDropdown("settings")}
              >
                <Settings className="h-5 w-5 text-[#000]" />
              </button>
              {activeDropdown === "settings" && (
                <div
                  className="absolute right-20 top-5 mt-1  bg-white border border-gray-200 rounded-md shadow-lg z-50 "
                  style={{ width: 320 }}
                >
                  <div>
                    <div className="flex items-center space-x-4 py-3 px-3">
                      <Settings className="w-3 h-3" />
                      <span>Master Data (Post-POC Phase)</span>
                    </div>
                    <div className="flex items-center space-x-4 py-3 px-3">
                      <Settings className="w-3 h-3" />
                      <span>Access Control (Post-POC Phase)</span>
                    </div>
                  </div>
                </div>
              )}
              <button className="p-2 rounded-md hover:bg-blue-50">
                <Bell className="h-5 w-5 text-[#000]" />
              </button>
            </div>

            {/* Mobile Menu Button */}
            <button
              className="md:hidden p-2 rounded-md hover:bg-blue-50"
              onClick={(e) => {
                e.stopPropagation();
                setIsDrawerOpen(true);
              }}
            >
              <Menu className="h-6 w-6 text-[#000]" />
            </button>
          </div>
        </div>
      </nav>

      {/* Add margin to account for fixed navbar */}
      <div className="xl:h-10 lg:h-16 md:h-20 sm:h-10 h-10" />

      {/* Mobile Drawer Backdrop */}
      {isDrawerOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300 md:hidden z-40"
          onClick={() => setIsDrawerOpen(false)}
        />
      )}

      {/* Mobile Drawer */}
      <div
        className={`
          fixed top-0 right-0 bottom-0 w-3/4 bg-white shadow-xl transform transition-transform duration-300 ease-in-out md:hidden z-50
          ${isDrawerOpen ? "translate-x-0" : "translate-x-full"}
        `}
      >
        <div className="p-4 border-b border-gray-200 flex justify-between items-center">
          <h2 className="text-lg font-semibold">Menu</h2>
          <button
            onClick={() => setIsDrawerOpen(false)}
            className="p-2 rounded-md hover:bg-blue-50"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="flex flex-col h-[calc(100%-60px)]">
          <div className="flex-grow overflow-y-auto">
            <div className="py-2">
              {navItems.map((item) => (
                <MobileNavItem key={item.title} item={item} />
              ))}
            </div>
          </div>

          <div className="flex items-center justify-around p-4 border-t border-gray-200 bg-white">
            <button className="p-2 rounded-md hover:bg-blue-50">
              <User className="h-5 w-5 text-[#000]" />
            </button>
            <button className="p-2 rounded-md hover:bg-blue-50">
              <Settings className="h-5 w-5 text-[#000]" />
            </button>
            <button className="p-2 rounded-md hover:bg-blue-50">
              <Bell className="h-5 w-5 text-[#000]" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
