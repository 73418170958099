import React, { useState, useEffect } from "react";
import { ArrowUpDown, Edit2, Eye, Search, X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LoadingBox from "../../../../components/LoadingBox";
import { GET } from "../../../../utils/apiCalls";
import API from "../../../../config/api";
import moment from "moment";

interface Account {
  id: number;
  ACCOUNT_NAME: string;
  SALUTATION: string;
  EMAIL: string;
  PHONE: string;
  MOBILE: string;
  STREET: string;
  CITY: string;
  POSTAL_CODE: string;
  COUNTRY: string;
  FIRST_NAME?: string;
  MIDDLE_NAME?: string;
  LAST_NAME?: string;
  SUFFIX?: string;
  ACCOUNT_RECORD_TYPE?: string;
  ACCOUNT_OWNER?: string;
  TYPE?: string;
  ASI_NUMBER?: string;
  BUSINESS_UNIT?: string;
}

interface AccountsResponse {
  data: Account[];
  totalRecords: number;
  filteredRecords: number;
  totalPages: number;
  currentPage: number;
}

interface AccountsTableProps {
  totalRecords: (val: number) => void;
  filteredRecords: (val: number) => void;
}

const dummyData: Account[] = [
  {
    id: 1,
    ACCOUNT_NAME: "Dummy Data 1",
    SALUTATION: "Mr.",
    EMAIL: "john.doe@example.com",
    PHONE: "123-456-7890",
    MOBILE: "098-765-4321",
    STREET: "123 Main St",
    CITY: "Anytown",
    POSTAL_CODE: "12345",
    COUNTRY: "Country A",
    FIRST_NAME: "John",
    MIDDLE_NAME: "A.",
    LAST_NAME: "Doe",
    SUFFIX: "Jr.",
    ACCOUNT_RECORD_TYPE: "Type A",
    ACCOUNT_OWNER: "Owner A",
    TYPE: "Type 1",
    ASI_NUMBER: "ASI123",
    BUSINESS_UNIT: "Unit A",
  },
  {
    id: 2,
    ACCOUNT_NAME: "Dummy Data 2",
    SALUTATION: "Ms.",
    EMAIL: "jane.smith@example.com",
    PHONE: "234-567-8901",
    MOBILE: "987-654-3210",
    STREET: "456 Elm St",
    CITY: "Othertown",
    POSTAL_CODE: "67890",
    COUNTRY: "Country B",
    FIRST_NAME: "Jane",
    MIDDLE_NAME: "B.",
    LAST_NAME: "Smith",
    SUFFIX: "Sr.",
    ACCOUNT_RECORD_TYPE: "Type B",
    ACCOUNT_OWNER: "Owner B",
    TYPE: "Type 2",
    ASI_NUMBER: "ASI456",
    BUSINESS_UNIT: "Unit B",
  },
  {
    id: 3,
    ACCOUNT_NAME: "Dummy Data 3",
    SALUTATION: "Ms.",
    EMAIL: "jane.smith@example.com",
    PHONE: "234-567-8901",
    MOBILE: "987-654-3210",
    STREET: "789 Oak St",
    CITY: "Sometown",
    POSTAL_CODE: "11223",
    COUNTRY: "Country C",
    FIRST_NAME: "Jane",
    MIDDLE_NAME: "C.",
    LAST_NAME: "Smith",
    SUFFIX: "III",
    ACCOUNT_RECORD_TYPE: "Type C",
    ACCOUNT_OWNER: "Owner C",
    TYPE: "Type 3",
    ASI_NUMBER: "ASI789",
    BUSINESS_UNIT: "Unit C",
  },
  {
    id: 4,
    ACCOUNT_NAME: "Dummy Data 4",
    SALUTATION: "Ms.",
    EMAIL: "jane.smith@example.com",
    PHONE: "234-567-8901",
    MOBILE: "987-654-3210",
    STREET: "101 Pine St",
    CITY: "Anycity",
    POSTAL_CODE: "44556",
    COUNTRY: "Country D",
    FIRST_NAME: "Jane",
    MIDDLE_NAME: "D.",
    LAST_NAME: "Smith",
    SUFFIX: "IV",
    ACCOUNT_RECORD_TYPE: "Type D",
    ACCOUNT_OWNER: "Owner D",
    TYPE: "Type 4",
    ASI_NUMBER: "ASI101",
    BUSINESS_UNIT: "Unit D",
  },
  {
    id: 5,
    ACCOUNT_NAME: "Dummy Data 5",
    SALUTATION: "Ms.",
    EMAIL: "jane.smith@example.com",
    PHONE: "234-567-8901",
    MOBILE: "987-654-3210",
    STREET: "202 Maple St",
    CITY: "Everytown",
    POSTAL_CODE: "77889",
    COUNTRY: "Country E",
    FIRST_NAME: "Jane",
    MIDDLE_NAME: "E.",
    LAST_NAME: "Smith",
    SUFFIX: "V",
    ACCOUNT_RECORD_TYPE: "Type E",
    ACCOUNT_OWNER: "Owner E",
    TYPE: "Type 5",
    ASI_NUMBER: "ASI202",
    BUSINESS_UNIT: "Unit E",
  },
];

const AccountTable: React.FC<AccountsTableProps> = ({
  totalRecords,
  filteredRecords,
}) => {
  const [accounts, setAccounts] = useState<Account[]>(dummyData);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sortField, setSortField] = useState("created_at");
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC">("DESC");
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecordsCount, setTotalRecordsCount] = useState(dummyData.length);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchAccounts = async () => {
      setIsLoading(true);
      try {
        // Simulate API call with dummy data
        let filteredData = dummyData;

        // Apply search filter
        if (searchTerm) {
          filteredData = filteredData.filter((account) =>
            Object.values(account).some((value) =>
              value.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
          );
        }

        const data: AccountsResponse = {
          data: filteredData,
          totalRecords: dummyData.length,
          filteredRecords: filteredData.length,
          totalPages: Math.ceil(filteredData.length / itemsPerPage),
          currentPage: currentPage,
        };

        setAccounts(data.data);
        setTotalPages(data.totalPages);
        setTotalRecordsCount(data.totalRecords);
        totalRecords(data.totalRecords);
        filteredRecords(data.filteredRecords);
      } catch (error) {
        console.error("Error fetching accounts:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAccounts();
  }, [searchTerm, currentPage, itemsPerPage, sortField, sortOrder]);

  const handleSort = (column: string) => {
    if (sortField === column) {
      setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC");
    } else {
      setSortField(column);
      setSortOrder("ASC");
    }
  };

  const handleView = (AccountId: string) => {
    navigate(`/ccm-account/${AccountId}`);
  };

  const clearSearch = () => {
    setSearchTerm("");
    setCurrentPage(1);
  };

  const handleEntriesChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const renderPageNumbers = () => {
    const pages = [];
    const showAround = 1;

    pages.push(1);

    if (currentPage > 2 + showAround) {
      pages.push("...");
    }

    for (
      let i = Math.max(2, currentPage - showAround);
      i <= Math.min(totalPages - 1, currentPage + showAround);
      i++
    ) {
      pages.push(i);
    }

    if (currentPage < totalPages - (1 + showAround)) {
      pages.push("...");
    }

    if (totalPages > 1) {
      pages.push(totalPages);
    }

    return (
      <div className="flex items-center space-x-1">
        {pages.map((page, index) => (
          <React.Fragment key={index}>
            {page === "..." ? (
              <span className="px-2 py-1 text-gray-500">...</span>
            ) : (
              <button
                onClick={() => setCurrentPage(page as number)}
                className={`min-w-[32px] px-2 py-1 rounded text-sm ${
                  currentPage === page
                    ? "bg-gray-600 text-white"
                    : "text-gray-600 hover:bg-gray-100"
                }`}
              >
                {page}
              </button>
            )}
          </React.Fragment>
        ))}
      </div>
    );
  };

  return (
    <div className="p-4 md:p-6 lg:col-span-10">
      <div className="bg-white rounded-lg border border-gray-200 shadow-sm">
        {/* Table Header */}
        <div className="p-4 border-b border-gray-200">
          <h2 className="text-xl font-semibold">Accounts</h2>
          <div className="mt-4 flex flex-col sm:flex-row justify-between items-center gap-4">
            <div className="flex items-center gap-2">
              <span className="text-sm text-gray-600">Show</span>
              <select
                value={itemsPerPage}
                onChange={handleEntriesChange}
                className="px-2 py-1 border border-gray-300 rounded-md text-sm"
              >
                {[10, 25, 50, 100].map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <span className="text-sm text-gray-600">entries</span>
            </div>
            <div className="relative w-full sm:w-auto">
              <Search className="h-5 w-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                placeholder="Search Accounts...."
                className="w-full sm:w-64 pl-10 pr-10 py-2 border border-gray-300 rounded-lg"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  setCurrentPage(1);
                }}
              />
              {searchTerm && (
                <button
                  onClick={clearSearch}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
                >
                  <X className="h-5 w-5" />
                </button>
              )}
            </div>
          </div>
        </div>

        {/* Table Content */}
        <div className="overflow-x-auto">
          {isLoading ? (
            <LoadingBox />
          ) : (
            <table className="w-full">
              <thead className="bg-gray-50">
                <tr>
                  {[
                    { key: "ACCOUNT_NAME", label: "Account Name" },
                    { key: "SALUTATION", label: "Salutation" },
                    { key: "FIRST_NAME", label: "First Name" },
                    { key: "MIDDLE_NAME", label: "Middle Name" },
                    { key: "LAST_NAME", label: "Last Name" },
                    { key: "SUFFIX", label: "Suffix" },
                    {
                      key: "ACCOUNT_RECORD_TYPE",
                      label: "Account Record Type",
                    },
                    { key: "ACCOUNT_OWNER", label: "Account Owner" },
                    { key: "EMAIL", label: "Email" },
                    { key: "PHONE", label: "Phone" },
                    { key: "TYPE", label: "Type" },
                    { key: "MOBILE", label: "Mobile" },
                    { key: "ASI_NUMBER", label: "ASI Number" },
                    { key: "BUSINESS_UNIT", label: "Business Unit" },
                  ].map(({ key, label }) => (
                    <th
                      key={key}
                      onClick={() => handleSort(key)}
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                    >
                      <div className="flex items-center space-x-1">
                        <span>{label}</span>
                        <ArrowUpDown className="h-4 w-4" />
                      </div>
                    </th>
                  ))}
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {accounts?.map((account) => (
                  <tr key={account.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-blue-600 font-medium">
                      {account.ACCOUNT_NAME}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {account.SALUTATION}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {account.FIRST_NAME}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {account.MIDDLE_NAME}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {account.LAST_NAME}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {account.SUFFIX}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {account.ACCOUNT_RECORD_TYPE}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {account.ACCOUNT_OWNER}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {account.EMAIL}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {account.PHONE}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {account.TYPE}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {account.MOBILE}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {account.ASI_NUMBER}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                      {account.BUSINESS_UNIT}
                    </td>
                    <td>
                      <div className="flex items-center gap-3">
                        <button
                          onClick={() => handleView(account.id.toString())}
                          className="text-blue-600 hover:text-blue-800"
                        >
                          <Eye className="h-5 w-5" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        {/* Pagination */}
        <div className="px-6 py-4 border-t border-gray-200">
          <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
            <div className="text-sm text-gray-500">
              Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
              {Math.min(currentPage * itemsPerPage, totalRecordsCount)} of{" "}
              {totalRecordsCount} entries
            </div>
            <div className="flex items-center space-x-2">
              <button
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
                className="px-3 py-1 text-sm disabled:opacity-50"
              >
                Previous
              </button>
              {renderPageNumbers()}
              <button
                onClick={() =>
                  setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                }
                disabled={currentPage === totalPages}
                className="px-3 py-1 text-sm disabled:opacity-50"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountTable;
