import { ArrowLeft, LayoutDashboard } from "lucide-react";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../../components/navigation/navbar";

const SupplierContracts = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <div className="flex items-center justify-between flex-wrap border-b border-gray-200 px-6 py-4 bg-white">
        <div className="flex items-center gap-3">
          <ArrowLeft
            className="h-5 w-5 cursor-pointer"
            onClick={() => navigate("/oms-dashboard")}
          />
          <div className="flex items-center gap-2">
            <LayoutDashboard />
            <h1 className="text-xl font-medium">
              Supplier Contracts & Sourcing: (Post-POC Phase)
              <span className="font-bold">
                {/* {loading ? "" : product?.NAME_EN} */}
              </span>
            </h1>
          </div>
        </div>
        <div className="flex gap-2">
          <button className="px-4 py-1.5 text-gray-700 border border-gray-300 rounded hover:bg-gray-50">
            Edit
          </button>
          <button className="px-4 py-1.5 text-white bg-blue-600 rounded hover:bg-blue-700">
            Save
          </button>
        </div>
      </div>
      {loading ? (
        <div className="flex justify-center items-center min-h-screen bg-gray-50">
          <div className="animate-pulse">
            <div className="w-16 h-16 border-4 border-gray-600 border-t-transparent rounded-full animate-spin"></div>
          </div>
        </div>
      ) : (
        <div className="p-6">
          <div className="grid grid-cols-1 md:grid-cols-12 gap-6 items-stretch">
            <div className="col-span-12 md:col-span-4 flex flex-col">
              <div className="bg-white rounded-lg shadow-md p-4 h-full">
                <div className="bg-gray-100 flex items-center justify-between mb-1 p-3 cursor-pointer">
                  <div className="flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="lucide lucide-handshake h-5 w-5 text-blue-500"
                    >
                      <path d="m11 17 2 2a1 1 0 1 0 3-3"></path>

                      <path d="m14 14 2.5 2.5a1 1 0 1 0 3-3l-3.88-3.88a3 3 0 0 0-4.24 0l-.88.88a1 1 0 1 1-3-3l2.81-2.81a5.79 5.79 0 0 1 7.06-.87l.47.28a2 2 0 0 0 1.42.25L21 4"></path>

                      <path d="m21 3 1 11h-2"></path>

                      <path d="M3 3 2 14l6.5 6.5a1 1 0 1 0 3-3"></path>

                      <path d="M3 4h8"></path>
                    </svg>

                    <span className="font-bold text-blue-500">
                      Supplier Contract Summary
                    </span>
                  </div>
                </div>

                <div className="text-sm text-gray-600 mt-1">
                  <div className="divide-y divide-gray-100">
                    <div className="flex items-start gap-3 p-2 bg-blue-50 rounded-lg">
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">Contract ID</div>

                        <div className="mt-1 font-medium text-blue-600 break-words">
                          CNT-202402-008
                        </div>
                      </div>
                    </div>

                    <div className="flex items-start gap-3 p-2">
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">Supplier</div>

                        <div className="mt-1 font-medium text-gray-900 break-words">
                          ABC Industrial Ltd.
                        </div>
                      </div>
                    </div>

                    <div className="flex items-start gap-3 p-2">
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">
                          Contract Type
                        </div>

                        <div className="mt-1 font-medium text-gray-900 break-words">
                          Annual Supply Agreement
                        </div>
                      </div>
                    </div>

                    <div className="flex items-start gap-3 p-2">
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">
                          Expiration Date
                        </div>

                        <div className="mt-1 font-medium text-gray-900 break-words">
                          Dec 31, 2025
                        </div>
                      </div>
                    </div>

                    <div className="flex items-start gap-3 p-2">
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">Status</div>

                        <div className="mt-1 font-medium text-green-600 break-words">
                          Active
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-span-12 md:col-span-8">
              <div className="bg-white rounded-lg shadow-md p-4 mb-6">
                <div className="bg-gray-100 flex items-center justify-between mb-1 p-3 cursor-pointer">
                  <div className="flex items-center gap-2">
                    <span className="font-bold text-blue-500">
                      Contract Details
                    </span>
                  </div>
                </div>

                <div className="text-sm text-gray-600 mt-1">
                  <div className="divide-y divide-gray-100">
                    <div className="flex items-start gap-3 p-2 bg-blue-50 rounded-lg">
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">
                          Agreement Terms
                        </div>

                        <div className="mt-1 font-medium text-blue-600 break-words">
                          Fixed Supply for 12 months
                        </div>
                      </div>
                    </div>

                    <div className="flex items-start gap-3 p-2">
                      <div className="flex-grow min-w-0">
                        <div className="text-sm text-gray-500">
                          Renewal Status
                        </div>

                        <div className="mt-1 font-medium text-gray-900 break-words">
                          Auto-renewal in progress
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-lg shadow-md p-4 mb-6">
                <div className="bg-gray-100 flex items-center justify-between mb-1 p-3 cursor-pointer">
                  <div className="flex items-center gap-2">
                    <span className="font-bold text-blue-500">
                      Sourcing Information
                    </span>
                  </div>
                </div>

                <div className="text-sm text-gray-600 mt-1">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="bg-white p-3 rounded-lg shadow-sm">
                      <dt className="text-xs text-gray-500 uppercase">
                        Supplier Rating
                      </dt>

                      <dd className="text-sm font-medium text-blue-800">
                        4.6/5
                      </dd>
                    </div>

                    <div className="bg-white p-3 rounded-lg shadow-sm">
                      <dt className="text-xs text-gray-500 uppercase">
                        Compliance Score
                      </dt>

                      <dd className="text-sm font-medium text-green-800">
                        99%
                      </dd>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-lg shadow-md p-4">
                <div className="bg-gray-100 flex items-center justify-between mb-1 p-3 cursor-pointer">
                  <div className="flex items-center gap-2">
                    <span className="font-bold text-blue-500">
                      Pricing & Terms
                    </span>
                  </div>
                </div>

                <div className="text-sm text-gray-600 mt-1">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="bg-white p-3 rounded-lg shadow-sm">
                      <dt className="text-xs text-gray-500 uppercase">
                        Unit Price
                      </dt>

                      <dd className="text-sm font-medium text-gray-800">
                        $45 per unit
                      </dd>
                    </div>

                    <div className="bg-white p-3 rounded-lg shadow-sm">
                      <dt className="text-xs text-gray-500 uppercase">
                        Discount
                      </dt>

                      <dd className="text-sm font-medium text-gray-800">
                        5% on bulk orders
                      </dd>
                    </div>

                    <div className="bg-white p-3 rounded-lg shadow-sm">
                      <dt className="text-xs text-gray-500 uppercase">
                        Payment Terms
                      </dt>

                      <dd className="text-sm font-medium text-gray-800">
                        Net 30 Days
                      </dd>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SupplierContracts;
