import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ArrowLeft, LayoutDashboard, ListFilter } from "lucide-react";
import Navbar from "../../components/navigation/navbar";
import { GET } from "../../utils/apiCalls";
import API from "../../config/api";
import NavbarCCM from "../../components/navigation/ccmnavbar";

const dummyData = [
  {
    id: 1,
    Opportunity_Name: "Opportunity A",
    Opportunity_Owner: "Wissam Messarra",
    Account_Name: "Account A",
    Close_Date: "2025-01-01",
    Type: "New Business",
    Stage: "Proposal",
    Primary_Campaign_Source: "Campaign A",
    Probability: "50",
    Budget_Confirmed: "1",
    Opportunity_Amount: "$100,000",
    Discovery_Completed: "1",
    NSV: "$50,000",
    ROI_Analysis_Completed: "0",
    Receive_Marketing_Emails: "1",
    Loss_Reason: "Price",
    Customer_Channel: "Channel A",
    Reason_for_Close_Lost: "Budget",
    Customer_PO: "PO123",
    Opportunity_Currency: "USD",
    Customer_Ref_Number: "Ref001",
    Product: "10lb Bag",
    Sale_Source_Origin: "Web",
    Billing_Address: "Address 1",
    Shipping_Address: "Ship Address 1",
    Order_Number: "ORD123",
    Package_Choice: "Standard",
  },
  {
    id: 2,
    Opportunity_Name: "Opportunity B",
    Opportunity_Owner: "Wissam Messarra",
    Account_Name: "Account B",
    Close_Date: "2025-02-01",
    Type: "Renewal",
    Stage: "Negotiation",
    Primary_Campaign_Source: "Campaign B",
    Probability: "75",
    Budget_Confirmed: "1",
    Opportunity_Amount: "$200,000",
    Discovery_Completed: "0",
    NSV: "$75,000",
    ROI_Analysis_Completed: "1",
    Receive_Marketing_Emails: "0",
    Loss_Reason: "Timing",
    Customer_Channel: "Channel B",
    Reason_for_Close_Lost: "Scope",
    Customer_PO: "PO124",
    Opportunity_Currency: "USD",
    Customer_Ref_Number: "Ref002",
    Product: "5lb Bag",
    Sale_Source_Origin: "Referral",
    Billing_Address: "Address 2",
    Shipping_Address: "Ship Address 2",
    Order_Number: "ORD124",
    Package_Choice: "Premium",
  },
  {
    id: 3,
    Opportunity_Name: "Opportunity C",
    Opportunity_Owner: "Wissam Messarra",
    Account_Name: "Account C",
    Close_Date: "2025-03-01",
    Type: "Upsell",
    Stage: "Closed Won",
    Primary_Campaign_Source: "Campaign C",
    Probability: "90",
    Budget_Confirmed: "0",
    Opportunity_Amount: "$150,000",
    Discovery_Completed: "1",
    NSV: "$60,000",
    ROI_Analysis_Completed: "0",
    Receive_Marketing_Emails: "1",
    Loss_Reason: "Competition",
    Customer_Channel: "Channel C",
    Reason_for_Close_Lost: "Price",
    Customer_PO: "PO125",
    Opportunity_Currency: "EUR",
    Customer_Ref_Number: "Ref003",
    Product: "7oz Bag",
    Sale_Source_Origin: "Event",
    Billing_Address: "Address 3",
    Shipping_Address: "Ship Address 3",
    Order_Number: "ORD125",
    Package_Choice: "Basic",
  },
  {
    id: 4,
    Opportunity_Name: "Opportunity D",
    Opportunity_Owner: "Wissam Messarra",
    Account_Name: "Account D",
    Close_Date: "2025-04-01",
    Type: "Cross-Sell",
    Stage: "Closed Lost",
    Primary_Campaign_Source: "Campaign D",
    Probability: "30",
    Budget_Confirmed: "1",
    Opportunity_Amount: "$250,000",
    Discovery_Completed: "0",
    NSV: "$85,000",
    ROI_Analysis_Completed: "1",
    Receive_Marketing_Emails: "0",
    Loss_Reason: "Budget",
    Customer_Channel: "Channel D",
    Reason_for_Close_Lost: "Timing",
    Customer_PO: "PO126",
    Opportunity_Currency: "GBP",
    Customer_Ref_Number: "Ref004",
    Product: "Acrylic 16oz",
    Sale_Source_Origin: "Email Campaign",
    Billing_Address: "Address 4",
    Shipping_Address: "Ship Address 4",
    Order_Number: "ORD126",
    Package_Choice: "Enterprise",
  },
  {
    id: 5,
    Opportunity_Name: "Opportunity E",
    Opportunity_Owner: "Wissam Messarra",
    Account_Name: "Account E",
    Close_Date: "2025-05-01",
    Type: "Retention",
    Stage: "Discovery",
    Primary_Campaign_Source: "Campaign E",
    Probability: "80",
    Budget_Confirmed: "0",
    Opportunity_Amount: "$300,000",
    Discovery_Completed: "1",
    NSV: "$95,000",
    ROI_Analysis_Completed: "1",
    Receive_Marketing_Emails: "1",
    Loss_Reason: "Scope",
    Customer_Channel: "Channel E",
    Reason_for_Close_Lost: "Competition",
    Customer_PO: "PO127",
    Opportunity_Currency: "AUD",
    Customer_Ref_Number: "Ref005",
    Product: "Business Card Kit",
    Sale_Source_Origin: "Social Media",
    Billing_Address: "Address 5",
    Shipping_Address: "Ship Address 5",
    Order_Number: "ORD127",
    Package_Choice: "Custom",
  },
];

const OpportunityView = () => {
  const { opportunityId }: any = useParams();
  const navigate = useNavigate();
  const [opportunityData, setOpportunityData] = useState<any>();
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    setLoading(true);
    const opportunityInfo = dummyData.find(
      (opportunityItem) => opportunityItem.id === parseInt(opportunityId)
    );
    setOpportunityData(opportunityInfo);
    setLoading(false);
  }, [opportunityId]);

  return (
    <div className="min-h-screen bg-gray-50">
      <NavbarCCM />
      <div className="flex items-center justify-between flex-wrap border-b border-gray-200 px-6 py-4 bg-white">
        <div className="flex items-center gap-3">
          <ArrowLeft
            className="h-5 w-5 cursor-pointer"
            onClick={() => navigate("/ccm-opportunities")}
          />
          <div className="flex items-center gap-2">
            <LayoutDashboard />
            <h1 className="text-xl font-medium">
              Opportunity:{" "}
              {loading ? "Loading..." : opportunityData?.Opportunity_Name}
            </h1>
          </div>
        </div>
        <div className="flex gap-2">
          <button className="px-4 py-1.5 text-gray-700 border border-gray-300 rounded hover:bg-gray-50">
            Edit
          </button>
          <button className="px-4 py-1.5 text-white bg-blue-600 rounded hover:bg-blue-700">
            Save
          </button>
        </div>
      </div>
      {loading ? (
        <div className="flex justify-center items-center min-h-screen bg-gray-50">
          <div className="animate-pulse">
            <div className="w-16 h-16 border-4 border-gray-600 border-t-transparent rounded-full animate-spin"></div>
          </div>
        </div>
      ) : (
        <>
          <div className="p-6 bg-gray-50">
            <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
              {/* <!-- Left Column --> */}
              <div className="col-span-12 md:col-span-6 bg-white rounded-lg shadow-md p-6">
                <div className="bg-gray-100 flex items-center justify-between mb-4 p-3 cursor-pointer">
                  <div className="flex items-center gap-2">
                    <ListFilter className="h-5 w-5 text-blue-500" />
                    <span className="font-bold text-blue-500">
                      Opportunity Information
                    </span>
                  </div>
                </div>
                <div className="space-y-4">
                  <div>
                    <label className="text-sm font-medium">
                      Opportunity Name
                    </label>
                    <p className="text-gray-700">
                      {opportunityData?.Opportunity_Name}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">
                      Opportunity Owner
                    </label>
                    <p className="text-gray-700">
                      {opportunityData?.Opportunity_Owner}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">Account Name</label>
                    <p className="text-gray-700">
                      {opportunityData?.Account_Name}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">Close Date</label>
                    <p className="text-gray-700">
                      {opportunityData?.Close_Date}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">Type</label>
                    <p className="text-gray-700">{opportunityData?.Type}</p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">Stage</label>
                    <p className="text-gray-700">{opportunityData?.Stage}</p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">
                      Primary Campaign Source
                    </label>
                    <p className="text-gray-700">
                      {opportunityData?.Primary_Campaign_Source}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">Probability</label>
                    <p className="text-gray-700">
                      {opportunityData?.Probability}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">
                      Budget Confirmed
                    </label>
                    <p className="text-gray-700">
                      {opportunityData?.Budget_Confirmed}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">
                      Opportunity Amount
                    </label>
                    <p className="text-gray-700">
                      {opportunityData?.Opportunity_Amount}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">
                      Discovery Completed
                    </label>
                    <p className="text-gray-700">
                      {opportunityData?.Discovery_Completed}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">NSV</label>
                    <p className="text-gray-700">{opportunityData?.NSV}</p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">
                      ROI Analysis Completed
                    </label>
                    <p className="text-gray-700">
                      {opportunityData?.ROI_Analysis_Completed}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">
                      Receive Marketing Emails
                    </label>
                    <p className="text-gray-700">
                      {opportunityData?.Receive_Marketing_Emails}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">Loss Reason</label>
                    <p className="text-gray-700">
                      {opportunityData?.Loss_Reason}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">
                      Customer Channel
                    </label>
                    <p className="text-gray-700">
                      {opportunityData?.Customer_Channel}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">
                      Reason for Close Lost
                    </label>
                    <p className="text-gray-700">
                      {opportunityData?.Reason_for_Close_Lost}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">Customer PO</label>
                    <p className="text-gray-700">
                      {opportunityData?.Customer_PO}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">
                      Opportunity Currency
                    </label>
                    <p className="text-gray-700">
                      {opportunityData?.Opportunity_Currency}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">
                      Customer Ref Number
                    </label>
                    <p className="text-gray-700">
                      {opportunityData?.Customer_Ref_Number}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">Product</label>
                    <p className="text-gray-700">{opportunityData?.Product}</p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">
                      Sale Source Origin
                    </label>
                    <p className="text-gray-700">
                      {opportunityData?.Sale_Source_Origin}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">
                      Billing Address
                    </label>
                    <p className="text-gray-700">
                      {opportunityData?.Billing_Address}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">
                      Shipping Address
                    </label>
                    <p className="text-gray-700">
                      {opportunityData?.Shipping_Address}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">Order Number</label>
                    <p className="text-gray-700">
                      {opportunityData?.Order_Number}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">
                      Package Choice
                    </label>
                    <p className="text-gray-700">
                      {opportunityData?.Package_Choice}
                    </p>
                  </div>
                </div>
              </div>

              {/* <!-- Right Column --> */}
              <div className="col-span-12 md:col-span-6 bg-white rounded-lg shadow-md p-6">
                {/* <!-- SAP Section --> */}
                <div className="bg-gray-100 flex items-center justify-between mb-4 p-3 cursor-pointer">
                  <div className="flex items-center gap-2">
                    <ListFilter className="h-5 w-5 text-blue-500" />
                    <span className="font-bold text-blue-500">
                      SAP Information
                    </span>
                  </div>
                </div>
                <div className="space-y-4">
                  <div>
                    <label className="text-sm font-medium">SAP Case ID</label>
                    <p className="text-gray-700">
                      {opportunityData?.SAP_Case_ID}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm font-medium">SAP Cases IDs</label>
                    <p className="text-gray-700">
                      {opportunityData?.SAP_Cases_IDs}
                    </p>
                  </div>
                </div>
                {/* <!-- Address Section --> */}
                <div className="bg-gray-100 flex items-center justify-between my-6 p-3 cursor-pointer">
                  <div className="flex items-center gap-2">
                    <ListFilter className="h-5 w-5 text-blue-500" />
                    <span className="font-bold text-blue-500">
                      Address Information
                    </span>
                  </div>
                </div>
                <div className="space-y-4">
                  {/* <!-- Mailing Address --> */}
                  <div>
                    <label className="text-sm font-medium">
                      Mailing Address
                    </label>
                    <p className="text-gray-700">
                      {opportunityData?.Mailing_Address}
                    </p>
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="text-sm font-medium">
                        Mailing Country
                      </label>
                      <p className="text-gray-700">
                        {opportunityData?.Mailing_Country}
                      </p>
                    </div>
                    <div>
                      <label className="text-sm font-medium">
                        Mailing Street
                      </label>
                      <p className="text-gray-700">
                        {opportunityData?.Mailing_Street}
                      </p>
                    </div>
                  </div>
                  {/* <!-- Billing Address --> */}
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="text-sm font-medium">
                        Billing Address
                      </label>
                      <p className="text-gray-700">
                        {opportunityData?.Billing_Address}
                      </p>
                    </div>
                    <div>
                      <label className="text-sm font-medium">
                        Billing Country
                      </label>
                      <p className="text-gray-700">
                        {opportunityData?.Billing_Country}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default OpportunityView;
