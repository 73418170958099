import React, { useEffect, useState } from "react";
import { ArrowUpDown } from "lucide-react";
import LoadingBox from "../../../components/LoadingBox";
import { POST } from "../../../utils/apiCalls";
import API from "../../../config/api";
import Pagination from "../../sales/components/pagination";

const ProductSplit = (props: any) => {
  const [order, setOrder] = useState("ASC");
  const [categoryData, setCategoryData] = useState<any>();
  const [isCategoryLoading, setIsCategoryLoading] = useState<any>();
  const [isCountryLoading, setIsCountryLoading] = useState<any>();
  const [isOccationLoading, setIsOccationLoading] = useState<any>();
  const [sortCategoryField, setSortCategoryField] =
    useState<any>("DISCOUNTCAMPAIGN");
  const [sortCategoryOrder, setSortCategoryOrder] = useState<"ASC" | "DESC">(
    "DESC"
  );
  const [sortOccationField, setSortOccationField] =
    useState<any>("DISCOUNTCAMPAIGN");
  const [sortOccationOrder, setSortOccationOrder] = useState<"ASC" | "DESC">(
    "DESC"
  );
  const [sortCountryField, setSortCountryField] =
    useState<any>("DISCOUNTCAMPAIGN");
  const [sortCountryOrder, setSortCountryOrder] = useState<"ASC" | "DESC">(
    "DESC"
  );
  const [occationData, setOccationData] = useState<any>();
  const [countryData, setCountryData] = useState<any>();
  const [countryPage, setCountryPage] = useState<any>(1);
  const [categoryPage, setCategoryPage] = useState<any>(1);
  const [occationPage, setOccationPage] = useState<any>(1);
  const formatCurrency = (value: any) => {
    if (isNaN(value)) return "-";
    return new Intl.NumberFormat("en-EU", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  useEffect(() => {
    fetchProductCategory({
      ...props?.filters,
      sort: sortCategoryField,
      order: sortCategoryOrder,
      page: categoryPage,
      limit: 10,
    });
  }, [categoryPage, props?.filters, sortCategoryField, sortCategoryOrder]);

  useEffect(() => {
    fetchProductOccation({
      ...props?.filters,
      sort: sortOccationField,
      order: sortOccationOrder,
      page: occationPage,
      limit: 10,
    });
  }, [occationPage, props?.filters, sortOccationField, sortOccationOrder]);
  useEffect(() => {
    fetchProductCountry({
      ...props?.filters,
      sort: sortCountryField,
      order: sortCountryOrder,
      page: countryPage,
      limit: 10,
    });
  }, [categoryPage, props?.filters, sortCountryField, sortCountryOrder]);

  const GetTotal = (data: any[], field: string): number => {
    return data?.reduce((total: number, item: any) => {
      const value = parseFloat(item[field]);
      return total + (isNaN(value) ? 0 : value);
    }, 0);
  };

  const fetchProductCategory = async (filterValues: any = {}) => {
    try {
      setIsCategoryLoading(true);
      const CategoryDataResponse: any = await POST(
        API.CATEGORY_NSV,
        filterValues
      );
      if (CategoryDataResponse) {
        setCategoryData(CategoryDataResponse);
      }
    } catch (error) {
      console.error("Error fetching occasions by country:", error);
    } finally {
      setIsCategoryLoading(false);
    }
  };
  const fetchProductOccation = async (filterValues: any = {}) => {
    try {
      setIsOccationLoading(true);
      const OccationDataResponse: any = await POST(
        API.OCCASION_NSV,
        filterValues
      );
      if (OccationDataResponse) {
        setOccationData(OccationDataResponse);
      }
    } catch (error) {
      console.error("Error fetching occasions by country:", error);
    } finally {
      setIsOccationLoading(false);
    }
  };
  const fetchProductCountry = async (filterValues: any = {}) => {
    try {
      setIsCountryLoading(true);
      const CountryDataResponse: any = await POST(
        API.COUNTRY_NSV,
        filterValues
      );
      if (CountryDataResponse) {
        console.log(
          "=======CountryDataResponse==============",
          CountryDataResponse
        );
        setCountryData(CountryDataResponse);
      }
    } catch (error) {
      console.error("Error fetching occasions by country:", error);
    } finally {
      setIsCountryLoading(false);
    }
  };

  const handleSort = (
    column: string,
    sort: any,
    order: any,
    sortItem: any,
    orderItem: any
  ) => {
    if (sortItem === column) {
      order(orderItem === "ASC" ? "DESC" : "ASC");
    } else {
      sort(column);
      order("ASC");
    }
  };
  return (
    <div className="grid grid-cols-1 md:grid-cols-12 gap-6">
      <div className="sm:col-span-12 md:col-span-7">
        {/* First Table */}
        <div className="bg-white rounded-lg shadow">
          <div className="p-4 border-b border-gray-200">
            <h2 className="text-lg font-semibold text-[#6b2228]">
              Products (Cat, Range, Name)
            </h2>
            <div className="text-sm text-gray-600">Mars Period</div>
          </div>
          <div className="overflow-x-auto">
            {isCategoryLoading ? (
              <LoadingBox />
            ) : (
              <table className="w-full" style={{ height: "60vh" }}>
                <thead className="bg-gray-50">
                  <tr>
                    {[
                      { key: "MARS_CREATED_YEAR", label: "Mars Year/Period" },
                      { key: "CATEGORY", label: "Category" },
                      { key: "RANGE", label: "Range" },
                      { key: "NAME_EN", label: "Name" },
                      { key: "NBR_OF_ORDERS", label: "Nbr of Orders" },
                      { key: "QTY", label: " Qty" },
                      { key: "DEMAND_NSV", label: "Demand NSV" },
                      {
                        key: "NBR_OF_ORDERS_LY",
                        label: "Nbr of Orders LY",
                      },
                      { key: "QTY_LY", label: "Qty LY" },
                      { key: "DEMAND_NSV_LY", label: "Demand NSV LY" },
                    ].map(({ key, label }) => (
                      <th
                        key={key}
                        onClick={() =>
                          handleSort(
                            key,
                            setSortCategoryField,
                            setSortCategoryOrder,
                            sortCategoryField,
                            sortCategoryOrder
                          )
                        }
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                      >
                        <div className="flex items-center space-x-1">
                          <span>{label}</span>
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {categoryData?.rows.map((category: any, index: any) => {
                    console.log("Category Data:", category);
                    return (
                      <React.Fragment key={index}>
                        <tr className="bg-gray-50">
                          <td className="px-6 py-2 text-sm font-medium text-gray-900">
                            {category.MARS_CREATED_YEAR}{" "}
                            {category.MARS_CREATED_PERIOD}
                          </td>
                          <td className="px-6 py-2 text-sm font-medium text-gray-900">
                            {category.CATEGORY}
                          </td>
                          <td className="px-6 py-2 text-sm font-medium text-gray-900">
                            {category.RANGE}
                          </td>
                          <td className="px-6 py-2 text-sm font-medium text-gray-900">
                            {category.NAME_EN}
                          </td>
                          <td className="px-6 py-2 text-sm text-gray-900">
                            {category.NBR_OF_ORDERS}
                          </td>
                          <td className="px-6 py-2 text-sm text-gray-900">
                            {category.QTY}
                          </td>
                          <td className="px-6 py-2 text-sm text-gray-900">
                            {formatCurrency(category.DEMAND_NSV)}
                          </td>
                          <td className="px-6 py-2 text-sm text-gray-900">
                            {category.NBR_OF_ORDERS_LY}
                          </td>
                          <td className="px-6 py-2 text-sm text-gray-900">
                            {category.QTY_LY}
                          </td>
                          <td className="px-6 py-2 text-sm text-gray-900">
                            {formatCurrency(category.DEMAND_NSV_LY)}
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                  <tr className="bg-blue-600 text-white">
                    <td className="px-6 py-2 text-sm font-bold">
                      Total Digital EU
                    </td>
                    <td className="px-6 py-2 text-sm"></td>
                    <td className="px-6 py-2 text-sm"></td>
                    <td className="px-6 py-2 text-sm"></td>
                    <td className="px-6 py-2 text-sm">
                      {GetTotal(categoryData?.rows, "NBR_OF_ORDERS")}
                    </td>
                    <td className="px-6 py-2 text-sm">
                      {GetTotal(categoryData?.rows, "QTY")}
                    </td>
                    <td className="px-6 py-2 text-sm">
                      {formatCurrency(
                        GetTotal(categoryData?.rows, "DEMAND_NSV")
                      )}
                    </td>
                    <td className="px-6 py-2 text-sm">
                      {GetTotal(categoryData?.rows, "NBR_OF_ORDERS_LY")}
                    </td>
                    <td className="px-6 py-2 text-sm">
                      {GetTotal(categoryData?.rows, "QTY_LY")}
                    </td>
                    <td className="px-6 py-2 text-sm">
                      {formatCurrency(
                        GetTotal(categoryData?.rows, "DEMAND_NSV_LY")
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
          <Pagination
            currentPage={Number(categoryData?.currentPage) || 0}
            totalPages={Number(categoryData?.totalPages) || 0}
            totalRows={Number(categoryData?.count) || 0}
            setCurrentPage={(val: any) => {
              setCategoryPage(val);
            }}
            itemsPerPage={Number(10) || 0}
          />
        </div>
      </div>
      {/* <br /> */}
      {/* Second Table */}
      <div className="sm:col-span-12 md:col-span-5">
        <div className="bg-white rounded-lg shadow">
          <div className="p-4 border-b border-gray-200">
            <h2 className="text-lg font-semibold text-[#6b2228]">
              Products (Country, Name)
            </h2>
            <div className="text-sm text-gray-600">Mars Period</div>
          </div>
          <div className="overflow-x-auto">
            {isCountryLoading ? (
              <LoadingBox />
            ) : (
              <table className="w-full" style={{ height: "60vh" }}>
                <thead className="bg-gray-50">
                  <tr>
                    {[
                      { key: "MARS_CREATED_YEAR", label: "Mars Year/Period" },
                      { key: "COUNTRY", label: "Country" },
                      { key: "NAME_EN", label: "Name" },
                      { key: "NBR_OF_ORDERS", label: "Nbr of Orders" },
                      { key: "QTY", label: " Qty" },
                      { key: "DEMAND_NSV", label: "Demand NSV" },
                      {
                        key: "NBR_OF_ORDERS_LY",
                        label: "Nbr of Orders LY",
                      },
                      { key: "QTY_LY", label: "Qty LY" },
                    ].map(({ key, label }) => (
                      <th
                        key={key}
                        onClick={() =>
                          handleSort(
                            key,
                            setSortCountryField,
                            setSortCountryOrder,
                            sortCountryField,
                            sortCountryOrder
                          )
                        }
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                      >
                        <div className="flex items-center space-x-1">
                          <span>{label}</span>
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {countryData?.rows?.map((country: any, index: any) => (
                    <React.Fragment key={index}>
                      <tr className="bg-gray-50">
                        <td className="px-6 py-2 text-sm font-medium text-gray-900">
                          {country.MARS_CREATED_YEAR}{" "}
                          {country.MARS_CREATED_PERIOD}
                        </td>
                        <td className="px-6 py-2 text-sm font-medium text-gray-900">
                          {country.COUNTRY}
                        </td>
                        <td className="px-6 py-2 text-sm font-medium text-gray-900">
                          {country.NAME_EN}
                        </td>
                        <td className="px-6 py-2 text-sm text-gray-900">
                          {country.NBR_OF_ORDERS}
                        </td>
                        <td className="px-6 py-2 text-sm text-gray-900">
                          {country.QTY}
                        </td>
                        <td className="px-6 py-2 text-sm text-gray-900">
                          {formatCurrency(country.DEMAND_NSV)}
                        </td>
                        <td className="px-6 py-2 text-sm text-gray-900">
                          {country.NBR_OF_ORDERS_LY}
                        </td>
                        <td className="px-6 py-2 text-sm text-gray-900">
                          {country.QTY_LY}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                  <tr className="bg-blue-600 text-white">
                    <td className="px-6 py-2 text-sm font-bold">
                      Total Digital EU
                    </td>
                    <td className="px-6 py-2 text-sm"></td>
                    <td className="px-6 py-2 text-sm">
                      {GetTotal(countryData?.rows, "QTY_PY")}
                    </td>
                    <td className="px-6 py-2 text-sm">
                      {GetTotal(countryData?.rows, "NBR_OF_ORDERS")}
                    </td>
                    <td className="px-6 py-2 text-sm">
                      {GetTotal(countryData?.rows, "QTY")}
                    </td>
                    <td className="px-6 py-2 text-sm">
                      {formatCurrency(
                        GetTotal(countryData?.rows, "DEMAND_NSV")
                      )}
                    </td>
                    <td className="px-6 py-2 text-sm">
                      {GetTotal(countryData?.rows, "NBR_OF_ORDERS_LY")}
                    </td>
                    <td className="px-6 py-2 text-sm">
                      {GetTotal(countryData?.rows, "QTY_LY")}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
          <Pagination
            currentPage={Number(countryData?.currentPage) || 0}
            totalPages={Number(countryData?.totalPages) || 0}
            totalRows={Number(countryData?.count) || 0}
            setCurrentPage={(val: any) => {
              setCountryPage(val);
            }}
            itemsPerPage={Number(10) || 0}
          />
        </div>
      </div>

      <div className="sm:col-span-12 md:col-span-12">
        {/* Third Table */}
        <div className="bg-white rounded-lg shadow">
          <div className="p-4 border-b border-gray-200">
            <h2 className="text-lg font-semibold text-[#6b2228]">
              Products (Occasion, Name)
            </h2>
            <div className="text-sm text-gray-600">Mars Period</div>
          </div>
          <div className="overflow-x-auto">
            {isOccationLoading ? (
              <LoadingBox />
            ) : (
              <table className="w-full" style={{ height: "60vh" }}>
                <thead className="bg-gray-50">
                  <tr>
                    {[
                      { key: "MARS_CREATED_YEAR", label: "Mars Year/Period" },
                      { key: "OCCASION", label: "Occasion" },
                      { key: "NAME_EN", label: "Name" },
                      { key: "NBR_OF_ORDERS", label: "Nbr of Orders" },
                      { key: "QTY", label: " Qty" },
                      { key: "DEMAND_NSV", label: "Demand NSV" },
                    ].map(({ key, label }) => (
                      <th
                        key={key}
                        onClick={() =>
                          handleSort(
                            key,
                            setSortOccationField,
                            setSortOccationOrder,
                            sortOccationField,
                            sortOccationOrder
                          )
                        }
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                      >
                        <div className="flex items-center space-x-1">
                          <span>{label}</span>
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {occationData?.rows?.map((item: any, index: any) => (
                    <tr key={index} className="hover:bg-gray-50">
                      <td className="px-6 py-2 text-sm text-gray-900">
                        {item.MARS_CREATED_YEAR} {item.MARS_CREATED_PERIOD}
                      </td>
                      <td className="px-6 py-2 text-sm text-gray-900">
                        {item.OCCASION}
                      </td>
                      <td className="px-6 py-2 text-sm text-gray-900">
                        {item.NAME_EN}
                      </td>
                      <td className="px-6 py-2 text-sm text-gray-600">
                        {item.NBR_OF_ORDERS.toLocaleString()}
                      </td>
                      <td className="px-6 py-2 text-sm text-gray-600">
                        {item.QTY.toLocaleString()}
                      </td>
                      <td className="px-6 py-2 text-sm text-gray-600">
                        {formatCurrency(item.DEMAND_NSV)}
                      </td>
                    </tr>
                  ))}
                  <tr className="bg-blue-600 text-white">
                    <td className="px-6 py-2 text-sm font-bold">
                      Total Digital EU
                    </td>
                    <td className="px-6 py-2 text-sm font-bold"></td>{" "}
                    <td className="px-6 py-2 text-sm font-bold"></td>
                    <td className="px-6 py-2 text-sm">
                      {GetTotal(occationData?.rows, "NBR_OF_ORDERS")}
                    </td>
                    <td className="px-6 py-2 text-sm">
                      {GetTotal(occationData?.rows, "QTY")}
                    </td>
                    <td className="px-6 py-2 text-sm">
                      {formatCurrency(
                        GetTotal(occationData?.rows, "DEMAND_NSV")
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
          <Pagination
            currentPage={Number(occationData?.currentPage) || 0}
            totalPages={Number(occationData?.totalPages) || 0}
            totalRows={Number(occationData?.count) || 0}
            setCurrentPage={(val: any) => {
              setOccationPage(val);
            }}
            itemsPerPage={Number(10) || 0}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductSplit;
